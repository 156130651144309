/** @jsx jsx */
/* eslint react/jsx-key: 0 */
import Highlight, { defaultProps } from "prism-react-renderer";
import { jsx } from "@emotion/core";
import { element, string } from "prop-types";

import { usePrismTheme } from "../../utils";

export const Code = ({ children, className: outerClassName }) => {
  const [language] = outerClassName
    ? outerClassName.replace(/language-/, "").split(" ")
    : ["text"];
  const theme = usePrismTheme();

  return (
    <Highlight
      {...defaultProps}
      code={children.trim()}
      language={language}
      theme={theme}
    >
      {({ style, tokens, getLineProps, getTokenProps }) => (
        <pre css={{ ...style, overflowX: "auto" }} data-testid="code">
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span
                  {...getTokenProps({ token, key })}
                  css={{ display: "inline-block" }}
                />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

Code.propTypes = {
  children: element,
  className: string
};
