import React from "react";
import { View } from "react-native";
import { bool } from "prop-types";

import { radii, colors } from "../../../core";

const Underline = ({ isActive }) => (
  <View
    style={{
      borderRadius: radii.large,
      backgroundColor: isActive ? colors.highlighted.dark : "transparent",
      height: 3
    }}
  />
);

Underline.propTypes = {
  isActive: bool
};

export default Underline;
