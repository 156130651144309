// ==============================
// Field Validator
// ==============================

import React from "react";
import PropTypes from "prop-types";

import FormValidationRules from "../validationRules";

const FormType = PropTypes.shape({
  addError: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
});

class FieldValidator extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool
    ]),
    form: FormType.isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    validationMethods: PropTypes.arrayOf(PropTypes.func), // payload => message | undefined
    formatMethods: PropTypes.arrayOf(PropTypes.func)
  };
  static defaultProps = {
    defaultValue: undefined,
    isRequired: false,
    validationMethods: [],
    formatMethods: []
  };

  constructor(props) {
    super(props);

    const { defaultValue, form, isRequired, label, name } = props;
    const field = {
      isRequired,
      name,
      validate: this.validate,
      format: this.format
    };

    // register this field with the form class
    if (!form) {
      throw ReferenceError(
        `The input "${label || name}" requires a \`form\` prop.

        This should be passed down from the Form component in its render function.`
      );
    }

    form.registerField(field, defaultValue);
  }

  onChange = (unformattedValue, maybeKey) => {
    const { form, name, onChange } = this.props;

    // NOTE: use the consumer's `onChange` to, potentially, format the
    // value. Fallback if they fail to return from the function.
    const value = onChange(unformattedValue, maybeKey) || unformattedValue;

    form.onChange({ name, value });
  };

  validate = () => {
    const { form, isRequired, label, name, validationMethods } = this.props;
    const value = form.values[name];
    const field = { name, label, value };

    const methods = isRequired
      ? [FormValidationRules.isRequired, ...validationMethods]
      : validationMethods;

    let errors = methods
      .map(method => method(field, form.values))
      .filter(Boolean);

    let fieldIsValid = errors.length === 0;

    form.addError({ name, errors });

    return fieldIsValid;
  };

  format = () => {
    const { form, name, formatMethods } = this.props;

    const formattedValue = formatMethods.reduce(
      (memo, formatMethod) => formatMethod(memo),
      form.values[name]
    );

    form.onChange({ name, value: formattedValue });
  };

  onBlur = () => {
    const { form, name, onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }

    form.onBlur(name);
  };

  onFocus = () => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }
  };

  render() {
    const { children, form, name } = this.props;

    const data = {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      value: form.values[name], // undefined by default
      errors: form.touched[name] && form.errors[name]
    };

    return children(data);
  }
}

export default FieldValidator;
