import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";

import Text from "../Text";
import { spacing } from "../../core";

const Header = ({ beforeElement, afterElement, title, hasPrimaryTitle }) => {
  const hasChildren = !!beforeElement || !!afterElement;

  return (
    <View
      style={{
        paddingVertical: spacing.none,
        paddingHorizontal: spacing.xlarge,
        alignItems: "center",
        alignContent: "center",
        flexDirection: "row",
        height: 64,
        justifyContent: "space-between"
      }}
    >
      {hasChildren && (
        <View style={{ width: 80, alignItems: "flex-start" }}>
          {beforeElement}
        </View>
      )}

      <View style={{ flex: 1, alignItems: "center" }}>
        {typeof title === "string" ? (
          <Text
            token={hasPrimaryTitle ? "title-2" : "title-1"}
            numberOfLines={1}
            style={{
              textAlign: "center",
              alignSelf: "center"
            }}
          >
            {title}
          </Text>
        ) : (
          title
        )}
      </View>
      {hasChildren && (
        <View style={{ width: 80, alignItems: "flex-end" }}>
          {afterElement}
        </View>
      )}
    </View>
  );
};

Header.propTypes = {
  /** Element placed to the right of the title */
  afterElement: PropTypes.element,
  /** Element placed to the left of the title */
  beforeElement: PropTypes.element,
  /** Element placed in the middle of the header */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /** If the central element is text, the 'title-2' style will be applied */
  hasPrimaryTitle: PropTypes.bool
};

Header.defaultProps = {
  title: ""
};

export default Header;
