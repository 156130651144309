import { Playground, Props } from "docz";
import SwitchRow from "../../../../../src/components/SwitchRow/src/SwitchRow";
import SwitchRowWithValidation from "../../../../../src/components/SwitchRow/src/SwitchRowWithValidation";
import { colors } from "../../../../../src/core";
import Text from "../../../../../src/components/Text";
import * as React from 'react';
export default {
  Playground,
  Props,
  SwitchRow,
  SwitchRowWithValidation,
  colors,
  Text,
  React
};