import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://app.netlify.com/sites/reckon-mobile-design-system/deploys"
      }}><img alt="Netlify Status" src="https://api.netlify.com/api/v1/badges/73e8113e-2f96-4510-b13e-6db703de0b59/deploy-status" /></a></p>
    <h1 {...{
      "id": "balance-mobile"
    }}>{`Balance Mobile`}</h1>
    <p>{`Balance Mobile is the Design System for creating Reckon's React Native applications.`}</p>
    <h2 {...{
      "id": "geting-started"
    }}>{`Geting Started`}</h2>
    <p>{`To start using Balance in your React Native App, please ensure you have SSH access to Reckon's BitBucket organsiation.`}</p>
    <p>{`Then, you can install it like so...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add git+ssh://git@bitbucket.org/reckonhq/design-system.git#v1.52.1
# Ensure you update the version number above
`}</code></pre>
    <p>{`and you are ready to use the components!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{` import { Box } from 'reckon-mobile-design-system\`
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "demo-app"
    }}>{`Demo app`}</h2>
    <p>{`This repo includes a demo app, which allows you to view and test components while you dev.`}</p>
    <p>{`Make sure you install `}<inlineCode parentName="p">{`expo-cli`}</inlineCode>{` globally on your machine...`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install -g expo-cli
`}</code></pre>
    <p>{`...then run the following commands in your terminal...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn && yarn demo
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "about-releasing-and-versioning"
    }}>{`About releasing and versioning`}</h2>
    <p>{`Releasing a new version of the Mobile Design System is done by merging a PR from dev to master.`}</p>
    <p>{`This Design System follows the `}<a parentName="p" {...{
        "href": "https://semver.org/"
      }}>{`Semantic Versioning`}</a>{` convention. This repository is configured to generate version tags and changelog notes automatically with `}<a parentName="p" {...{
        "href": "https://semantic-release.gitbook.io"
      }}>{`semantic-release`}</a>{`. When a new commit is added to `}<inlineCode parentName="p">{`master`}</inlineCode>{` branch (for example when a pull request is merged) `}<inlineCode parentName="p">{`semantic-release`}</inlineCode>{` will inspect the git log and generate a changelog and appropriate version tag based on the changes it finds.`}</p>
    <p>{`To make it easier to document changes in the codebase we use a formalise commit message convention. A simple commit message might look like:`}</p>
    <pre><code parentName="pre" {...{}}>{`feat(button): Implement weight props
`}</code></pre>
    <p>{`As per `}<a parentName="p" {...{
        "href": "https://github.com/angular/angular/blob/master/CONTRIBUTING.md#type"
      }}>{`this guide`}</a>{`, the commit type must be one of the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`build`}</strong>{`: Changes that affect the build system or external dependencies (example scopes: gulp, broccoli, npm)`}</li>
      <li parentName="ul"><strong parentName="li">{`ci`}</strong>{`: Changes to our CI configuration files and scripts (example scopes: Circle, BrowserStack, SauceLabs)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs`}</strong>{`: Documentation only changes`}</li>
      <li parentName="ul"><strong parentName="li">{`feat`}</strong>{`: A new feature`}</li>
      <li parentName="ul"><strong parentName="li">{`fix`}</strong>{`: A bug fix`}</li>
      <li parentName="ul"><strong parentName="li">{`perf`}</strong>{`: A code change that improves performance`}</li>
      <li parentName="ul"><strong parentName="li">{`refactor`}</strong>{`: A code change that neither fixes a bug nor adds a feature`}</li>
      <li parentName="ul"><strong parentName="li">{`test`}</strong>{`: Adding missing tests or correcting existing tests`}</li>
    </ul>
    <p>{`PRs from Dev to Master must be merged via a 'merge commit' rather than squashing, to retain all of the commit descriptions for the semantic versioning bot.`}</p>
    <p>{`To read more about this commit message format and how it's used by `}<inlineCode parentName="p">{`semantic-release`}</inlineCode>{` check out `}<a parentName="p" {...{
        "href": "https://semantic-release.gitbook.io/semantic-release/#how-does-it-work"
      }}>{`the docs`}</a>{`. Or to get started try using the built-in helper:`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn commit
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      