import React, { Children, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Animated, ScrollView, Platform, Dimensions, View } from "react-native";

import { StackSpacer } from "../Spacer";
import { spacing } from "../../core";

import Pagination from "./pagination";

const deviceWidth = Dimensions.get("window").width;

const Carousel = ({
  itemGap: itemGapToken,
  itemPeekWidth: itemPeekWidthToken,
  children,
  style,
  hasPagination,
  paginationColor,
  activeItem,
  onScroll
}) => {
  const scrollX = new Animated.Value(0);
  const scrollViewRef = useRef(null);
  const itemGap = spacing[itemGapToken];
  const itemPeekWidth = spacing[itemPeekWidthToken];

  useEffect(() => {
    scrollViewRef.current.scrollTo({
      x: -(itemPeekWidth + itemGap / 2) + activeItem * (itemWidth + itemGap * 2)
    });
  }, [activeItem]);

  const itemWidth = deviceWidth - itemPeekWidth * 2 - itemGap * 2;

  const position = Animated.divide(
    scrollX,
    itemWidth + itemGap + itemPeekWidth
  );

  // Android doesn't support contentInset, so we needed a work around.
  const horizontalSpacing =
    Platform.OS === "ios"
      ? {
          contentInset: {
            bottom: 0,
            left: itemPeekWidth + itemGap / 2,
            right: itemPeekWidth + itemGap / 2,
            top: 0
          }
        }
      : {
          contentContainerStyle: {
            paddingHorizontal: itemPeekWidth + itemGap / 2,
            paddingVertical: 0
          }
        };

  const scrollViewWithPaginationProps =
    hasPagination || onScroll
      ? {
          onScroll: e => {
            Animated.event(
              [{ nativeEvent: { contentOffset: { x: scrollX } } }],
              { useNativeDriver: false }
            )(e);
            onScroll ? onScroll(e) : null;
          },
          scrollEventThrottle: 16
        }
      : null;

  // Children fail to render when overflow:visible is set on Android.
  // We need overflow enabled on iOS because drop shadows are being
  // cut off, resulting in an ugly hard edge.
  const overflowOniOS = Platform.OS === "ios" ? { overflow: "visible" } : {};

  const scrollViewProps = {
    ...horizontalSpacing,
    decelerationRate: "fast",
    directionalLockEnabled: false,
    horizontal: true,
    pagingEnabled: false,
    showsHorizontalScrollIndicator: false,
    snapToAlignment: "center",
    snapToInterval: itemWidth + itemGap * 2,
    width: "100%",
    style: {
      ...overflowOniOS
    },
    ...scrollViewWithPaginationProps
  };

  return (
    <View style={[{ width: "100%", alignItems: "center" }, style]}>
      <ScrollView {...scrollViewProps} ref={scrollViewRef}>
        {Children.map(children, (item, i) => (
          <View
            key={i}
            style={{
              width: itemWidth,
              flex: 1,
              // Adding some extra space for elevation
              // effect on Android
              paddingVertical: 1,
              ...overflowOniOS,
              marginHorizontal: itemGap
            }}
          >
            {item}
          </View>
        ))}
      </ScrollView>

      {hasPagination && (
        <>
          <StackSpacer />
          <Pagination
            totalPages={Children.count(children)}
            position={position}
            paginationColor={paginationColor}
          />
        </>
      )}
    </View>
  );
};

Carousel.propTypes = {
  /** Decides if the pagination UI will show below the scrollview */
  hasPagination: PropTypes.bool,
  /** An array of 'children' */
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** Gap between items. Use any of the spacing tokens from core (eg small, medium, 2x, 4x) */
  itemGap: PropTypes.oneOf(Object.keys(spacing)),
  /** The distance that the next/previous item will peak from the edge. Use any of the spacing tokens from core (eg small, medium, 2x, 4x) */
  itemPeekWidth: PropTypes.oneOf(Object.keys(spacing)),
  /** Apply a style object for the container. Add {flexGrow: 1} for full-height carousel */
  style: PropTypes.object,
  /** The color of the pagination when dragging to the right or left */
  paginationColor: PropTypes.string,
  /** Set the active item using its index */
  activeItem: PropTypes.number,
  /** Scroll event callback */
  onScroll: PropTypes.func
};

Carousel.defaultProps = {
  hasPagination: true,
  itemGap: "none",
  itemPeekWidth: "none",
  activeItem: 0
};

export default Carousel;
