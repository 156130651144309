/** @jsx jsx */
import { jsx } from "@emotion/core";
import { element } from "prop-types";

import { Layout } from "./Layout";
import { Playground } from "./Playground";
import { Code } from "./Code";
import { Props } from "./Props";
import { Notice } from "./Notice";

const Pre = ({ children }) => <div>{children}</div>;
Pre.propTypes = {
  children: element
};

export default {
  // page: undefined,
  // notFound: undefined,
  // render: undefined,
  // h1: undefined,
  // h2: undefined,
  // h3: undefined,
  // h4: undefined,
  // h5: undefined,
  // h6: undefined,
  // ul: undefined,
  // loading: undefined,
  // table: undefined,
  // inlineCode: undefined,
  blockquote: Notice,
  code: Code,
  playground: Playground,
  pre: Pre,
  props: Props,
  layout: Layout
};
