import { useTheme } from "emotion-theming";
import { get, pipe, defaultTo } from "lodash/fp";

export const usePrismTheme = () => {
  const theme = useTheme();
  const getTheme = pipe(
    get("prismTheme"),
    defaultTo(get(`prism.light`, theme))
  );
  return getTheme(theme);
};
