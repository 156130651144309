export const spacing = {
  none: 0,
  xsmall: 4,
  small: 8,
  medium: 12,
  large: 16,
  xlarge: 24,
  xxlarge: 32,
  xxxlarge: 40,

  // deprecated tokens
  "6x": 48,
  "7x": 56,
  "8x": 64,
  "9x": 72,
  "10x": 80
};
