import React, { forwardRef } from "react";
import {
  array,
  bool,
  string,
  func,
  object,
  oneOfType,
  arrayOf,
  shape as objectShape
} from "prop-types";

import { FieldValidator } from "../../Form";

import Select from "./select";

const SelectWithValidation = forwardRef(
  (
    {
      defaultValue,
      form,
      isRequired,
      label,
      options,
      name,
      validationMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;

    // stub the component's default onChange if it's not defined
    const onChange =
      typeof props.onChange === "function" ? props.onChange : () => {};

    const validationLabel = label.replace("*", "");

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={validationLabel}
        name={name}
        onChange={onChange}
        validationMethods={validationMethods}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <Select
              errorMessage={errorMessage}
              options={options}
              label={label}
              ref={ref}
              {...props}
              {...validatorProps}
              onChange={async text => {
                await validatorProps.onChange(text, keyValue);
                validatorProps.onBlur();
              }}
            />
          );
        }}
      </FieldValidator>
    );
  }
);

SelectWithValidation.propTypes = {
  defaultValue: string,
  form: object.isRequired,
  /** Defines if the Select should have a 'hint', and what the label should be. */
  hintLabel: string,
  isDisabled: bool,
  isRequired: bool,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func,
  /** What function should run when user taps the 'hint' */
  onHintPress: func,
  onKeyPress: func,
  options: arrayOf(
    oneOfType([
      objectShape({
        label: string.isRequired,
        value: string.isRequired,
        key: string,
        color: string
      }),
      string
    ])
  ).isRequired,
  validationMethods: array
};

SelectWithValidation.displayName = "SelectWithValidation";

export default SelectWithValidation;
