import React from "react";

import { ICONS } from "./index";

const IconsDemo = () => (
  <div style={{ display: "flex", flexWrap: "wrap" }}>
    {Object.keys(ICONS)
      .filter(name => name !== "__filemeta")
      .map(name => (
        <div key={name}>
          <div
            style={{
              width: 96,
              height: 96,
              backgroundColor: "white",
              margin: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <svg
              style={{ width: 72, height: 72 }}
              viewBox="0 0 32 32"
              fillRule="evenodd"
            >
              <path d={ICONS[name]} />
            </svg>
          </div>
          <span>{name}</span>
        </div>
      ))}
  </div>
);

export default IconsDemo;
