import { sizing, spacing, text, colors } from "../../../core";

const calendarTheme = {
  selectedDayBackgroundColor: colors.selected.dark,
  "stylesheet.calendar.main": {
    week: {
      marginTop: 6,
      marginBottom: 6,
      flexDirection: "row",
      justifyContent: "space-around"
    }
  },
  "stylesheet.calendar-list.main": {
    calendar: {
      paddingVertical: spacing.none,
      paddingHorizontal: spacing.large
    }
  },
  "stylesheet.calendar.header": {
    header: {
      paddingVertical: spacing.none,
      paddingHorizontal: spacing.large,
      margin: 0
    },
    monthText: {
      ...text["blockTitle-1"],
      paddingVertical: spacing.small,
      paddingHorizontal: spacing.none,
      marginTop: spacing.xsmall,
      width: "100%",
      textAlign: "center",
      color: colors.highlighted.dark
    }
  },
  "stylesheet.day.basic": {
    base: {
      width: sizing.base,
      height: sizing.base,
      borderRadius: sizing.base,
      justifyContent: "center"
    },
    selected: {
      borderRadius: sizing.base,
      backgroundColor: colors.selected.dark
    },
    selectedText: {
      color: colors.selected.light
    },
    todayText: {
      ...text["4x-700"],
      color: colors.hero.dark
    },
    text: {
      ...text["4x-500"],
      textAlign: "center"
    }
  },
  "stylesheet.day.period": {
    base: {
      width: sizing.base,
      height: sizing.base,
      justifyContent: "center"
    },
    leftFiller: {
      height: sizing.base,
      flex: 1
    },
    rightFiller: {
      height: sizing.base,
      flex: 1
    },
    todayText: {
      ...text["4x-700"],
      color: colors.hero.dark
    },
    text: {
      ...text["4x-500"],
      textAlign: "center"
    }
  }
};

export default calendarTheme;
