import React from "react";
import { array, bool, func, object, oneOfType, string } from "prop-types";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";

import { Icon } from "../../icons";
import { InlineSpacer } from "../Spacer";
import { colors, sizing, text, radii } from "../../core";

const RadioButton = ({ onPress, value, style, selected, label }) => (
  <TouchableOpacity
    onPress={() => onPress(value)}
    style={{
      flexDirection: "row",
      alignItems: "center"
    }}
  >
    <View style={[ss.button, selected && ss.button_selected, style]}>
      {selected ? (
        <View style={[ss.indicator]}>
          <Icon icon="tick" size={sizing["2x"]} color={colors.selected.light} />
        </View>
      ) : null}
    </View>
    <InlineSpacer />
    <Text style={ss.label}>{label}</Text>
  </TouchableOpacity>
);

RadioButton.propTypes = {
  label: string,
  onPress: func,
  selected: bool,
  style: oneOfType([array, object]),
  value: string
};

export default RadioButton;

// Styles
const ss = StyleSheet.create({
  button: {
    borderRadius: radii.full,
    height: sizing.small,
    width: sizing.small,
    backgroundColor: colors.idle.light,
    alignItems: "center",
    justifyContent: "center"
  },
  indicator: {
    borderRadius: radii.full,
    height: sizing.small,
    width: sizing.small,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.selected.dark
  },
  label: {
    ...text["3.5x-500"],
    flex: 1
  }
});
