import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { bool, func, string, oneOf } from "prop-types";

import { colors, spacing, radii } from "../../core";
import { InlineSpacer } from "../Spacer";
import Text from "../Text";
import { Icon } from "../../icons";

const DataRow = ({
  alignItems,
  label,
  isElevated,
  isFirst,
  isLabelEmphasised,
  isValueEmphasised,
  isLast,
  onValuePress,
  onHintPress,
  value
}) => {
  const dynamicStyles = StyleSheet.create({
    base: {
      ...(isElevated
        ? {
            marginHorizontal: -spacing.large,
            paddingHorizontal: spacing.large,
            backgroundColor: colors.background.elevated
          }
        : {}),
      ...(isFirst
        ? {
            borderTopLeftRadius: radii.medium,
            borderTopRightRadius: radii.medium
          }
        : {}),
      ...(isLast
        ? {
            borderBottomLeftRadius: radii.medium,
            borderBottomRightRadius: radii.medium
          }
        : {})
    },
    internalContainer: {
      paddingVertical: spacing.medium,
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottomColor: colors.separator,
      alignItems: alignItems,
      borderBottomWidth: isLast ? 0 : StyleSheet.hairlineWidth
    },
    labelContainer: {
      flexDirection: "row",
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "50%",
      alignItems: alignItems
    },
    valueContainer: {
      alignItems: alignItems,
      flexDirection: "row",
      flexGrow: 1,
      flexShrink: 1,
      justifyContent: "flex-end"
    }
  });

  return (
    <View style={dynamicStyles.base}>
      <View style={dynamicStyles.internalContainer}>
        <View style={dynamicStyles.labelContainer}>
          <Text token={isLabelEmphasised ? "3.5x-700-p" : "3.5x-400-p"}>
            {label}
          </Text>
          {onHintPress && (
            <>
              <InlineSpacer size="small" />
              <TouchableOpacity onPress={onHintPress}>
                <Icon icon="helpFilled" size={16} />
              </TouchableOpacity>
            </>
          )}
        </View>

        <View style={dynamicStyles.valueContainer}>
          <Text
            align="right"
            color={onValuePress ? colors.action.dark : colors.text.default}
            onPress={onValuePress}
            token={
              isValueEmphasised || onValuePress ? "3.5x-700-p" : "3.5x-500-p"
            }
          >
            {value}
          </Text>
        </View>
      </View>
    </View>
  );
};

DataRow.propTypes = {
  /** For number values, use flex-end */
  alignItems: oneOf(["flex-start", "flex-end"]),
  /** Applies elevated styling */
  isElevated: bool,
  isFirst: bool,
  isLast: bool,
  isLabelEmphasised: bool,
  isValueEmphasised: bool,
  label: string.isRequired,
  onHintPress: func,
  onValuePress: func,
  value: string
};

DataRow.defaultProps = {
  alignItems: "flex-start"
};

export default DataRow;
