import React from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { bool, func, string } from "prop-types";
import DatePicker from "@react-native-community/datetimepicker";
import { format } from "date-fns";

import SlideUpSheet from "../../SlideUpModal";
import Button from "../../Button";
import { colors, spacing, radii, sizing } from "../../../core";
import Text from "../../Text";
import { StackSpacer, InlineSpacer } from "../../Spacer";
import { Icon } from "../../../icons";

const DateSelect = ({
  errorMessage,
  isDisabled,
  label,
  onChange,
  testID,
  value
}) => {
  const [isVisible, setVisible] = React.useState(false);

  const formattedValue = () => {
    return value ? format(new Date(value), "d MMM yyyy") : " ";
  };

  const setDate = v => {
    if (v) {
      v.nativeEvent.timestamp
        ? onChange(format(new Date(v.nativeEvent.timestamp), "yyyy-MM-dd"))
        : null;
    } else {
      onChange(undefined);
    }
  };

  const clearDate = () => {
    onChange("");
    setVisible(false);
  };

  return (
    <>
      <View>
        <Text token="3.5x-500" style={{ color: colors.text.neutral }}>
          {label}
        </Text>

        <StackSpacer size="xsmall" />

        <View
          style={{
            padding: spacing.medium,
            borderRadius: radii.small,
            backgroundColor: colors.inputField.idle.light,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1
          }}
        >
          <TouchableOpacity
            style={{ flex: 1 }}
            disabled={isDisabled}
            testID={testID}
            onPress={() => setVisible(true)}
          >
            <Text
              token="4x-500"
              style={{
                color: isDisabled ? colors.text.muted : colors.text.default
              }}
            >
              {formattedValue()}
            </Text>
          </TouchableOpacity>

          <InlineSpacer />

          <TouchableOpacity onPress={clearDate} disabled={isDisabled}>
            <Icon
              icon={value ? "cancel" : "event"}
              size={sizing["1.5x"]}
              color={
                value
                  ? colors.inputField.support
                  : colors.inputField.idle.support
              }
            />
          </TouchableOpacity>
        </View>

        {errorMessage && (
          <>
            <StackSpacer size="0.5" />
            <Text
              style={{
                color: colors.alert.danger.dark
              }}
            >
              {errorMessage}
            </Text>
          </>
        )}
      </View>

      {/* IOS Picker */
      Platform.OS === "ios" && (
        <SlideUpSheet
          autoHeight
          visible={isVisible}
          title={label}
          afterTitle={<Button label="Clear" type="link" onPress={clearDate} />}
          footerContent={[
            <Button
              label="Done"
              onPress={() => setVisible(false)}
              key="0"
              appearance={value ? "default" : "disabled"}
            />
          ]}
        >
          <DatePicker
            value={value ? new Date(value) : new Date()}
            testID="dateTimePicker-ios"
            mode="date"
            display="spinner"
            onChange={v => setDate(v)}
          />
        </SlideUpSheet>
      )}

      {/* Android Picker */
      Platform.OS === "android" && isVisible && (
        <DatePicker
          testID="datePicker-android"
          value={value ? new Date(value) : new Date()}
          mode="date"
          display="default"
          onChange={v => {
            setVisible(false);
            setDate(v);
          }}
        />
      )}
    </>
  );
};

DateSelect.propTypes = {
  errorMessage: string,
  isDisabled: bool,
  label: string,
  onChange: func.isRequired,
  testID: string,
  /** Must be a string in format "YYYY-MM-DD", (ISO strings will be trimmed) */
  value: string
};

export default DateSelect;
