import React from "react";
import PropTypes from "prop-types";
import { Platform, ScrollView } from "react-native";

import Sheet from "../Sheet";
import Header from "../Header";
import Footer from "../Footer";
import KeyboardAwareScrollView from "../KeyboardAwareScrollView";
import { spacing } from "../../core";
import { StackSpacer } from "../Spacer";

const SlideUpModal = ({
  addPadding,
  visible,
  children,
  autoHeight,
  title,
  footerContent,
  footerDirection,
  onHide,
  onModalHide,
  beforeTitle,
  afterTitle
}) => {
  const showHeader = title || beforeTitle || afterTitle;

  const Container =
    Platform.OS == "android" ? ScrollView : KeyboardAwareScrollView;

  return (
    <Sheet
      visible={visible}
      autoHeight={autoHeight}
      onHide={onHide}
      onModalHide={onModalHide}
    >
      {showHeader && (
        <Header
          beforeElement={beforeTitle}
          title={title}
          afterElement={afterTitle}
        />
      )}
      <Container
        style={{
          flexGrow: 1,
          flexShrink: 1,
          ...(addPadding
            ? {
                paddingHorizontal: spacing.xxlarge
              }
            : {})
        }}
        // prevents unexpected scrolling behaviour on iOS when Keyboard is present.
        automaticallyAdjustContentInsets={false}
      >
        {addPadding && <StackSpacer />}
        {children}
        {addPadding && <StackSpacer />}
      </Container>
      {footerContent && (
        <Footer direction={footerDirection}>{footerContent}</Footer>
      )}
    </Sheet>
  );
};

SlideUpModal.propTypes = {
  /** Adds default padding to content container. 4x padding on sides, and 1x at the top and bottom. */
  addPadding: PropTypes.bool,
  /** Determines whether the size of the modal should be dictated by the content. Only recommended for modals with a small amount of content (i.e. unlikely to grow beyond the size of the default modal). */
  autoHeight: PropTypes.bool,
  /** Note: See <Footer/> */
  afterTitle: PropTypes.element,
  /** Note: See <Footer/> */
  beforeTitle: PropTypes.element,
  /** The content of the modal (between the header and footer) */
  children: PropTypes.node,
  /** An array of buttons to place in the footer */
  footerContent: PropTypes.arrayOf(PropTypes.node),
  /** The direction the buttons in the footer should be stacked */
  footerDirection: PropTypes.oneOf(["row", "column"]),
  /** Enables the use of Android's physical back button */
  onHide: PropTypes.func,
  /** specifies a callback to fire after slide-down/dismiss animation is completed */
  onModalHide: PropTypes.func,
  /** Note: See <Footer/> */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /** Determines whether the modal is visible */
  visible: PropTypes.bool
};

SlideUpModal.defaultProps = {
  footerDirection: "row"
};

export default SlideUpModal;
