import { Playground, Props } from "docz";
import FlexGroup from "../../../../../src/components/FlexGroup/index";
import Avatar from "../../../../../src/components/Avatar";
import * as React from 'react';
export default {
  Playground,
  Props,
  FlexGroup,
  Avatar,
  React
};