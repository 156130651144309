import React from "react";
import { bool, func, string } from "prop-types";
import { TouchableOpacity, StyleSheet, View } from "react-native";

import { colors, spacing } from "../../core";
import { Icon } from "../../icons";
import { InlineSpacer } from "../Spacer";
import Text from "../Text";

const PayItem = ({
  disabled,
  icon,
  iconColor,
  title,
  subTitle,
  value,
  onPress,
  valueSubtext
}) => {
  // disabled touchable opacity if no onPress func is applied
  const isDisabled = disabled || !onPress;

  return (
    <TouchableOpacity style={ss.row} onPress={onPress} disabled={isDisabled}>
      <View
        style={{
          flexGrow: 1,
          flexShrink: 1,
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Icon
          color="white"
          icon={icon}
          background={iconColor}
          size={40}
          type="square"
        />
        <InlineSpacer />
        <View style={{ flexGrow: 1, flexShrink: 1 }}>
          <Text token="4x-500" numberOfLines={1}>
            {title}
          </Text>
          {subTitle && (
            <Text
              token="3x-400"
              numberOfLines={1}
              style={{ color: colors.text.neutral }}
            >
              {subTitle}
            </Text>
          )}
        </View>
      </View>

      <InlineSpacer size="xsmall" />

      <View style={{ alignItems: "flex-end" }}>
        <Text token="3.5x-400" numberOfLines={1}>
          {value}
        </Text>
        {valueSubtext && (
          <Text
            token="3x-400"
            style={{ color: colors.text.neutral }}
            numberOfLines={1}
          >
            {valueSubtext}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

const ss = StyleSheet.create({
  row: {
    padding: spacing.large,
    flexShrink: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

PayItem.propTypes = {
  /** component will not respond to taps if disabled is true, or if there is no onPress prop */
  disabled: bool,
  /**
   * The SVG path to be rendered inside the colored box.
   * Specifically, the contents of the "D" attribute of a SVG Path
   */
  icon: string.isRequired,
  /** The colors of the box in HEX/rgb syntax */
  iconColor: string.isRequired,
  /** The primary title of the PayItem */
  title: string.isRequired,
  /** The function which runs onPress of a line item */
  onPress: func,
  /** Optional subtext. e.g category of item */
  subTitle: string,
  /** Value of the item */
  value: string.isRequired,
  /** A description of value (eg conversion) */
  valueSubtext: string
};

PayItem.defaultProps = {
  icon: "tick",
  iconColor: colors.highlighted.dark
};

export default PayItem;
