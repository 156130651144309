import React from "react";
import { array, object, oneOf, oneOfType, string } from "prop-types";
import { TouchableOpacity } from "react-native";
import hexAlpha from "hex-alpha";

import { Icon } from "../../icons";
import { colors, radii, boxShadow, spacing } from "../../core";
import Ellipsis from "../Ellipsis";
import Text from "../Text";

const Button = ({ size, type, appearance, style, label, ...props }) => {
  const colorPack =
    appearance === "danger" ? colors.actionDestructive : colors.action;

  const dynamicStyles = {
    default: {
      alignItems: "center",
      justifyContent: "center",
      borderRadius: radii.medium,
      height: 56,
      minWidth: 70
    },

    primary: {
      default: {
        ...boxShadow,
        paddingVertical: spacing.none,
        paddingHorizontal: spacing.large,
        backgroundColor: colorPack.dark
      },
      loading: {
        backgroundColor: colorPack.dark
      },
      success: {
        backgroundColor: colors.alert.success.dark,
        shadowRadius: 0,
        shadowOffset: { width: 0, height: 0 }
      },
      danger: {
        backgroundColor: colors.alert.danger.dark
      },
      disabled: {
        backgroundColor: colors.disabled.dark
      }
    },
    secondary: {
      default: {
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: colorPack.dark
      },
      success: {
        borderColor: colors.alert.success.dark
      },
      disabled: {
        borderColor: colors.disabled.dark
      },
      danger: {
        borderColor: colors.alert.danger.dark
      }
    },
    tertiary: {
      default: {
        backgroundColor: colors.actionSecondary.light,
        borderWidth: 0,
        borderColor: "transparent"
      },
      success: {
        backgroundColor: hexAlpha(colors.alert.success.dark, 0.1)
      },
      disabled: {
        backgroundColor: colors.disabled.light,
        borderColor: colors.disabled.dark
      },
      danger: {
        backgroundColor: hexAlpha(colors.alert.danger.dark, 0.1)
      }
    },
    link: {
      default: {
        minWidth: 0,
        padding: 0,
        backgroundColor: "transparent",
        borderWidth: 0,
        borderColor: "transparent"
      },
      success: {
        borderWidth: 0
      },
      disabled: {
        borderColor: colors.disabled.dark
      }
    },

    small: {
      paddingVertical: spacing.none,
      paddingHorizontal: spacing.large,
      borderRadius: radii.medium,
      height: 48,
      flexDirection: "row"
    },
    xsmall: {
      borderRadius: radii.small,
      height: 40
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      disabled={appearance === "loading" || appearance === "disabled"}
      {...props}
      style={{
        ...dynamicStyles.default,
        ...dynamicStyles[size],
        ...dynamicStyles[type]["default"],
        ...dynamicStyles[type][appearance],
        ...style
      }}
    >
      {appearance === "success" && (
        <Icon
          icon="tick"
          style={{ position: "absolute" }}
          color={colors.alert.success[type === "primary" ? "light" : "dark"]}
          size={
            {
              default: 32,
              small: 32,
              xsmall: 32
            }[size]
          }
        />
      )}

      {appearance === "loading" && (
        <Ellipsis
          style={{
            position: "absolute"
          }}
          color={colors.action[type === "primary" ? "light" : "dark"]}
        />
      )}

      <Text
        token={type === "link" ? "4x-500" : "4x-700"}
        numberOfLines={1}
        style={{
          opacity: appearance === "loading" || appearance === "success" ? 0 : 1,
          zIndex: -1,
          color:
            type === "primary"
              ? "white"
              : appearance === "disabled"
              ? colors.disabled.dark
              : colorPack.dark
        }}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};

Button.propTypes = {
  /** The label that will be printed on the button */
  label: string.isRequired,
  /** Greys-out the button and disabled it from being tapped */
  size: oneOf(["default", "small", "xsmall"]),
  /** Changes button contents and appearance to show appearance */
  appearance: oneOf(["default", "loading", "success", "danger", "disabled"]),
  /** To adjust style of button */
  style: oneOfType([array, object]),
  type: oneOf(["primary", "secondary", "tertiary", "link"])
};

Button.defaultProps = {
  style: {},
  type: "primary",
  size: "default"
};

export default Button;
