import { radii } from "./radii";
import { spacing } from "./spacing";
import { sizing } from "./sizing";
import colors from "./colors";
import text from "./text";

const boxShadow = {
  shadowColor: "#000",
  shadowOffset: { width: 0, height: 8 },
  shadowOpacity: 0.08,
  shadowRadius: 24,
  elevation: 1
};

export { boxShadow, colors, radii, sizing, spacing, text };
