import { Text } from "react-native";
import { Playground, Props } from "docz";
import Card from "../../../../../src/components/Card/index";
import { StackSpacer } from "../../../../../src/components/Spacer";
import { spacing } from "../../../../../src/core";
import * as React from 'react';
export default {
  Text,
  Playground,
  Props,
  Card,
  StackSpacer,
  spacing,
  React
};