import React from "react";
import { func } from "prop-types";

const FORM_STATE = ["indeterminate", "invalid", "valid"];

class Form extends React.PureComponent {
  state = {
    formState: FORM_STATE[0],
    errors: {},
    touched: {},
    values: {}
  };

  static propTypes = {
    children: func.isRequired,
    onBlur: func,
    onChange: func,
    onSubmit: func
  };
  fields = {};

  // NOTE: filters out field keys that don't have errors
  get errorMessages() {
    return Object.entries(this.state.errors).reduce((obj, [key, value]) => {
      if (value.length) {
        obj[key] = value;
      }
      return obj;
    }, {});
  }

  validateFields = ({ condition = () => true } = {}) => {
    const allFields = Object.values(this.fields);
    const conditionalFields = allFields.filter(condition);

    const conditionalFieldsAreValid = allFields
      .map(field => field.validate())
      .every(Boolean);

    const requiredFieldsAreValid = conditionalFields
      .filter(f => f.isRequired)
      .map(field => field.validate())
      .every(Boolean);

    let formState = FORM_STATE[0];

    if (!conditionalFieldsAreValid) {
      formState = FORM_STATE[1];
    } else if (conditionalFieldsAreValid && requiredFieldsAreValid) {
      formState = FORM_STATE[2];
    }

    if (formState !== this.state.formState) {
      this.setState({ formState });
    }

    return formState;
  };
  errorFilter = f => {
    return this.state.errors[f.name] ? this.state.errors[f.name].length : 0;
  };
  touchedFilter = f => this.state.touched[f.name];

  registerField = (field, value = undefined) => {
    this.fields[field.name] = field;

    // NOTE: ensure current state object as many fields will attempt to register
    // at the same time when they mount.
    this.setState(
      state => ({
        errors: { ...state.errors, [field.name]: [] },
        values: { ...state.values, [field.name]: value }
      }),
      () => {
        field.validate(value);

        const payload = this.getPayload({ condition: this.errorFilter });
        if (this.props.onChange) {
          this.props.onChange(payload);
        }
      }
    );
  };
  addError = ({ name, errors: fieldErrors }) => {
    this.setState(state => ({
      errors: { ...state.errors, [name]: fieldErrors }
    }));
  };

  // NOTE: calls the validation method on each field
  getPayload = options => {
    let formState = this.validateFields(options);

    const payload = {
      values: this.state.values,
      touched: this.state.touched,
      errors: this.state.errors,
      state: formState
    };

    return payload;
  };

  onSubmit = () => {
    // touch all fields on submit
    const touched = Object.keys(this.fields).reduce(
      (obj, key) => ({ ...obj, [key]: true }),
      {}
    );

    this.setState({ touched }, () => {
      const payload = this.getPayload();
      if (this.props.onSubmit) {
        this.props.onSubmit(payload);
      }
    });
  };

  onChange = ({ name, value }) => {
    this.setState(
      state => ({
        values: {
          ...state.values,
          [name]: value
        },
        touched: {
          ...state.touched,
          ...(!state.touched[name]
            ? {
                [name]: true,
                any: true
              }
            : {})
        }
      }),
      () => {
        // validate on each keystroke once a field is invalid
        if (this.state.errors[name] && this.state.errors[name].length) {
          this.fields[name].validate(value);
        }

        // send back to the user
        const payload = this.getPayload({ condition: this.errorFilter });
        if (this.props.onChange) {
          this.props.onChange(payload);
        }
      }
    );
  };

  onBlur = async name => {
    await this.fields[name].format();

    this.validateFields({ condition: this.errorFilter });

    if (this.props.onBlur) {
      const payload = this.getPayload();
      this.props.onBlur(payload);
    }
  };

  render() {
    const form = {
      addError: this.addError,
      registerField: this.registerField,
      onBlur: this.onBlur,
      onChange: this.onChange,
      onSubmit: this.onSubmit,
      values: this.state.values,
      touched: this.state.touched,
      errors: this.state.errors,
      state: this.state.formState,
      isValid: this.state.formState === "valid"
    };

    return this.props.children(form);
  }
}

export default Form;
