import { Playground, Props } from "docz";
import { View } from "react-native";
import FormValidationRules from "../../../../../src/components/Form/validationRules";
import Form from "../../../../../src/components/Form/components/form";
import InputWithValidation from "../../../../../src/components/Input/src/inputWithValidation";
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  FormValidationRules,
  Form,
  InputWithValidation,
  React
};