import React from "react";
import { bool, func, string } from "prop-types";
import { View, TouchableOpacity } from "react-native";

import { colors, spacing, radii } from "../../../core";
import { InlineSpacer } from "../../Spacer";
import Text from "../../Text";

const Pill = ({ label, value, isActive, onPress, ...props }) => {
  return (
    <View
      key={`TabBarItem-${value}`}
      style={{ flexDirection: "row", alignItems: "center" }}
    >
      <TouchableOpacity
        style={{
          paddingHorizontal: spacing.large,
          paddingVertical: spacing.small,
          borderRadius: radii.full,
          backgroundColor: isActive
            ? colors.highlighted.dark
            : colors.background.elevated
        }}
        {...props}
        onPress={() => onPress(value)}
      >
        <Text
          token={"3x-500"}
          style={{
            color: isActive ? colors.highlighted.light : colors.idle.dark
          }}
        >
          {label}
        </Text>
      </TouchableOpacity>

      <InlineSpacer size="small" />
    </View>
  );
};

Pill.propTypes = {
  /** Whether the current item is selected */
  isActive: bool,
  /** The text that will be printed on the item */
  label: string.isRequired,
  /** The function that runs as a result of an item being pressed. onpress = (item) => setActiveItem(item) */
  onPress: func.isRequired,
  /** The value that is returned onPress */
  value: string.isRequired
};

Pill.defaultProps = {
  active: 0,
  level: "1"
};

export default Pill;
