import React, { forwardRef } from "react";
import { array, bool, string, func, object } from "prop-types";

import { FieldValidator } from "../../Form";

import Switch from "./SwitchRow";

const SwitchRowWithValidation = forwardRef(
  (
    {
      defaultValue,
      form,
      isRequired,
      label,
      name,
      validationMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;

    // stub the component's default onChange if it's not defined
    const onChange =
      typeof props.onChange === "function" ? props.onChange : () => {};

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={label}
        name={name}
        onChange={onChange}
        validationMethods={validationMethods}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <Switch
              errorMessage={errorMessage}
              headline={label}
              ref={ref}
              {...props}
              {...validatorProps}
              onSwitchChange={value => {
                validatorProps.onBlur();
                validatorProps.onChange(value, keyValue);
              }}
            />
          );
        }}
      </FieldValidator>
    );
  }
);

SwitchRowWithValidation.propTypes = {
  defaultValue: bool,
  form: object.isRequired,
  isRequired: bool,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func,
  onKeyPress: func,
  validationMethods: array
};

SwitchRowWithValidation.displayName = "SwitchRowWithValidation";

export default SwitchRowWithValidation;
