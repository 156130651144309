/*
  This component is inspired by Notice in the Web Design System.
  Once external packages are available this component should be
  replaced with @reckon-web/notice.
*/

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { element } from "prop-types";

export const Notice = ({ children }) => {
  const theme = useTheme();
  return (
    <div
      css={{
        backgroundColor: theme.colors.informativeBackground,
        borderRadius: theme.radii.medium,
        border: `1px solid ${theme.colors.informativeBorder}`,
        display: "flex",
        flex: 1,
        flexDirection: "row",
        outline: 0,
        padding: theme.spacing.large,
        "> p": { margin: 0 }
      }}
    >
      {children}
    </div>
  );
};

Notice.propTypes = {
  children: element
};
