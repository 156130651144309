/* eslint-disable react/prop-types */
// TODO: prop-types

import { Text, TextInput, View } from "react-native";
import React, { Component, createRef, forwardRef } from "react";
import PropTypes from "prop-types";

import { colors, spacing, radii, text } from "../../../core";
import { StackSpacer } from "../../Spacer";

class Input extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    hintLabel: PropTypes.string,
    isDisabled: PropTypes.bool,
    /** Defines what kind of soft keyboard is shown  on focus. See this guide for more info https://lefkowitz.me/visual-guide-to-react-native-textinput-keyboardtype-options/. */
    keyboardType: PropTypes.oneOf([
      "decimal-pad",
      "default",
      "email-address",
      "phone-pad",
      "number-pad",
      "numbers-and-punctuation",
      "numeric",
      "url"
    ]),
    label: PropTypes.string,
    /** Limits the maximum number of characters that can be entered.*/
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onHintPress: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string
  };
  inputRef = createRef();

  state = {
    isFocused: false
  };

  blur = (...args) => {
    if (this.inputRef.current) {
      this.inputRef.current.blur(...args);
    }
  };
  focus = (...args) => {
    if (this.inputRef.current) {
      this.inputRef.current.focus(...args);
    }
  };

  handleFocus = (...args) => {
    if (this.props.onFocus) {
      this.props.onFocus(...args);
    }
    this.setState({ isFocused: true });
  };
  handleBlur = (...args) => {
    if (this.props.onBlur) {
      this.props.onBlur(...args);
    }
    this.setState({ isFocused: false });
  };

  render() {
    const {
      errorMessage,
      hintLabel,
      label,
      onChange,
      onHintPress = () => {},
      ...props
    } = this.props;
    const { isFocused } = this.state;

    return (
      <View>
        {label && (
          <>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Label>{label}</Label>
              {hintLabel && (
                <Text
                  token="3.5x-500"
                  style={{ color: colors.action.dark }}
                  onPress={() => onHintPress()}
                >
                  {hintLabel}
                </Text>
              )}
            </View>
            <StackSpacer size="xsmall" />
          </>
        )}

        <InputWrapper>
          <InputPrimitive
            hasError={Boolean(errorMessage)}
            hasLabel={Boolean(label)}
            isFocused={isFocused}
            ref={this.inputRef}
            onChangeText={onChange}
            {...props}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
          />
        </InputWrapper>

        {errorMessage && (
          <>
            <StackSpacer size="xsmall" />
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </>
        )}
      </View>
    );
  }
}

export default Input;

// ==============================
// Styled Components
// ==============================

const ErrorMessage = props => (
  <Text
    style={{ ...text["3x-400"], color: colors.alert.danger.dark }}
    {...props}
  />
);
const Label = props => (
  <Text
    style={{ ...text["3.5x-500"], color: colors.text.neutral }}
    {...props}
  />
);
const InputWrapper = props => (
  <View
    style={{
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between"
    }}
    {...props}
  />
);
const InputPrimitive = forwardRef(
  ({ isDisabled, isFocused, ...props }, ref) => (
    <TextInput
      ref={ref}
      clearButtonMode="while-editing"
      editable={!isDisabled}
      placeholderTextColor={colors.text.muted}
      style={{
        ...text["4x-500"],
        padding: spacing.medium,
        borderRadius: radii.small,
        backgroundColor: isFocused
          ? colors.inputField.focus.light
          : isDisabled
          ? colors.inputField.disabled.light
          : colors.inputField.idle.light,
        color: isFocused
          ? colors.inputField.focus.dark
          : isDisabled
          ? colors.inputField.disabled.dark
          : colors.inputField.idle.dark,
        flex: 1,
        zIndex: 1
      }}
      {...props}
    />
  )
);
InputPrimitive.propTypes = {
  hasError: PropTypes.bool,
  hasLabel: PropTypes.bool,
  isDisabled: PropTypes.bool
};
InputPrimitive.defaultProps = {
  isDisabled: false
};
InputPrimitive.displayName = "InputPrimitive";
