import React from "react";
import { array, bool, object, oneOf, oneOfType } from "prop-types";
import { TouchableOpacity } from "react-native";

import { Icon } from "../../icons";
import { colors, sizing, radii } from "../../core";

const CircleButton = ({ isDisabled, icon, size, style, type, ...props }) => {
  const buttonSize = size === "sm" ? sizing.small : sizing.medium;
  const iconSize = size === "sm" ? sizing["2x"] : sizing.xsmall;

  const buttonColor = isDisabled
    ? colors.disabled.dark
    : type == "primary"
    ? colors.action.dark
    : colors.action.light;

  const iconColor =
    type === "primary" || isDisabled ? colors.action.light : colors.action.dark;

  const baseStyles = {
    borderRadius: radii.full,
    alignItems: "center",
    backgroundColor: buttonColor,
    height: buttonSize,
    justifyContent: "center",
    width: buttonSize
  };

  return (
    <TouchableOpacity
      disabled={isDisabled}
      {...props}
      activeOpacity={0.7}
      style={[baseStyles, style]}
    >
      <Icon icon={icon} color={iconColor} size={iconSize} />
    </TouchableOpacity>
  );
};

CircleButton.propTypes = {
  isDisabled: bool,
  icon: oneOf(["plus", "dots", "arrowLeft"]),
  size: oneOf(["sm", "lg"]),
  style: oneOfType([array, object]),
  type: oneOf(["primary", "secondary"])
};

CircleButton.defaultProps = {
  disabled: false,
  icon: "dots",
  type: "primary",
  size: "lg"
};

export default CircleButton;
