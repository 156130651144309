/** @jsx jsx */
import { useRef, useState, useEffect, forwardRef } from "react";
import { jsx } from "@emotion/core";
import { useMenus } from "docz";
import { useTheme } from "emotion-theming";

import { NavGroup, NavSearch, NavSectionTitle } from "./nav";
import { Logo } from "./logo";

export const Sidebar = forwardRef((props, ref) => {
  const [query, setQuery] = useState("");
  const menus = useMenus({ query });
  const currentDocRef = useRef();
  const { colors, spacing } = useTheme();

  const handleChange = ev => {
    setQuery(ev.target.value);
  };
  useEffect(() => {
    if (ref.current && currentDocRef.current) {
      ref.current.scrollTo(0, currentDocRef.current.offsetTop);
    }
  }, []);

  const HEADER_HEIGHT = 81;
  const styles = {
    global: {
      body: {
        overflow: "hidden"
      }
    },
    overlay: ({ open }) => ({
      zIndex: 999,
      position: "fixed",
      top: HEADER_HEIGHT,
      right: 0,
      bottom: 0,
      left: 0,
      background: "rgba(0,0,0,0.6)",
      transition: "all .2s ease-out",
      visibility: open ? "visible" : "hidden",
      opacity: open ? 1 : 0
    }),
    wrapper: {
      boxSizing: "border-box",
      padding: spacing.xlarge,
      flexDirection: "column",
      position: "sticky",
      top: 0,
      zIndex: 1,
      minWidth: 0,
      maxHeight: "100vh",
      borderRight: `1px solid ${colors.border}`,
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      backgroundColor: colors.backgroundMuted
    }
  };

  return (
    <aside ref={ref} css={styles.wrapper} data-testid="sidebar">
      <Logo />
      <NavSearch
        placeholder="Type to search..."
        value={query}
        onChange={handleChange}
      />
      <hr />
      <NavSectionTitle>Getting Started</NavSectionTitle>
      <NavGroup
        item={{
          name: "About",
          menu: [
            {
              name: "Home",
              route: "/"
            },
            {
              name: "Changelog",
              route: "/changelog"
            },
            {
              name: "Playroom",
              route: "/playroom"
            }
          ]
        }}
        sidebarRef={ref}
      />
      <NavSectionTitle>Components</NavSectionTitle>
      {menus
        .filter(
          menu =>
            !["Changelog", "Welcome", "Readme"].includes(menu.name) &&
            !menu.route
        )
        .map(menu => (
          <NavGroup key={menu.id} item={menu} sidebarRef={ref} />
        ))}
    </aside>
  );
});
