import { Props } from "docz";
import text from "../../../../../src/core/text/index";
import TextDemo from "../../../../../src/core/text/textDemo";
import TextComponent from "../../../../../src/components/Text";
import * as React from 'react';
export default {
  Props,
  text,
  TextDemo,
  TextComponent,
  React
};