import React from "react";
import { bool, func, instanceOf, oneOf, string } from "prop-types";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { format } from "date-fns";

import { colors, radii, sizing, spacing } from "../../core";
import Badge from "../Badge";
import { InlineSpacer, StackSpacer } from "../Spacer";
import Text from "../Text";
import { Icon } from "../../icons";

export const getBadgeType = {
  ACCEPTED: "success",
  DONE: "success",
  DRAFT: "idle",
  ERROR: "error",
  PENDING: "pending",
  PAID: "success",
  REJECTED: "error",
  READYTOSUBMIT: "pending",
  SUCCESS: "success",
  "-": "disabled"
};

const PayRunListItem = ({
  isArrowHidden,
  isFirst,
  isLast,
  onPress,
  primaryDate,
  secondaryText,
  status,
  tertiaryText,
  testID
}) => {
  const ss = StyleSheet.create({
    base: {
      backgroundColor: colors.background.elevated,
      marginHorizontal: -spacing.large,
      paddingHorizontal: spacing.large,
      ...(isFirst
        ? {
            borderTopLeftRadius: radii.medium,
            borderTopRightRadius: radii.medium
          }
        : {}),
      ...(isLast
        ? {
            borderBottomLeftRadius: radii.medium,
            borderBottomRightRadius: radii.medium
          }
        : {})
    },
    main: {
      paddingVertical: spacing.medium,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      alignContent: "center",
      borderBottomColor: colors.separator,
      borderBottomWidth: isLast ? 0 : StyleSheet.hairlineWidth
    }
  });

  return (
    <TouchableOpacity style={ss.base} onPress={onPress} testID={testID}>
      <View style={ss.main}>
        <Badge
          type={getBadgeType[status]}
          label={status.toLowerCase()}
          style={{ alignSelf: "center" }}
        />
        <InlineSpacer size="large" />

        <View style={{ flex: 1 }}>
          <Text token="4x-700">
            {format(new Date(primaryDate), "d MMMM yyyy")}
          </Text>
          <StackSpacer size="xsmall" />
          <Text token="3.5x-400">{secondaryText}</Text>
          {tertiaryText && (
            <>
              <StackSpacer size="xsmall" />
              <Text token="3x-400" style={{ color: colors.text.neutral }}>
                {tertiaryText}
              </Text>
            </>
          )}
        </View>
        <InlineSpacer size="small" />
        {!isArrowHidden && (
          <Icon icon="chevron" size={sizing["2x"]} color={colors.action.dark} />
        )}
      </View>
    </TouchableOpacity>
  );
};

PayRunListItem.propTypes = {
  isArrowHidden: bool,
  isFirst: bool,
  isLast: bool,
  onPress: func.isRequired,
  primaryDate: instanceOf(Date).isRequired,
  secondaryText: string.isRequired,
  status: oneOf(Object.keys(getBadgeType)),
  tertiaryText: string,
  testID: string
};

export default PayRunListItem;
