import { useTheme } from "emotion-theming";

export const styles = () => {
  const { colors, fontSize, spacing, radii } = useTheme();

  const column = {
    minWidth: 0,
    paddingBottom: spacing.medium,
    paddingLeft: spacing.medium,
    paddingRight: spacing.medium
  };

  return {
    container: {
      marginTop: spacing.medium,
      marginBottom: spacing.medium,
      border: `1px solid ${colors.border}`,
      borderRadius: radii.medium,
      overflow: "hidden",
      backgroundColor: colors.backgroundMuted,
      color: colors.foreground,
      fontSize: fontSize.xlarge
    },

    content: {
      position: "relative",
      display: "flex"
    },

    line: {
      paddingTop: spacing.medium,
      "& + &": {
        borderTop: `1px solid ${colors.border}`
      }
    },

    propName: {
      ...column,
      color: colors.foregroundHighlight
    },

    propType: {
      ...column,
      color: colors.foreground
    },

    defaultValue: {
      ...column,
      color: colors.foregroundMuted
    },

    right: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingLeft: spacing.medium,
      paddingRight: spacing.medium,
      flex: 1
    },

    propRequired: {
      color: colors.foregroundMuted,
      fontSize: fontSize.small
    },

    openDescBtn: {
      padding: "none",
      outline: "none",
      background: "transparent",
      border: "none",
      ":hover": {
        cursor: "pointer"
      },
      marginTop: 0,
      marginLeft: spacing.medium,
      color: colors.linkColor
    },

    description: {
      fontSize: fontSize.medium,
      margin: 0,
      paddingTop: spacing.medium,
      paddingBottom: spacing.medium,
      paddingLeft: spacing.medium,
      paddingRight: spacing.medium,
      borderTop: `1px solid ${colors.border}`,
      color: colors.foreground,
      backgroundColor: colors.backgroundDim
    }
  };
};
