// ==============================
// Default Validation Methods
// ==============================

// required
const isRequired = field => {
  const label = field.label || "Field";

  if (
    field.value !== undefined &&
    field.value !== "" &&
    field.value !== null &&
    field.value.toString().length
  ) {
    return false;
  }

  return `${label} is required`;
};

// NOTE: If there is no value on a field the length IS NOT enforced. This is so
// we can validate a "non-required" field.

// min length
const minLength = minLength => field => {
  const label = field.label || "Field";

  if (!field.value || (field.value && field.value.length >= minLength)) {
    return false;
  }

  return `${label} must be at least ${minLength} characters`;
};

// max length
const maxLength = maxLength => field => {
  const label = field.label || "Field";

  if (!field.value || (field.value && field.value.length <= maxLength)) {
    return false;
  }

  return `${label} must be no more than ${maxLength} characters`;
};

// exact length
const exactLength = length => field => {
  const label = field.label || "Field";

  if (!field.value || (field.value && field.value.length === length)) {
    return false;
  }

  return `${label} must be ${length} characters`;
};

// email
const isEmail = field => {
  const label = field.label || "Email";

  if (
    !field.value ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      field.value
    )
  ) {
    return false;
  }

  return `${label} is invalid`;
};

// date
const isDate = field => {
  const label = field.label || "Date";

  // NOTE: this does not restrict month to <= 12 or days to <= 31.
  // That should be handled server-side.
  if (!field.value || /^\d{2}\/\d{2}\/\d{4}$/.test(field.value)) {
    return false;
  }

  return `${label} is invalid`;
};

const isPhone = field => {
  const label = field.label || "Field";

  if (!field.value || /^\+?[0-9 ]+$/.test(field.value)) {
    return false;
  }

  return `${label} is invalid`;
};

// max amount
const maxAmount = maxAmount => field => {
  const label = field.label || "Field";

  if (!field.value || field.value <= maxAmount) {
    return false;
  }

  return `${label} must be ${maxAmount} or less`;
};

// min amount
const minAmount = minAmount => field => {
  const label = field.label || "Field";

  if (!field.value || field.value >= minAmount) {
    return false;
  }

  return `${label} must be ${minAmount} or more`;
};

// value only contains numbers
const hasOnlyNumbers = field => {
  const label = field.label || "Field";
  if (!field.value || /^[0-9]+$/.test(field.value)) {
    return false;
  }
  return `${label} must only contain numbers`;
};

// matches 04XXXXXXXX format
const isAustralianMobilePhone = field => {
  const label = field.label || "Field";

  if (!field.value || /^04[\d]{8}$/.test(field.value)) {
    return false;
  }

  return `${label} is invalid`;
};

// Used for validation of number-based inputs
// such as NumberInput and CurrencyInput
const isNumber = ({ label = "Field", value }) => {
  if (value === undefined || value === null) {
    return false;
  } else if (isNaN(value)) {
    return `${label} must be a valid number.`;
  } else {
    return false;
  }
};

export default {
  exactLength,
  hasOnlyNumbers,
  isAustralianMobilePhone,
  isDate,
  isEmail,
  isNumber,
  isPhone,
  isRequired,
  maxAmount,
  minAmount,
  maxLength,
  minLength
};
