import { Form } from "../../../../../src/components/Form";
import NumberInput from "../../../../../src/components/NumberInput/src/numberInput";
import NumberInputWithValidation from "../../../../../src/components/NumberInput/src/numberInputWithValidation";
import { Props, Playground } from "docz";
import Text from "../../../../../src/components/Text";
import * as React from 'react';
export default {
  Form,
  NumberInput,
  NumberInputWithValidation,
  Props,
  Playground,
  Text,
  React
};