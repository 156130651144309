// ==============================
// InputWithValidation
// ==============================

import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import FieldValidator from "../../Form/components/fieldValidator";

import Input from "./input";

const FormType = PropTypes.shape({
  addError: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
});

// wrap up the input in a field validator for easy consumption
const InputWithValidation = forwardRef(
  (
    {
      defaultValue,
      form,
      isRequired,
      label,
      name,
      onBlur,
      onFocus,
      onChange,
      onKeyPress,
      validationMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;
    const handleKeyPress = event => {
      if (onKeyPress) onKeyPress(event);
      keyValue = event.nativeEvent.key;
    };

    const validationLabel = label.replace("*", "");

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={validationLabel}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        validationMethods={validationMethods}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <Input
              errorMessage={errorMessage}
              label={label}
              ref={ref}
              {...props}
              {...validatorProps}
              onChange={text => validatorProps.onChange(text, keyValue)}
              onKeyPress={handleKeyPress}
            />
          );
        }}
      </FieldValidator>
    );
  }
);
InputWithValidation.propTypes = {
  defaultValue: PropTypes.string,
  form: FormType.isRequired,
  /** Enables the use of the input hint link */
  hintLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  /** Limits the maximum number of characters that can be entered.*/
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func, // NOTE: if provided must return the value. used for transforms
  onFocus: PropTypes.func,
  onHintPress: PropTypes.func,
  onKeyPress: PropTypes.func,
  validationMethods: PropTypes.arrayOf(PropTypes.func).isRequired
};
InputWithValidation.displayName = "InputWithValidation";
InputWithValidation.defaultProps = {
  onChange: value => value,
  validationMethods: []
};

export default InputWithValidation;
