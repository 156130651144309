import React from "react";
import { View, StyleSheet } from "react-native";
import PropTypes from "prop-types";

import FlexGroup from "../FlexGroup";
import { SafeAreaView } from "../SafeArea";
import { colors, spacing } from "../../core";

const Footer = ({ children, direction, hideDivider }) => {
  return (
    <SafeAreaView forceInset={{ top: "never", bottom: "always" }}>
      <View
        style={{
          paddingHorizontal: spacing.xxlarge,
          paddingVertical: spacing.large,
          ...(hideDivider
            ? {}
            : {
                borderTopColor: colors.separator,
                borderTopWidth: StyleSheet.hairlineWidth
              })
        }}
      >
        <FlexGroup direction={direction}>{children}</FlexGroup>
      </View>
    </SafeAreaView>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(["row", "column"]),
  /** Hides the thin grey border at the top of the Footer */
  hideDivider: PropTypes.bool
};

export default Footer;
