// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("./../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-helpers-helpers-mdx": () => import("./../../src/helpers/helpers.mdx" /* webpackChunkName: "component---src-helpers-helpers-mdx" */),
  "component---changelog-mdx": () => import("./../../changelog.mdx" /* webpackChunkName: "component---changelog-mdx" */),
  "component---welcome-mdx": () => import("./../../welcome.mdx" /* webpackChunkName: "component---welcome-mdx" */),
  "component---posts-v-2-upgrade-mdx": () => import("./../../posts/v2_upgrade.mdx" /* webpackChunkName: "component---posts-v-2-upgrade-mdx" */),
  "component---src-icons-readme-mdx": () => import("./../../src/icons/README.mdx" /* webpackChunkName: "component---src-icons-readme-mdx" */),
  "component---src-components-android-biometric-input-modal-readme-mdx": () => import("./../../src/components/AndroidBiometricInputModal/readme.mdx" /* webpackChunkName: "component---src-components-android-biometric-input-modal-readme-mdx" */),
  "component---src-components-action-sheet-readme-mdx": () => import("./../../src/components/ActionSheet/readme.mdx" /* webpackChunkName: "component---src-components-action-sheet-readme-mdx" */),
  "component---src-components-add-item-button-readme-mdx": () => import("./../../src/components/AddItemButton/readme.mdx" /* webpackChunkName: "component---src-components-add-item-button-readme-mdx" */),
  "component---src-components-app-wrapper-readme-mdx": () => import("./../../src/components/AppWrapper/README.mdx" /* webpackChunkName: "component---src-components-app-wrapper-readme-mdx" */),
  "component---src-components-avatar-readme-mdx": () => import("./../../src/components/Avatar/README.mdx" /* webpackChunkName: "component---src-components-avatar-readme-mdx" */),
  "component---src-components-badge-readme-mdx": () => import("./../../src/components/Badge/README.mdx" /* webpackChunkName: "component---src-components-badge-readme-mdx" */),
  "component---src-components-button-readme-mdx": () => import("./../../src/components/Button/README.mdx" /* webpackChunkName: "component---src-components-button-readme-mdx" */),
  "component---src-components-box-box-mdx": () => import("./../../src/components/Box/box.mdx" /* webpackChunkName: "component---src-components-box-box-mdx" */),
  "component---src-components-card-card-mdx": () => import("./../../src/components/Card/card.mdx" /* webpackChunkName: "component---src-components-card-card-mdx" */),
  "component---src-components-carousel-readme-mdx": () => import("./../../src/components/Carousel/readme.mdx" /* webpackChunkName: "component---src-components-carousel-readme-mdx" */),
  "component---src-components-circle-button-readme-mdx": () => import("./../../src/components/CircleButton/README.mdx" /* webpackChunkName: "component---src-components-circle-button-readme-mdx" */),
  "component---src-components-currency-input-readme-mdx": () => import("./../../src/components/CurrencyInput/readme.mdx" /* webpackChunkName: "component---src-components-currency-input-readme-mdx" */),
  "component---src-components-data-row-readme-mdx": () => import("./../../src/components/DataRow/README.mdx" /* webpackChunkName: "component---src-components-data-row-readme-mdx" */),
  "component---src-components-date-picker-datepicker-mdx": () => import("./../../src/components/DatePicker/datepicker.mdx" /* webpackChunkName: "component---src-components-date-picker-datepicker-mdx" */),
  "component---src-components-date-picker-daterangepicker-mdx": () => import("./../../src/components/DatePicker/daterangepicker.mdx" /* webpackChunkName: "component---src-components-date-picker-daterangepicker-mdx" */),
  "component---src-components-date-select-date-select-mdx": () => import("./../../src/components/DateSelect/DateSelect.mdx" /* webpackChunkName: "component---src-components-date-select-date-select-mdx" */),
  "component---src-components-divider-readme-mdx": () => import("./../../src/components/Divider/readme.mdx" /* webpackChunkName: "component---src-components-divider-readme-mdx" */),
  "component---src-components-ellipsis-readme-mdx": () => import("./../../src/components/Ellipsis/readme.mdx" /* webpackChunkName: "component---src-components-ellipsis-readme-mdx" */),
  "component---src-components-employee-list-item-readme-mdx": () => import("./../../src/components/EmployeeListItem/readme.mdx" /* webpackChunkName: "component---src-components-employee-list-item-readme-mdx" */),
  "component---src-components-error-message-readme-mdx": () => import("./../../src/components/ErrorMessage/readme.mdx" /* webpackChunkName: "component---src-components-error-message-readme-mdx" */),
  "component---src-components-flex-group-readme-mdx": () => import("./../../src/components/FlexGroup/readme.mdx" /* webpackChunkName: "component---src-components-flex-group-readme-mdx" */),
  "component---src-components-footer-readme-mdx": () => import("./../../src/components/Footer/readme.mdx" /* webpackChunkName: "component---src-components-footer-readme-mdx" */),
  "component---src-components-form-readme-mdx": () => import("./../../src/components/Form/readme.mdx" /* webpackChunkName: "component---src-components-form-readme-mdx" */),
  "component---src-components-header-readme-mdx": () => import("./../../src/components/Header/README.mdx" /* webpackChunkName: "component---src-components-header-readme-mdx" */),
  "component---src-components-hidden-input-readme-mdx": () => import("./../../src/components/HiddenInput/readme.mdx" /* webpackChunkName: "component---src-components-hidden-input-readme-mdx" */),
  "component---src-components-keyboard-aware-scroll-view-readme-mdx": () => import("./../../src/components/KeyboardAwareScrollView/readme.mdx" /* webpackChunkName: "component---src-components-keyboard-aware-scroll-view-readme-mdx" */),
  "component---src-components-link-row-readme-mdx": () => import("./../../src/components/LinkRow/readme.mdx" /* webpackChunkName: "component---src-components-link-row-readme-mdx" */),
  "component---src-components-input-readme-mdx": () => import("./../../src/components/Input/README.mdx" /* webpackChunkName: "component---src-components-input-readme-mdx" */),
  "component---src-components-loader-readme-mdx": () => import("./../../src/components/Loader/readme.mdx" /* webpackChunkName: "component---src-components-loader-readme-mdx" */),
  "component---src-components-number-input-readme-mdx": () => import("./../../src/components/NumberInput/readme.mdx" /* webpackChunkName: "component---src-components-number-input-readme-mdx" */),
  "component---src-components-logo-readme-mdx": () => import("./../../src/components/Logo/readme.mdx" /* webpackChunkName: "component---src-components-logo-readme-mdx" */),
  "component---src-components-pay-item-readme-mdx": () => import("./../../src/components/PayItem/README.mdx" /* webpackChunkName: "component---src-components-pay-item-readme-mdx" */),
  "component---src-components-pay-run-list-item-readme-mdx": () => import("./../../src/components/PayRunListItem/readme.mdx" /* webpackChunkName: "component---src-components-pay-run-list-item-readme-mdx" */),
  "component---src-components-page-hint-readme-mdx": () => import("./../../src/components/PageHint/README.mdx" /* webpackChunkName: "component---src-components-page-hint-readme-mdx" */),
  "component---src-components-percentage-input-readme-mdx": () => import("./../../src/components/PercentageInput/readme.mdx" /* webpackChunkName: "component---src-components-percentage-input-readme-mdx" */),
  "component---src-components-pill-bar-pillbar-mdx": () => import("./../../src/components/PillBar/pillbar.mdx" /* webpackChunkName: "component---src-components-pill-bar-pillbar-mdx" */),
  "component---src-components-progress-bar-readme-mdx": () => import("./../../src/components/ProgressBar/README.mdx" /* webpackChunkName: "component---src-components-progress-bar-readme-mdx" */),
  "component---src-components-rn-4-bottom-tab-bar-readme-mdx": () => import("./../../src/components/RN4BottomTabBar/readme.mdx" /* webpackChunkName: "component---src-components-rn-4-bottom-tab-bar-readme-mdx" */),
  "component---src-components-radio-button-readme-mdx": () => import("./../../src/components/RadioButton/README.mdx" /* webpackChunkName: "component---src-components-radio-button-readme-mdx" */),
  "component---src-components-pin-code-input-readme-mdx": () => import("./../../src/components/PinCodeInput/readme.mdx" /* webpackChunkName: "component---src-components-pin-code-input-readme-mdx" */),
  "component---src-components-safe-area-readme-mdx": () => import("./../../src/components/SafeArea/readme.mdx" /* webpackChunkName: "component---src-components-safe-area-readme-mdx" */),
  "component---src-components-screen-readme-mdx": () => import("./../../src/components/Screen/readme.mdx" /* webpackChunkName: "component---src-components-screen-readme-mdx" */),
  "component---src-components-section-container-readme-mdx": () => import("./../../src/components/SectionContainer/README.mdx" /* webpackChunkName: "component---src-components-section-container-readme-mdx" */),
  "component---src-components-section-title-readme-mdx": () => import("./../../src/components/SectionTitle/readme.mdx" /* webpackChunkName: "component---src-components-section-title-readme-mdx" */),
  "component---src-components-segmented-control-readme-mdx": () => import("./../../src/components/SegmentedControl/readme.mdx" /* webpackChunkName: "component---src-components-segmented-control-readme-mdx" */),
  "component---src-components-select-readme-mdx": () => import("./../../src/components/Select/readme.mdx" /* webpackChunkName: "component---src-components-select-readme-mdx" */),
  "component---src-components-selectable-card-readme-mdx": () => import("./../../src/components/SelectableCard/readme.mdx" /* webpackChunkName: "component---src-components-selectable-card-readme-mdx" */),
  "component---src-components-sheet-readme-mdx": () => import("./../../src/components/Sheet/readme.mdx" /* webpackChunkName: "component---src-components-sheet-readme-mdx" */),
  "component---src-components-skeleton-readme-mdx": () => import("./../../src/components/Skeleton/README.mdx" /* webpackChunkName: "component---src-components-skeleton-readme-mdx" */),
  "component---src-components-slide-up-modal-readme-mdx": () => import("./../../src/components/SlideUpModal/readme.mdx" /* webpackChunkName: "component---src-components-slide-up-modal-readme-mdx" */),
  "component---src-components-spacer-readme-mdx": () => import("./../../src/components/Spacer/README.mdx" /* webpackChunkName: "component---src-components-spacer-readme-mdx" */),
  "component---src-components-stack-readme-mdx": () => import("./../../src/components/Stack/readme.mdx" /* webpackChunkName: "component---src-components-stack-readme-mdx" */),
  "component---src-components-switch-row-readme-mdx": () => import("./../../src/components/SwitchRow/readme.mdx" /* webpackChunkName: "component---src-components-switch-row-readme-mdx" */),
  "component---src-components-tab-bar-readme-mdx": () => import("./../../src/components/TabBar/README.mdx" /* webpackChunkName: "component---src-components-tab-bar-readme-mdx" */),
  "component---src-components-deprecated-back-arrow-mdx": () => import("./../../src/components/deprecated/backArrow.mdx" /* webpackChunkName: "component---src-components-deprecated-back-arrow-mdx" */),
  "component---src-components-deprecated-close-button-mdx": () => import("./../../src/components/deprecated/closeButton.mdx" /* webpackChunkName: "component---src-components-deprecated-close-button-mdx" */),
  "component---src-components-deprecated-modal-mdx": () => import("./../../src/components/deprecated/modal.mdx" /* webpackChunkName: "component---src-components-deprecated-modal-mdx" */),
  "component---src-core-colors-readme-mdx": () => import("./../../src/core/colors/README.mdx" /* webpackChunkName: "component---src-core-colors-readme-mdx" */),
  "component---src-core-radii-radii-mdx": () => import("./../../src/core/radii/radii.mdx" /* webpackChunkName: "component---src-core-radii-radii-mdx" */),
  "component---src-core-sizing-sizing-mdx": () => import("./../../src/core/sizing/sizing.mdx" /* webpackChunkName: "component---src-core-sizing-sizing-mdx" */),
  "component---src-core-text-readme-mdx": () => import("./../../src/core/text/readme.mdx" /* webpackChunkName: "component---src-core-text-readme-mdx" */),
  "component---src-core-spacing-spacing-mdx": () => import("./../../src/core/spacing/spacing.mdx" /* webpackChunkName: "component---src-core-spacing-spacing-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

