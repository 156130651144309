import React from "react";
import { View } from "react-native";
import { oneOf, oneOfType, node, number, string } from "prop-types";

import { colors, spacing, radii } from "../../core";
import { StylePropType } from "../../helpers";

import {
  getMarginStyles,
  getPaddingStyles,
  getRadiiStyles,
  getFlexStyles,
  alignmentMap,
  justificationMap,
  orientationMap
} from "./utils";

const Box = ({
  align,
  background,
  basis,
  children,
  flex,
  grow,
  height,
  justify,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  orientation,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  rounding,
  roundingBottom,
  roundingLeft,
  roundingRight,
  roundingTop,
  shrink,
  style,
  width,
  ...props
}) => {
  const marginObj = getMarginStyles({
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX,
    marginY
  });

  const paddingObj = getPaddingStyles({
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY
  });
  const radiiObj = getRadiiStyles({
    rounding,
    roundingBottom,
    roundingLeft,
    roundingRight,
    roundingTop
  });
  const flexStyles = getFlexStyles({
    flex,
    grow,
    shrink,
    basis
  });

  const backgroundColor =
    background !== "none" ? colors["background"][background] : undefined;

  return (
    <View
      {...props}
      style={{
        alignItems: alignmentMap[align],
        ...flexStyles,
        backgroundColor,
        ...radiiObj,
        flexDirection: orientationMap[orientation],
        height,
        justifyContent: justificationMap[justify],
        ...marginObj,
        ...paddingObj,
        width,
        ...style
      }}
    >
      {children}
    </View>
  );
};

const spacingType = oneOf(Object.keys(spacing));

Box.propTypes = {
  /** The align prop maps to the align-items CSS property, and defines the alignment of items along the cross-axis of the flex container. */
  align: oneOf(Object.keys(alignmentMap)),
  /** Background color */
  background: oneOf(["none", "default", "sunken", "elevated"]),
  /** Define the initial main size of a flex item. */
  basis: oneOfType([number, string]),
  /** The content of this flex container. */
  children: node,
  /** You can pass shorthand values directly to the CSS flex property. e.g.<Box flex="0 1 auto" /> */
  flex: oneOfType([number, string]),
  /** Define grow "factor" of a flex item. It accepts a unitless value, which dictates the amount of available space inside the flex container the item should take up. */
  grow: number,
  /** Height */
  height: number,
  /** The justify prop maps to the justify-content CSS property, and defines the distribution of space between items along the main-axis of the flex container. */
  justify: oneOf(Object.keys(justificationMap)),
  /** Margin */
  margin: spacingType,
  /** Margin bottom */
  marginBottom: spacingType,
  /** Margin left */
  marginLeft: spacingType,
  /** Margin right */
  marginRight: spacingType,
  /** Margin top */
  marginTop: spacingType,
  /** Margin horizontal */
  marginX: spacingType,
  /** Margin vertical */
  marginY: spacingType,
  /** The orientation prop maps to the flex direction CSS property, allowing us to maintain a consistent API among the design system primitives. */
  orientation: oneOf(["horizontal", "vertical"]),
  /** Padding */
  padding: spacingType,
  /** Padding bottom */
  paddingBottom: spacingType,
  /** Padding left */
  paddingLeft: spacingType,
  /** Padding right */
  paddingRight: spacingType,
  /** Padding top */
  paddingTop: spacingType,
  /** Padding horizontal */
  paddingX: spacingType,
  /** Padding vertical */
  paddingY: spacingType,
  /** Radius */
  rounding: oneOf(Object.keys(radii)),
  /** Radius bottom */
  roundingBottom: oneOf(Object.keys(radii)),
  /** Radius left */
  roundingLeft: oneOf(Object.keys(radii)),
  /** Radius right */
  roundingRight: oneOf(Object.keys(radii)),
  /** Radius top */
  roundingTop: oneOf(Object.keys(radii)),
  /** Define shrink "factor" of a flex item. It accepts a unitless value, which dictates the amount of available space inside the flex container the item should take up. */
  shrink: number,
  /** The regular style prop */
  style: StylePropType,
  /** Width */
  width: number
};

Box.defaultProps = {
  background: "none",
  orientation: "vertical"
};

export default Box;
