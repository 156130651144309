/* eslint-disable react/prop-types */
// TODO: prop-types

import React from "react";
import PropTypes from "prop-types";
import { View, TouchableOpacity } from "react-native";

import { boxShadow, colors, sizing, spacing, radii } from "../../core";
import Text from "../Text";
import { Icon } from "../../icons";

const Card = ({
  children,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  style,
  title,
  rounding,
  buttonLabel,
  onButtonPress,
  innerStyle,
  ...props
}) => {
  const marginObj = {
    marginTop: spacing[marginTop || marginY || margin],
    marginBottom: spacing[marginBottom || marginY || margin],
    marginLeft: spacing[marginLeft || marginX || margin],
    marginRight: spacing[marginRight || marginX || margin]
  };

  const paddingObj = {
    paddingTop: spacing[paddingTop || paddingY || padding],
    paddingBottom: spacing[paddingBottom || paddingY || padding],
    paddingLeft: spacing[paddingLeft || paddingX || padding],
    paddingRight: spacing[paddingRight || paddingX || padding]
  };

  return (
    <View
      style={{
        ...marginObj,
        backgroundColor: colors.background.elevated,
        borderRadius: radii[rounding],
        ...boxShadow,
        ...style
      }}
      {...props}
    >
      {title && (
        <Header
          title={title}
          buttonLabel={buttonLabel}
          onButtonPress={onButtonPress}
        />
      )}
      <View style={{ ...innerStyle, ...paddingObj }}>{children}</View>
    </View>
  );
};

const Header = ({ title, onButtonPress }) => (
  <View style={{ paddingHorizontal: spacing.large }}>
    <View
      style={{
        paddingVertical: spacing.large,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottomColor: colors.separator,
        borderBottomWidth: 1
      }}
    >
      <Text token="blockTitle-1" style={{ color: colors.highlighted.dark }}>
        {title}
      </Text>
      {onButtonPress && (
        <TouchableOpacity onPress={onButtonPress}>
          <Icon icon="add" color={colors.action.dark} size={sizing["2x"]} />
        </TouchableOpacity>
      )}
    </View>
  </View>
);

const spacingType = PropTypes.oneOf(Object.keys(spacing));

Card.propTypes = {
  /** Content that will populate the card */
  children: PropTypes.node.isRequired,
  /** Margin */
  margin: spacingType,
  /** Margin bottom */
  marginBottom: spacingType,
  /** Margin left */
  marginLeft: spacingType,
  /** Margin right */
  marginRight: spacingType,
  /** Margin top */
  marginTop: spacingType,
  /** Margin horizontal */
  marginX: spacingType,
  /** Margin vertical */
  marginY: spacingType,
  /** Padding */
  padding: spacingType,
  /** Padding bottom */
  paddingBottom: spacingType,
  /** Padding left */
  paddingLeft: spacingType,
  /** Padding right */
  paddingRight: spacingType,
  /** Padding top */
  paddingTop: spacingType,
  /** Padding horizontal */
  paddingX: spacingType,
  /** Padding vertical */
  paddingY: spacingType,
  /** Action that fires when action is pressed */
  onButtonPress: PropTypes.func,
  /** Radius */
  rounding: PropTypes.oneOf(Object.keys(radii)),
  /** Style object that is applied to the base component */
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Title that appears a the top of the card. This will cause the Header to appear */
  title: PropTypes.string,
  /** Style object that is applied to the child component */
  innerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

Card.defaultProps = {
  rounding: "medium",
  padding: "large"
};

export default Card;

// Styles
