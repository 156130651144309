/** @jsx jsx */
import { useRef, useState } from "react";
import { Global, jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { element } from "prop-types";

import { MainContainer } from "./mainContainer";
import { Sidebar } from "./sidebar";
// import { media } from "~theme/breakpoints";

export const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const nav = useRef();
  const { fontSize, fontFamily, spacing, colors, leading, radii } = useTheme();

  const globalStyles = {
    body: {
      margin: 0,
      padding: 0,
      fontFamily: fontFamily.body
    },
    ".icon-link": {
      display: "none"
    },
    ".with-overlay": {
      overflow: "hidden"
    },
    p: {
      fontSize: fontSize.medium,
      lineHeight: leading.base
    },
    a: {
      color: colors.linkColor,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    blockquote: {
      marginTop: spacing.medium,
      margingBottom: spacing.medium,
      marginLeft: 0,
      marginRight: 0,
      padding: spacing.small,
      background: colors.backgroundMuted,
      borderLeft: `5px solid ${colors.border}`,
      color: colors.foregroundMuted,
      fontStyle: "italic",
      "> p": {
        margin: 0
      }
    },
    code: {
      backgroundColor: colors.codeBackground,
      borderRadius: radii.xsmall,
      color: colors.codeForeground,
      display: "inline-block",
      fontFamily: fontFamily.monospace,
      fontSize: fontSize.small,
      paddingLeft: "0.2em",
      paddingRight: "0.2em"
    },
    inlineCode: {
      fontFamily: fontFamily.monospace
    },
    pre: {
      border: `1px solid ${colors.border}`,
      background: colors.backgroundMuted,
      marginTop: spacing.small,
      marginBottom: spacing.small,
      padding: spacing.small,
      variant: "prism",
      textAlign: "left",
      fontFamily: fontFamily.monospace,
      fontSize: fontSize.medium,
      lineHeight: leading.base,
      borderRadius: 6
    },
    table: {
      width: "100%",
      marginTop: spacing.small,
      marginBottom: spacing.small,
      borderCollapse: "separate",
      borderSpacing: 0,
      [["th", "td"]]: {
        textAlign: "left",
        padding: spacing.small,
        borderColor: colors.border,
        borderBottomStyle: "solid"
      }
    },
    th: {
      verticalAlign: "bottom",
      borderBottomWidth: "2px"
    },
    td: {
      verticalAlign: "top",
      borderBottomWidth: "1px"
    },
    hr: {
      border: 0,
      borderBottom: `1px solid ${colors.border}`
    }
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
      }}
    >
      <Global styles={globalStyles} />
      <div
        css={{
          padding: "none",
          flex: 1,
          display: "grid",
          gridTemplateColumns: "320px 1fr",
          minHeight: "100vh"
        }}
      >
        <Sidebar
          ref={nav}
          open={open}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          onClick={() => setOpen(false)}
        />
        <MainContainer data-testid="main-container">{children}</MainContainer>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: element
};
