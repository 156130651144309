import React from "react";
import { TouchableOpacity, View } from "react-native";
import {
  arrayOf,
  bool,
  func,
  shape as objectShape,
  oneOfType,
  string
} from "prop-types";
import hexAlpha from "hex-alpha";

import { Icon } from "../../../icons";
import { colors, spacing, radii } from "../../../core";
import Text from "../../Text";
import { StackSpacer } from "../../Spacer";

const SegmentedControl = ({
  options: originalOptions,
  label,
  value,
  onChange,
  fullWidth
}) => {
  const options = originalOptions.map(option =>
    typeof option === "string"
      ? {
          label: option,
          value: option
        }
      : option
  );

  return (
    <>
      <Text token="3.5x-500" style={{ color: colors.text.neutral }}>
        {label}
      </Text>
      <StackSpacer size="xsmall" />
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          margin: -2
        }}
      >
        {options.map((option, index) => {
          const isSelected = value === option.value;
          return (
            <Segment
              key={index}
              option={option}
              onPress={onChange}
              isSelected={isSelected}
              fullWidth={fullWidth}
            />
          );
        })}
      </View>
    </>
  );
};

SegmentedControl.propTypes = {
  /** Determines if the items should be 100% width of the container */
  fullWidth: bool,
  /** The label that appears above the input field */
  label: string,
  /** The function that runs on tap, will return the value of selected item */
  onChange: func.isRequired,
  /** The options that will be rendered, each can be either a string, or an object with `label` and `value` keys */
  options: arrayOf(
    oneOfType([
      string,
      objectShape({
        label: string.isRequired,
        value: string.isRequired,
        isDisabled: bool
      })
    ])
  ).isRequired,
  /** The value of the active item */
  value: string
};

const Segment = ({
  option: { value, label, isDisabled },
  isSelected,
  onPress,
  fullWidth
}) => {
  const segmentStyles = {
    padding: spacing.large,
    borderRadius: radii.small,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: isSelected
      ? colors.selected.dark
      : isDisabled
      ? hexAlpha(colors.disabled.light, 0.5)
      : colors.inputField.idle.light
  };

  const labelColor = isSelected
    ? colors.selected.light
    : isDisabled
    ? colors.disabled.dark
    : colors.text.default;

  return (
    <TouchableOpacity
      disabled={isDisabled}
      onPress={() => onPress(value)}
      style={{ flexBasis: fullWidth ? "100%" : "50%", padding: 2 }}
    >
      <View style={segmentStyles}>
        <Text token="3x-500" style={{ color: labelColor }}>
          {label}
        </Text>
        <Icon
          color={colors.selected.light}
          style={{ opacity: isSelected ? 1 : 0 }}
          icon="tickEnclosed"
          size={16}
        />
      </View>
    </TouchableOpacity>
  );
};

Segment.propTypes = {
  isSelected: bool,
  onPress: func.isRequired,
  fullWidth: bool,
  option: objectShape({
    label: string,
    value: string,
    isDisabled: bool
  })
};

export default SegmentedControl;
