import React from "react";
import { func, bool, string } from "prop-types";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import Avatar from "../Avatar";
import Text from "../Text";
import { colors, radii, spacing, sizing } from "../../core";
import { Icon } from "../../icons";
import { StackSpacer, InlineSpacer } from "../Spacer";

const EmployeeListItem = ({
  name,
  secondaryText,
  isDisabled,
  isFirst,
  isLast,
  onPress,
  testID
}) => {
  const ss = StyleSheet.create({
    base: {
      backgroundColor: colors.background.elevated,
      marginHorizontal: -spacing.large,
      paddingHorizontal: spacing.large,
      ...(isFirst
        ? {
            borderTopLeftRadius: radii.medium,
            borderTopRightRadius: radii.medium
          }
        : {}),
      ...(isLast
        ? {
            borderBottomLeftRadius: radii.medium,
            borderBottomRightRadius: radii.medium
          }
        : {})
    },
    main: {
      paddingVertical: spacing.medium,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      alignContent: "center",
      borderBottomColor: colors.separator,
      borderBottomWidth: isLast ? 0 : StyleSheet.hairlineWidth
    }
  });

  return (
    <TouchableOpacity testID={testID} style={ss.base} onPress={onPress}>
      <View style={ss.main}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            flex: 1
          }}
        >
          <Avatar size="md" isDisabled={isDisabled} name={name} />
          <InlineSpacer size="large" />
          <View style={{ flex: 1 }}>
            <Text token="4x-500" numberOfLines={1}>
              {name}
            </Text>
            <StackSpacer size="xsmall" />

            {secondaryText && (
              <Text token="3.5x-400" style={{ color: colors.text.neutral }}>
                {secondaryText}
              </Text>
            )}
          </View>
        </View>
        <Icon icon="chevron" size={sizing["2x"]} color={colors.action.dark} />
      </View>
    </TouchableOpacity>
  );
};

EmployeeListItem.propTypes = {
  isDisabled: bool,
  isFirst: bool,
  isLast: bool,
  name: string.isRequired,
  onPress: func.isRequired,
  secondaryText: string,
  testID: string
};

export default EmployeeListItem;
