import React from "react";
import PropTypes from "prop-types";
import { Animated, View } from "react-native";

const Pagination = ({ totalPages, position, paginationColor }) => {
  const items = Array.from({ length: totalPages });

  return (
    <View style={{ flexDirection: "row" }}>
      {items.map((_, i) => {
        let opacity = position.interpolate({
          inputRange: [
            i - 0.50000000001,
            i - 0.5,
            i,
            i + 0.5,
            i + 0.50000000001
          ],
          outputRange: [0.2, 1, 1, 1, 0.2],
          extrapolate: "clamp",
          delay: 200
        });

        return (
          <Animated.View
            key={i}
            style={{
              opacity,
              height: 7,
              width: 7,
              backgroundColor: paginationColor || "rgb(0,0,0)",
              margin: 6,
              borderRadius: 7 / 2
            }}
          />
        );
      })}
    </View>
  );
};

Pagination.propTypes = {
  position: PropTypes.object,
  totalPages: PropTypes.number,
  paginationColor: PropTypes.string
};

export default Pagination;
