/* eslint-disable react/prop-types */
/** @jsx jsx */
import { useState } from "react";
import { jsx } from "@emotion/core";

import { ChevronDown, ChevronUp } from "../Icons";

import { styles } from "./styles";

export const getDefaultValue = ({ defaultValue, type, flowType }) => {
  const propType = flowType ? flowType : type;
  if (!defaultValue || !defaultValue.value) return null;
  if (defaultValue.value === "''") {
    return "[Empty string]";
  }
  if (propType && propType.name === "string") {
    return defaultValue.value;
  }
  if (typeof defaultValue.value === "object" && defaultValue.value.toString) {
    return defaultValue.value.toString();
  }
  return defaultValue.value;
};

export const Prop = ({ propName, prop, getPropType, isToggle }) => {
  const [showing, setShowing] = useState(isToggle || false);
  if (!prop.type && !prop.flowType) return null;

  const toggle = () => setShowing(s => !s);

  const propStyles = styles();

  return (
    <div css={propStyles.line} data-testid="prop">
      <div css={propStyles.content}>
        <div css={propStyles.propName} data-testid="prop-name">
          {propName}
        </div>
        <div css={propStyles.propType} data-testid="prop-type">
          {getPropType(prop)}
        </div>
        {prop.defaultValue && (
          <div css={propStyles.defaultValue} data-testid="prop-default-value">
            <em>{getDefaultValue(prop)}</em>
          </div>
        )}
        <div css={propStyles.right}>
          {prop.required && (
            <div css={propStyles.propRequired} data-testid="prop-required">
              <strong>required</strong>
            </div>
          )}
          {prop.description && (
            <button
              css={propStyles.openDescBtn}
              onClick={toggle}
              data-testid="prop-toggle-description"
            >
              {showing ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          )}
        </div>
      </div>
      {showing && prop.description && (
        <div css={propStyles.description} data-testid="prop-description">
          {prop.description}
        </div>
      )}
    </div>
  );
};

export const Props = ({ props, getPropType, isToggle }) => {
  const entries = Object.entries(props);

  return (
    <div css={styles().container} data-testid="props">
      {entries.map(([key, prop]) => (
        <Prop
          key={key}
          propName={key}
          prop={prop}
          getPropType={getPropType}
          isToggle={isToggle}
        />
      ))}
    </div>
  );
};
