import { Playground, Props } from "docz";
import { StackSpacer, InlineSpacer } from "../../../../../src/components/Spacer/index";
import { View } from "react-native";
import Text from "../../../../../src/components/Text";
import Avatar from "../../../../../src/components/Avatar";
import * as React from 'react';
export default {
  Playground,
  Props,
  StackSpacer,
  InlineSpacer,
  View,
  Text,
  Avatar,
  React
};