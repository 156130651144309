import React from "react";
import PropTypes from "prop-types";

import FieldValidator from "../Form/components/fieldValidator";

// wrap up the input in a field validator for easy consumption
const HiddenInput = ({ defaultValue, form, name }) => {
  return (
    <FieldValidator
      defaultValue={defaultValue}
      form={form}
      name={name}
      onChange={() => {}}
    >
      {() => {
        return null;
      }}
    </FieldValidator>
  );
};

HiddenInput.propTypes = {
  /** Treat this as a value prop */
  defaultValue: PropTypes.string,
  /** Connect the input to the form */
  form: PropTypes.shape({
    addError: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }).isRequired,
  /** Name of the value in the form object */
  name: PropTypes.string.isRequired
};

HiddenInput.displayName = "HiddenInput";

HiddenInput.defaultProps = {
  onChange: value => value,
  validationMethods: []
};

export default HiddenInput;
