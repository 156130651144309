import React from "react";
import PropTypes from "prop-types";

import { SafeAreaView } from "../SafeArea";
import { colors } from "../../core";

const Screen = ({
  background,
  style,
  children,
  hasInsetBottom,
  hasInsetTop,
  ...props
}) => (
  <SafeAreaView
    forceInset={{
      bottom: getInsetRule(hasInsetBottom),
      top: getInsetRule(hasInsetTop)
    }}
    style={{
      flex: 1,
      backgroundColor: colors.background[background],
      ...style
    }}
    {...props}
  >
    {children}
  </SafeAreaView>
);

Screen.propTypes = {
  /** Background color for the whole screen. Choose from any of the 'background' color tokens. */
  background: PropTypes.oneOf(["default", "sunken"]),
  /** What should be rendered inside the screen */
  children: PropTypes.node.isRequired,
  /** Determines if there should be empty space at the top of the wrapper (for the device home indicator) */
  hasInsetBottom: PropTypes.bool,
  /** Determines if there should be empty space at the top of the wrapper (for the device status bar) */
  hasInsetTop: PropTypes.bool,
  /** Any styles to apply to the wrapper */
  style: PropTypes.object
};

Screen.defaultProps = {
  background: "default",
  hasInsetBottom: false,
  hasInsetTop: true
};

export default Screen;

const getInsetRule = rule => (rule ? "always" : "never");
