import React from "react";
import {
  bool,
  func,
  object,
  shape as objectShape,
  string,
  number
} from "prop-types";
import { View } from "react-native";
import { CalendarList } from "react-native-calendars";
import { format } from "date-fns";

import Sheet from "../../Sheet";
import { colors } from "../../../core";

import {
  PickerInputField,
  DaysRow,
  CalendarHeader,
  CalendarFooter
} from "./common";
import calendarTheme from "./calendarTheme";
import { generateMarkedDays } from "./utils";

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super();

    const { value: { start: valueStart, end: valueEnd } = {} } = props;

    this.state = {
      isVisible: false,
      isStartDatePicked: false,
      isToDatePicked: false,
      markedDates: generateMarkedDays(valueStart, valueEnd),
      startDate: valueStart || null,
      endDate: valueEnd || null
    };
  }

  componentDidMount() {
    this.setupInitialRange();
  }

  render() {
    const {
      futureScrollRange,
      isDisabled,
      label,
      maxDate,
      minDate,
      onChange,
      pastScrollRange,
      placeholder,
      style,
      title,
      testID,
      value: { start: valueStart, end: valueEnd } = {}
    } = this.props;

    const { startDate, endDate, isVisible } = this.state;
    let selectedValue;
    if (startDate && !endDate) {
      selectedValue = format(new Date(startDate), "dd MMM");
    } else if (startDate && endDate) {
      selectedValue = `${format(new Date(startDate), "dd MMM")} - ${format(
        new Date(endDate),
        "dd MMM"
      )}`;
    } else {
      selectedValue = "Select pay period";
    }

    // Because timezones are hard
    let exactStartDate;
    let exactEndDate;

    if (valueStart) {
      const [year, month, day] = valueStart.split(new RegExp("[-T]"));
      exactStartDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
    }

    if (valueEnd) {
      const [year, month, day] = valueEnd.split(new RegExp("[-T]"));
      exactEndDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
    }

    return (
      <>
        <PickerInputField
          isDisabled={isDisabled}
          label={label}
          onPress={() => this.setState({ isVisible: true })}
          hasValue={valueStart && valueEnd ? true : false}
          value={
            valueStart && valueEnd
              ? `${format(exactStartDate, "dd MMM")} - ${format(
                  exactEndDate,
                  "d MMM yyyy"
                )}`
              : placeholder
          }
          style={style}
          testID={testID}
        />

        <Sheet
          visible={isVisible}
          onHide={() => this.setState({ isVisible: false })}
          testID="dateRangePickerSheet"
        >
          <CalendarHeader
            title={title || label}
            clearable={startDate || endDate ? true : false}
            handleClear={this.handleClear}
          />

          <DaysRow />

          <View
            style={{ flex: 1, overflow: "hidden" }}
            testID="dateRangePickerCalenderList"
          >
            <CalendarList
              {...this.props}
              minDate={minDate}
              maxDate={maxDate}
              futureScrollRange={futureScrollRange}
              pastScrollRange={pastScrollRange}
              markingType={"period"}
              current={this.state.startDate}
              markedDates={this.state.markedDates}
              hideDayNames={true}
              theme={calendarTheme}
              firstDay={1} // sets to Monday
              onDayPress={day => {
                this.onDayPress(day);
              }}
            />
          </View>

          <CalendarFooter
            title="Selected period"
            hasValue={startDate || endDate ? true : false}
            selectedValue={selectedValue}
            onDone={() => {
              onChange({ start: startDate, end: endDate });
              this.setState({ isVisible: false });
            }}
            testID="dateRangePickerDone"
          />
        </Sheet>
      </>
    );
  }

  // whenever a day is pressed
  onDayPress = day => {
    if (
      !this.state.isStartDatePicked ||
      (this.state.isStartDatePicked && this.state.isToDatePicked)
    ) {
      this.setupStartMarker(day);
    } else if (!this.state.isToDatePicked) {
      const markedDates = generateMarkedDays(
        this.state.startDate,
        day.dateString
      );
      if (
        day.dateString > this.state.startDate &&
        Object.keys(markedDates).length >= 0
      ) {
        this.setState({
          isStartDatePicked: true,
          isToDatePicked: true,
          markedDates,
          endDate: day.dateString
        });
      } else {
        this.setupStartMarker(day);
      }
    }
  };

  // create the first marker
  setupStartMarker = day => {
    let markedDates = {
      [day.dateString]: {
        startingDay: true,
        color: colors.selected.dark,
        textColor: colors.selected.light
      }
    };
    this.setState({
      isStartDatePicked: true,
      isToDatePicked: false,
      startDate: day.dateString,
      endDate: null,
      markedDates: markedDates
    });
  };

  setupInitialRange = () => {
    if (!this.props.value) return;
    const { start, end } = this.props.value;
    const markedDates = generateMarkedDays(start, end);
    this.setState({ markedDates, startDate: start });
  };

  handleClear = () => {
    this.setState({
      markedDates: {},
      startDate: undefined,
      endDate: undefined,
      isStartDatePicked: false,
      isToDatePicked: false
    });
  };
}

DateRangePicker.propTypes = {
  futureScrollRange: number,
  /** decides if the input is disabled to the user */
  isDisabled: bool,
  label: string,
  /** Minimum date that can be selected in "yyyy-mm-dd" format, dates before minDate will be grayed out.*/
  minDate: string,
  /** Maximum date that can be selected in "yyyy-mm-dd" format, dates after maxDate will be grayed out.*/
  maxDate: string,
  /** A function that expects an object with start and end keys with "YYYY-MM-DD" format as an argument ({ start, end }) */
  onChange: func.isRequired,
  /** Number of months the user should be able to scroll forward or backwards */
  pastScrollRange: number,
  placeholder: string,
  style: object,
  testID: string,
  /** The text printed at the top of the opened modal. Defaults to the 'label' prop if not set. */
  title: string,
  /** Object {start: "yyyy-mm-dd", end: "yyyy-mm-dd" }. ISO strings will be trimmed */
  value: objectShape({
    end: string,
    start: string
  })
};

DateRangePicker.defaultProps = {
  futureScrollRange: undefined,
  minDate: undefined,
  maxDate: undefined,
  pastScrollRange: undefined,
  placeholder: "Choose a range of dates"
};
