import { Dimensions, StyleSheet, View } from "react-native";
import React, { Component } from "react";
import { bool, func, node, number, string } from "prop-types";
import Modal from "react-native-modal";

import { colors, radii } from "../../core";
import { StackSpacer } from "../Spacer";

class Sheet extends Component {
  handleOnScroll = event => {
    this.setState({
      scrollOffset: event.nativeEvent.contentOffset.y
    });
  };

  render() {
    const {
      visible,
      height,
      avoidKeyboard,
      autoHeight,
      onHide,
      onModalHide,
      children,
      testID
    } = this.props;

    const windowWidth = Dimensions.get("window").width;
    const windowHeight = Dimensions.get("window").height;

    const ss = StyleSheet.create({
      modal: {
        justifyContent: "flex-end",
        margin: 0
      },
      sheet: {
        borderTopLeftRadius: radii.large,
        borderTopRightRadius: radii.large,
        maxHeight: windowHeight * (windowWidth > 320 ? height : height + 0.1),
        backgroundColor: colors.background.elevated
      }
    });

    const modalheight = autoHeight ? { height: "auto" } : { flex: 1 };

    return (
      <Modal
        propagateSwipe
        isVisible={visible}
        avoidKeyboard={avoidKeyboard}
        onBackdropPress={onHide}
        onBackButtonPress={onHide}
        onRequestClose={onHide}
        onModalHide={onModalHide}
        style={ss.modal}
        testID={testID}
      >
        <View style={[ss.sheet, modalheight]}>
          {children}
          <StackSpacer size="small" />
        </View>
      </Modal>
    );
  }
}

Sheet.propTypes = {
  autoHeight: bool,
  avoidKeyboard: bool,
  children: node,
  height: number,
  /** A function that hides the modal when user taps the background, or use the Android back button (i.e cancels the modal).*/
  onHide: func,
  /** specifies a callback to fire after the slide-down/dismiss animation is completed */
  onModalHide: func,
  testID: string,
  visible: bool
};

Sheet.defaultProps = {
  visible: false,
  avoidKeyboard: false,
  height: 0.8
};

export default Sheet;
