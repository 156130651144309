/* eslint-disable react/prop-types */
// TODO: prop-types
import React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  arrayOf,
  bool,
  func,
  shape as objectShape,
  oneOfType,
  string
} from "prop-types";

import { Icon } from "../../../icons";
import { colors, spacing, radii } from "../../../core";
import Footer from "../../Footer";
import Button from "../../Button";
import Sheet from "../../Sheet";
import { StackSpacer } from "../../Spacer";
import Text from "../../Text";
import Header from "../../Header";

const Select = ({
  isDisabled,
  options: originalOptions,
  onChange,
  testID,
  value,
  ...props
}) => {
  const [isVisible, setVisible] = React.useState(false);

  // enables support of a simple array of strings as options
  // by converting the strings, into objects
  const options = originalOptions.map(option => {
    if (typeof option === "string") {
      return {
        label: option,
        value: option
      };
    } else return option;
  });

  const currentOption = options.filter(opt => opt.value === value)[0] || {};

  return (
    <>
      <Input
        value={currentOption.label}
        testID={testID}
        {...props}
        isDisabled={isDisabled}
        onPress={() => setVisible(true)}
      />

      <Sheet
        visible={isVisible}
        autoHeight
        testID="selectSheet"
        onHide={() => setVisible(false)}
      >
        <Header title={props.label} />
        <FlatList
          extraData={value}
          data={options}
          contentContainerStyle={{
            paddingVertical: spacing.none,
            paddingHorizontal: spacing.large
          }}
          scrollEnabled
          keyExtractor={item => item.value}
          renderItem={({ index, item }) => (
            <ListItem
              key={index}
              onPress={() => onChange(item.value)}
              item={item}
              selected={value === item.value}
              testID={testID + "_" + index}
            />
          )}
        />
        <Footer>
          <Button
            primaryButtonType="link"
            onPress={() => setVisible(false)}
            label="Done"
          />
        </Footer>
      </Sheet>
    </>
  );
};

const ListItem = ({ item, selected, onPress, testID }) => {
  return (
    <TouchableOpacity
      onPress={() => onPress(item.value)}
      testID={testID}
      style={{
        padding: spacing.small,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: colors.separator,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Text token="3.5x-500" style={{ padding: spacing.small }}>
        {item.label}
      </Text>
      <StackSpacer size="large" />
      {selected && (
        <Icon
          icon="tickEnclosed"
          color={colors.notification.success.dark}
          size={24}
        />
      )}
    </TouchableOpacity>
  );
};

const Input = ({
  error,
  halfWidth,
  hintLabel,
  isDisabled,
  label,
  onHintPress = () => {},
  onPress,
  testID,
  value
}) => {
  return (
    <View
      style={{
        width: halfWidth ? "50%" : "100%"
      }}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text token="3.5x-500" style={{ color: colors.text.neutral }}>
          {label}
        </Text>
        {hintLabel && (
          <Text
            token="3.5x-500"
            style={{ color: colors.action.dark }}
            onPress={() => onHintPress()}
          >
            {hintLabel}
          </Text>
        )}
      </View>

      <StackSpacer size="xsmall" />
      <TouchableOpacity
        disabled={isDisabled}
        style={ss.input}
        testID={testID}
        onPress={onPress}
      >
        <Text
          token="4x-500"
          style={{
            color: isDisabled ? colors.text.muted : colors.text.default
          }}
        >
          {value ? value : " "}
        </Text>
        <Icon icon="chevronDown" size={12} color={colors.inputField.support} />
      </TouchableOpacity>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </View>
  );
};

Select.propTypes = {
  /** Pass error message in here on validation error */
  error: string,
  /** Resizes the component to 50% of screen width */
  halfWidth: bool,
  /** Defines if the Select should have a 'hint', and what the label should be. */
  hintLabel: string,
  /** Decides if the input is disabled to the user */
  isDisabled: bool,
  /** The label on the input, and title at the top of the slide-up sheet */
  label: string.isRequired,
  /** Returns option.value. Envoke a state/content change here. */
  onChange: func.isRequired,
  /** What function should run when user taps the 'hint' */
  onHintPress: func,
  /** An array that will be used to generate options in the select list */
  options: arrayOf(
    oneOfType([
      objectShape({
        label: string.isRequired,
        value: string.isRequired,
        key: string,
        color: string
      }),
      string
    ])
  ).isRequired,
  /** A string that will be printed when no option is selected */
  placeholder: string,
  /** Specifies an ID for the Select button, for Detox testing purposes */
  testID: string,
  /** Current value of the Select. Pass in value from state or context. */
  value: string
};

Select.defaultProps = {
  testID: "Select"
};

const ErrorMessage = props => (
  <Text
    style={{
      color: colors.alert.danger.dark,
      marginTop: spacing.xsmall
    }}
    {...props}
  />
);

const ss = StyleSheet.create({
  input: {
    padding: spacing.medium,
    borderRadius: radii.small,
    backgroundColor: colors.inputField.idle.light,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1
  }
});

export default Select;
