import React from "react";
import { View } from "react-native";
import { default as Svg, Path } from "react-native-svg";
import { oneOf, oneOfType, number, string, object } from "prop-types";

import { colors, radii } from "../core";

import ICONS from "./icons";

const sizes = {
  xxsmall: 8,
  xsmall: 12,
  small: 16,
  medium: 24,
  large: 32
};

const Icon = ({ color, background, icon, size, style, type }) => {
  const resolvedSize = typeof size === "number" ? size : sizes[size];
  const iconSize = type === "default" ? resolvedSize : resolvedSize / 2;

  const defaultBadgeStyle = {
    alignItems: "center",
    height: resolvedSize,
    justifyContent: "center",
    width: resolvedSize
  };

  const badgeTypes = {
    default: { ...defaultBadgeStyle },
    circle: {
      ...defaultBadgeStyle,
      backgroundColor: background,
      borderRadius: radii.full
    },
    square: {
      ...defaultBadgeStyle,
      backgroundColor: background,
      borderRadius: radii.small
    }
  };

  return (
    <View style={{ ...badgeTypes[type], ...style }}>
      <Svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 32 32"
        fillRule="evenodd"
        style={{
          alignContent: "center",
          justifyContent: "center"
        }}
      >
        <Path d={ICONS[icon]} fill={color} />
      </Svg>
    </View>
  );
};

Icon.propTypes = {
  /** Background */
  background: string,
  /** The name of the icon design */
  icon: oneOf(Object.keys(ICONS)).isRequired,
  /** Applied as width and height. Tokens include xxsmall, xsmall, small, medium, large */
  size: oneOfType([oneOf(Object.keys(sizes)), number]),
  /** Fill/stroke color of the icon */
  color: string,
  /** The well-known style prop */
  style: object,
  /** The style of framing of the icon. */
  type: oneOf(["default", "circle", "square"])
};

Icon.defaultProps = {
  color: colors.idle.dark,
  size: "large",
  type: "default"
};

export { ICONS, Icon };
