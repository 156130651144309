import React from "react";
import { string } from "prop-types";

import allColors from "./index";

const ColorsDemo = () => {
  return Object.keys(allColors).map(pack => (
    <div key={pack}>
      <h2>{pack}</h2>
      <div style={{ display: "flex" }}>
        {Object.keys(allColors[pack]).map(colorName => {
          if (typeof allColors[pack][colorName] === "string") {
            return (
              <ColorSquare
                color={allColors[pack][colorName]}
                name={colorName}
                key={colorName}
              />
            );
          }

          if (typeof allColors[pack][colorName] === "object") {
            return (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h3>{colorName}</h3>
                  {Object.keys(allColors[pack][colorName]).map(i => (
                    <ColorSquare
                      color={allColors[pack][colorName][i]}
                      name={i}
                      key={i}
                    />
                  ))}
                </div>
              </>
            );
          }
        })}
      </div>
    </div>
  ));
};

const ColorSquare = ({ color, name }) => (
  <div>
    <div
      style={{
        width: 72,
        height: 72,
        backgroundColor: color
      }}
    />
    <span>{name}</span>
  </div>
);

ColorSquare.propTypes = {
  color: string.isRequired,
  name: string.isRequired
};

export default ColorsDemo;
