import PropTypes from "prop-types";

// This prop type is used in many places so it's defined here as a convenience
export const StylePropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.arrayOf(PropTypes.object)
]);

// Pretty formatting of currency.
// WARNING: This function rounds numbers if the input has more decimal places
// than the set length.
export const formatAsCurrency = (amount, options = {}) => {
  const number = typeof amount === "number" ? amount : parseFloat(amount);

  if (isNaN(number)) {
    return "";
  }

  const formatter = new Intl.NumberFormat("en-AU", {
    // style: "currency",
    // currency: "AUD",
    // currencyDisplay: "symbol",
    // Why not use these? Because doing so change the output of
    // the function when our pipelines run in US locale, which
    // causes tests to fail in Node 10.
    minimumFractionDigits: 2,
    maximumFractionDigits: options.maximumDecimalPlaces || 2,
    ...options
  });

  return "$" + formatter.format(amount);
};

// Single/Plural label helper
export function pluralLabel(num, single, plural) {
  return num === 1 ? `${num} ${single}` : `${num} ${plural}`;
}

export const dateStringToISODateString = dateString => {
  if (!dateString) {
    return "";
  }
  // in dd/MM/yyyy
  // out yyyy-MM-dd
  return dateString
    .split("/")
    .reverse()
    .join("-");
};

export const ISODateStringToDateString = dateString => {
  if (!dateString) {
    return "";
  }
  // in yyyy-MM-dd
  // out dd/MM/yyyy
  return dateString
    .split("-")
    .reverse()
    .join("/");
};

export const truncateString = (string, maxLength = 20) => {
  if (!string) {
    return "-";
  }

  return string && string.length > maxLength
    ? `${string.substring(0, maxLength - 3)}...`
    : string;
};

export const generateSummary = data =>
  // data = [{ gross: 2, net: 1 }, { gross: 2, net: 1 }];
  // summaryData = { gross: 4, net: 2 };
  data.reduce((summary, employee) => {
    Object.keys(employee).forEach(key => {
      if (key in summary) {
        summary[key] += employee[key];
      } else {
        summary[key] = employee[key];
      }
    });

    return summary;
  }, {});
