import { Playground, Props } from "docz";
import { View } from "react-native";
import Button from "../../../../../src/components/Button/index";
import { StackSpacer } from "../../../../../src/components/Spacer";
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  Button,
  StackSpacer,
  React
};