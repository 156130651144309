import { Playground, Props } from "docz";
import { FlatList } from "react-native";
import EmployeeListItem from "../../../../../src/components/EmployeeListItem/index";
import { colors } from "../../../../../src/core";
import Card from "../../../../../src/components/Card";
import Divider from "../../../../../src/components/Divider";
import { StackSpacer } from "../../../../../src/components/Spacer";
import * as React from 'react';
export default {
  Playground,
  Props,
  FlatList,
  EmployeeListItem,
  colors,
  Card,
  Divider,
  StackSpacer,
  React
};