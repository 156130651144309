import { Playground, Props } from "docz";
import Select from "../../../../../src/components/Select/src/select";
import SelectWithValidation from "../../../../../src/components/Select/src/selectWithValidation";
import * as React from 'react';
export default {
  Playground,
  Props,
  Select,
  SelectWithValidation,
  React
};