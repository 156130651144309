import React from "react";
import { View, StyleSheet } from "react-native";

import { colors } from "../../core";

const Divider = () => <View style={ss.base} />;

export default Divider;

// Styles
const ss = StyleSheet.create({
  base: {
    width: "100%",
    height: StyleSheet.hairlineWidth * 2,
    backgroundColor: colors.separator,
    flexGrow: 1
  }
});
