import React from "react";
import { View } from "react-native";
import { oneOf } from "prop-types";

import { InlineSpacer } from "../../Spacer";
import { colors, spacing, radii } from "../../../core";

const StatusDot = ({ status }) => (
  <>
    <InlineSpacer />
    <View
      style={{
        borderRadius: radii.full,
        width: spacing.small,
        height: spacing.small,
        marginBottom: spacing.small,
        backgroundColor: colors.alert[status].dark
      }}
    />
  </>
);

StatusDot.propTypes = {
  status: oneOf(["success", "danger"])
};

export default StatusDot;
