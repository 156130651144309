import React from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";

const Loader = () => (
  <View style={ss.wrapper}>
    <ActivityIndicator size="large" />
  </View>
);

// Styles
const ss = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default Loader;
