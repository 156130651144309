import { useTheme } from "emotion-theming";

export const editor = () => {
  const { colors, fontFamily, leading } = useTheme();

  return {
    lineHeight: leading.base,
    background: colors.backgroundMuted,
    borderTop: 0,
    fontFamily: fontFamily.monospace,
    fontSize: 16,
    "* > textarea:focus": {
      outline: "none"
    }
  };
};

export const error = ({ spacing, fontSize }) => ({
  margin: 0,
  padding: spacing.small,
  backgroundColor: "#FF4757",
  fontSize: fontSize.small,
  color: "white",
  whiteSpace: "pre-wrap"
});

export const wrapper = ({ colors }) => ({
  height: "auto",
  display: "block",
  minHeight: "100%",
  width: "calc(100% - 2px)",
  backgroundColor: colors.backgroundMuted
});

export const wrapperBorder = (content, showingCode, colors) => {
  let borderRadius = 4;
  if (showingCode) {
    borderRadius = content === "preview" ? "4px 4px 0 0" : "0 0 4px 4px";
  }

  return {
    border: `1px solid ${colors.border}`,
    borderTop: content === "editor" ? 0 : undefined,
    borderRadius
  };
};

export const preview = {
  margin: 0,
  padding: "20px"
};

export const buttons = {
  zIndex: 9,
  display: "flex",
  position: "absolute",
  bottom: -20,
  right: 4
};

export const button = ({ colors, radii, spacing }) => ({
  outline: "none",
  background: "transparent",
  border: "none",
  ":hover": {
    cursor: "pointer"
  },
  display: "flex",
  alignItems: "center",
  padding: spacing.small,
  backgroundColor: colors.interactiveMuted,
  color: colors.interactive,
  borderRadius: radii.small,
  "& ~ &": {
    marginLeft: spacing.xsmall
  }
});

export const link = {
  py: 0,
  ml: 1,
  height: 22
};
