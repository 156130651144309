import React from "react";
import { View } from "react-native";
import { bool, number, string } from "prop-types";

import Text from "../../Text";
import { colors, radii } from "../../../core";

const Digit = ({ value, isFocused, index }) => {
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isFocused
          ? colors.inputField.focus.light
          : colors.inputField.idle.light,
        height: 56,
        margin: 4,
        width: 40,
        borderRadius: radii.small
      }}
    >
      <Text token="title-2">{value ? value[index] : ""}</Text>
    </View>
  );
};

Digit.propTypes = {
  index: number,
  isFocused: bool,
  value: string
};

export default Digit;
