import React, { Children } from "react";
import { View } from "react-native";
import PropTypes from "prop-types";

import { spacing } from "../../core";

// TODO: remove margin: -4
const FlexGroup = ({ children, direction }) => {
  return (
    <View style={{ flexDirection: direction, margin: -spacing.xsmall }}>
      {Children.map(children, child => {
        const flex = direction === "row" ? 1 : null;
        return <View style={{ margin: spacing.xsmall, flex }}>{child}</View>;
      })}
    </View>
  );
};

FlexGroup.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(["row", "column"])
};

FlexGroup.defaultProps = {
  direction: "column"
};

export default FlexGroup;
