import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, Animated } from "react-native";

import { colors } from "../../core";

export default class Skeleton extends React.Component {
  state = {
    fadeAnim: new Animated.Value(1)
  };

  componentDidMount() {
    Animated.sequence([
      Animated.loop(
        Animated.sequence([
          Animated.timing(this.state.fadeAnim, {
            toValue: 0.3,
            duration: 500,
            useNativeDriver: false
          }),
          Animated.timing(this.state.fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: false
          }),
          Animated.delay(300)
        ]),
        {
          iterations: 400
        }
      )
    ]).start();
  }

  render() {
    return (
      <Animated.View
        style={[
          ss.base,
          {
            height: this.props.height,
            width: this.props.width,
            opacity: this.state.fadeAnim
          },
          this.props.style
        ]}
      />
    );
  }
}

Skeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Skeleton.defaultProps = {
  height: 8,
  width: 8
};

// Styles
const ss = StyleSheet.create({
  base: {
    height: 8,
    width: 8,
    backgroundColor: colors.progress.pending.dark,
    marginBottom: 14,
    borderRadius: 6
  }
});
