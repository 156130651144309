import { spacing, radii } from "../../core";

export const getMarginStyles = ({
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY
}) => ({
  marginTop: spacing[marginTop || marginY || margin || "none"],
  marginBottom: spacing[marginBottom || marginY || margin || "none"],
  marginLeft: spacing[marginLeft || marginX || margin || "none"],
  marginRight: spacing[marginRight || marginX || margin || "none"]
});

export const getPaddingStyles = ({
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY
}) => ({
  paddingTop: spacing[paddingTop || paddingY || padding || "none"],
  paddingBottom: spacing[paddingBottom || paddingY || padding || "none"],
  paddingLeft: spacing[paddingLeft || paddingX || padding || "none"],
  paddingRight: spacing[paddingRight || paddingX || padding || "none"]
});

export const getRadiiStyles = ({
  rounding,
  roundingBottom,
  roundingLeft,
  roundingRight,
  roundingTop
}) => ({
  borderTopLeftRadius: radii[roundingTop || roundingLeft || rounding || "none"],
  borderTopRightRadius:
    radii[roundingTop || roundingRight || rounding || "none"],
  borderBottomLeftRadius:
    radii[roundingBottom || roundingLeft || rounding || "none"],
  borderBottomRightRadius:
    radii[roundingBottom || roundingRight || rounding || "none"]
});

export const getFlexStyles = ({ flex, basis, grow, shrink }) => {
  if (flex) {
    return {
      flex: flex
    };
  } else {
    return {
      flexBasis: basis,
      flexGrow: grow,
      flexShrink: shrink
    };
  }
};

export const orientationMap = {
  horizontal: "row",
  vertical: "column"
};

export const alignmentMap = {
  center: "center",
  end: "flex-end",
  start: "flex-start",
  stretch: "stretch"
};

export const justificationMap = {
  around: "space-around",
  between: "space-between",
  center: "center",
  end: "flex-end",
  even: "space-evenly",
  start: "flex-start",
  stretch: "stretch"
};
