import prism from "./prism";

// Copied from Reckon Balance Design System
const themeColors = {
  neutral: {
    white: "#FFFFFF",
    100: "#F8F9F9",
    200: "#F2F2F3",
    300: "#F0F0F1",
    400: "#E8E8E8",
    500: "#DDDDDD",
    600: "#AEAFAF",
    700: "#6B6C6C",
    800: "#121212"
  },
  blue: {
    100: "#E3F4FF",
    200: "#BBE2FF",
    300: "#1DADFF",
    400: "#007AFF",
    500: "#1269EC",
    600: "#1F47CD",
    700: "#002D9C"
  },
  cyan: {
    700: "#004887",
    600: "#007BC7",
    500: "#00B2FF",
    400: "#5ACEFF",
    300: "#9BE2FF",
    200: "#BDE7FF",
    100: "#CDEDFF"
  },
  violet: {
    100: "#E9EAF6",
    200: "#C9CAE9",
    300: "#696ABF",
    400: "#4C47A9",
    500: "#433D9D",
    600: "#3C3391",
    700: "#2F207C"
  },
  red: {
    100: "#ffeaed",
    200: "#ffcbce",
    300: "#ff443c",
    400: "#E30613",
    500: "#d6000b",
    600: "#c90000",
    700: "#C20303"
  }
};

const colors = {
  // Copied from Reckon Balance Design System
  background: "white",
  backgroundMuted: themeColors.neutral[100],
  backgroundDim: themeColors.neutral[200],
  border: themeColors.neutral[400],
  borderCritical: themeColors.red[400],
  borderFocus: themeColors.blue[400],
  focusRing: themeColors.blue[200],
  foreground: themeColors.neutral[800],
  foregroundMuted: themeColors.neutral[700],
  foregroundDim: themeColors.neutral[600],
  foregroundHighlight: themeColors.violet[600],
  inputBackground: themeColors.neutral[200],
  inputDisabledBackground: themeColors.neutral[400],
  inputInvalidBackground: themeColors.red[100],
  linkColor: themeColors.blue[400],
  linkHoverColor: themeColors.blue[500],
  overlayBackground: "rgba(18,18,18, 0.3)", // blanket behind modal dialogs
  loaderLight: themeColors.neutral[100],
  loaderDark: themeColors.blue[400],

  // custom
  interactive: themeColors.blue["400"],
  interactiveMuted: themeColors.blue["200"],
  codeForeground: themeColors.violet["400"],
  codeBackground: themeColors.violet["100"],
  informativeBackground: themeColors.cyan["100"],
  informativeBorder: themeColors.cyan["300"]
};

export const themeConfig = {
  // Show errors above playground editor
  showLiveError: true,
  // Show preview of the code inside playground
  showLivePreview: true,
  // Show editor when a playground is rendered
  showPlaygroundEditor: true,
  // Wrap the playground editor and preview in iframes to avoid style/script collisions
  useScopingInPlayground: false,
  colors,
  // from Reckon Web DS
  fontFamily: {
    monospace:
      'Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    body:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif'
  },
  fontSize: {
    xsmall: "0.75rem",
    small: "0.875rem",
    medium: "1rem",
    large: "1.125rem",
    xlarge: "1.25rem",
    xxlarge: "1.5rem",
    xxxlarge: "1.875rem",
    xxxxlarge: "2.25rem",
    xxxxxlarge: "3rem",
    xxxxxxlarge: "4rem"
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800
  },
  leading: {
    tighter: 1,
    tight: 1.2,
    base: 1.4,
    loose: 1.6,
    looser: 1.8
  },
  tracking: {
    tighter: "-0.02em",
    tight: "-0.01em",
    base: "0em",
    loose: "0.01em",
    looser: "0.02em"
  },

  radii: {
    none: 0,
    xsmall: 4,
    small: 6,
    medium: 8,
    large: 12,
    full: 9999
  },
  spacing: {
    none: 0,
    xsmall: 4,
    small: 8,
    medium: 12,
    large: 16,
    xlarge: 24,
    xxlarge: 32
  },
  sizing: {
    xsmall: 24,
    small: 32,
    base: 40,
    medium: 48,
    large: 56,
    xlarge: 72
  },
  prism
};
