import React from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { bool, func, string, object, oneOf } from "prop-types";

import { sizing, spacing, colors, radii } from "../../core";
import { Icon } from "../../icons";
import { InlineSpacer, StackSpacer } from "../Spacer";
import Text from "../Text";

const LinkRow = ({
  body,
  isFirst,
  headline,
  hasIndicator,
  indicatorStatus,
  isDanger,
  onPress,
  icon,
  isLast,
  ...props
}) => {
  const baseStyle = {
    marginHorizontal: -spacing.large,
    paddingHorizontal: spacing.large,
    backgroundColor: colors.background.elevated,
    ...(isFirst
      ? {
          borderTopLeftRadius: radii.medium,
          borderTopRightRadius: radii.medium
        }
      : {}),
    ...(isLast
      ? {
          borderBottomLeftRadius: radii.medium,
          borderBottomRightRadius: radii.medium
        }
      : {})
  };

  const internalContainer = {
    alignItems: "center",
    borderBottomColor: colors.separator,
    borderBottomWidth: isLast ? 0 : StyleSheet.hairlineWidth,
    flexDirection: "row",
    paddingVertical: spacing.medium,
    justifyContent: "space-between"
  };

  return (
    <TouchableOpacity style={[baseStyle, props.style]} onPress={onPress}>
      <View style={internalContainer}>
        <View style={{ flex: 1 }}>
          {headline && (
            <Text
              token="3.5x-500"
              style={{
                color: isDanger
                  ? colors.actionDestructive.dark
                  : colors.text.default
              }}
            >
              {headline}
            </Text>
          )}
          <StackSpacer size="xsmall" />
          {body && (
            <Text
              token="3x-400"
              style={{
                color: colors.text.neutral
              }}
            >
              {body}
            </Text>
          )}
        </View>
        <View style={ss.actionContainer}>
          <InlineSpacer />

          {hasIndicator &&
            (indicatorStatus ? <SuccessIcon /> : <WarningIcon />)}

          <InlineSpacer size="large" />
          <Icon icon={icon} size={sizing["2x"]} color={colors.action.dark} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

LinkRow.propTypes = {
  body: string,
  headline: string,
  icon: oneOf(["chevron", "open"]),
  hasIndicator: bool,
  /** Indicates if the action is destructive */
  isDanger: bool,
  isFirst: bool,
  isLast: bool,
  onPress: func,
  indicatorStatus: bool,
  style: object
};

LinkRow.defaultProps = {
  icon: "chevron"
};

const WarningIcon = () => (
  <View style={[ss.iconBase, { backgroundColor: colors.alert.danger.dark }]}>
    <Text style={{ color: "white" }}>!</Text>
  </View>
);

const SuccessIcon = () => (
  <View style={[ss.iconBase, { backgroundColor: colors.alert.success.dark }]}>
    <Icon icon="tick" color={colors.alert.success.light} size={sizing["1x"]} />
  </View>
);

export default LinkRow;

// styles
const ss = StyleSheet.create({
  actionContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  iconBase: {
    borderRadius: radii.full,
    width: sizing["2x"],
    height: sizing["2x"],
    padding: 2,
    alignItems: "center",
    justifyContent: "center"
  }
});
