/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { element } from "prop-types";

export const MainContainer = ({ children, ...props }) => {
  const { colors, spacing } = useTheme();

  return (
    <main
      css={{
        backgroundColor: colors.background,
        boxSizing: "border-box",
        position: "relative",
        maxWidth: 960,
        paddingLeft: spacing.xlarge,
        paddingRight: spacing.xlarge,
        paddingTop: spacing.medium,
        paddingBottom: spacing.medium
      }}
      {...props}
    >
      {children}
    </main>
  );
};

MainContainer.propTypes = {
  children: element
};
