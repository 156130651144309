import React from "react";
import { View, Switch, StyleSheet } from "react-native";
import { bool, func, string, element, object, oneOfType } from "prop-types";

import { colors, spacing, radii } from "../../../core";
import { InlineSpacer, StackSpacer } from "../../Spacer";
import Text from "../../Text";

const SwitchRow = ({
  body,
  disabled,
  isFirst,
  headline,
  onSwitchChange,
  value,
  isElevated,
  isLast,
  ...props
}) => {
  const baseStyle = {
    borderBottomColor: colors.separator,
    borderBottomWidth: isLast ? 0 : StyleSheet.hairlineWidth,
    ...(isElevated
      ? {
          marginHorizontal: -spacing.large,
          paddingHorizontal: spacing.large,
          backgroundColor: colors.background.elevated
        }
      : {}),
    ...(isFirst
      ? {
          borderTopLeftRadius: radii.medium,
          borderTopRightRadius: radii.medium
        }
      : {}),
    ...(isLast
      ? {
          borderBottomLeftRadius: radii.medium,
          borderBottomRightRadius: radii.medium
        }
      : {})
  };

  const internalContainer = {
    paddingVertical: spacing.medium,
    borderBottomColor: colors.separator,
    borderBottomWidth: isLast ? 0 : StyleSheet.hairlineWidth,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  };

  return (
    <View style={[baseStyle, props.style]}>
      <View style={internalContainer}>
        <View style={{ flex: 1 }}>
          {headline && (
            <Text token="3.5x-500" style={{ color: colors.text.default }}>
              {headline}
            </Text>
          )}
          <StackSpacer size="xsmall" />
          {body && (
            <Text token="3x-400" style={{ color: colors.text.neutral }}>
              {body}
            </Text>
          )}
        </View>
        <View style={ss.actionContainer}>
          <InlineSpacer />

          <Switch
            value={value}
            onValueChange={v => onSwitchChange(v)}
            disabled={disabled || false}
          />
        </View>
      </View>
    </View>
  );
};

SwitchRow.propTypes = {
  body: oneOfType([string, element]),
  disabled: bool,
  headline: string,
  isFirst: bool,
  isLast: bool,
  onSwitchChange: func.isRequired,
  style: object,
  isElevated: bool,
  value: bool
};

export default SwitchRow;

// styles
const ss = StyleSheet.create({
  actionContainer: {
    flexDirection: "row",
    alignItems: "center"
  }
});
