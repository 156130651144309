import React, { Component } from "react";
import {
  findNodeHandle,
  Keyboard,
  NativeModules,
  Platform,
  ScrollView,
  KeyboardAvoidingView
} from "react-native";
import PropTypes from "prop-types";
const { ScrollViewManager } = NativeModules;

const BOTTOM_TABBAR_HEIGHT = Platform.OS === "android" ? 80 : 64;

class KeyboardAwareScrollViewComponent extends Component {
  static propTypes = {
    hasTabBar: PropTypes.bool,
    onScroll: PropTypes.func
  };
  static defaultProps = {
    hasTabBar: false
  };

  state = { keyboardHeight: 0 };

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  addListeners = () => {
    this.keyboardEventListeners = [
      Keyboard.addListener("keyboardWillShow", this.onKeyboardWillShow),
      Keyboard.addListener("keyboardWillHide", this.onKeyboardWillHide)
    ];
  };

  removeListeners = () => {
    this.keyboardEventListeners.forEach(listener => listener.remove());
  };

  onKeyboardWillShow = event => {
    // NOTE: we must offset the distance the view gets pushed up if there's a
    // bottom tab bar present. this would be nice to do programatically, but I
    // can't find anything in the React Navigation docs.
    // https://reactnavigation.org/en/
    let keyboardHeight = event.endCoordinates.height;
    if (this.props.hasTabBar) {
      keyboardHeight -= BOTTOM_TABBAR_HEIGHT;
    }

    if (this.state.keyboardHeight === keyboardHeight) {
      return;
    }

    this.setState({ keyboardHeight });
  };

  onKeyboardWillHide = () => {
    const { keyboardHeight } = this.state;
    this.setState({ keyboardHeight: 0 });

    const hasYOffset =
      this.scrollViewRef &&
      this.scrollViewRef.contentOffset &&
      this.scrollViewRef.contentOffset.y !== undefined;

    const yOffset = hasYOffset
      ? Math.max(this.scrollViewRef.contentOffset.y - keyboardHeight, 0)
      : 0;

    this.scrollViewRef.scrollTo({ x: 0, y: yOffset, animated: true });
  };

  handleLayout = event => {
    const layout = event.nativeEvent.layout;

    this.scrollViewRef.layout = layout;
    this.scrollViewRef.contentOffset = { x: 0, y: 0 };
    this.handleSizeChange();
  };

  handleScroll = event => {
    const contentOffset = event.nativeEvent.contentOffset;
    const { onScroll } = this.props;

    if (onScroll) {
      onScroll(event);
    }
    this.scrollViewRef.contentOffset = contentOffset;
    this.handleSizeChange();
  };

  handleSizeChange = () => {
    if (ScrollViewManager && ScrollViewManager.getContentSize) {
      ScrollViewManager.getContentSize(
        findNodeHandle(this.scrollViewRef),
        res => {
          if (this.scrollViewRef) {
            this.scrollViewRef.contentSize = res;
          }
        }
      );
    }
  };

  setScrollViewRef = r => {
    this.scrollViewRef = r;
  };

  render() {
    const { keyboardHeight } = this.state;

    return (
      <ScrollView
        {...this.props}
        automaticallyAdjustContentInsets={false}
        contentInset={{ bottom: keyboardHeight }}
        onContentSizeChange={this.handleSizeChange}
        onLayout={this.handleLayout}
        onScroll={this.handleScroll}
        ref={this.setScrollViewRef}
        scrollEventThrottle={200}
      />
    );
  }
}

// NOTE: this component is unnecessary for Android, because we use
// `windowSoftInputMode="adjustResize"`, which has a similar behaviour.
// ------------------------------
// The property `windowSoftInputMode="adjustResize"` should be in the manifest,
// but it's somehow tied to `androidStatusBar`, in expo's app.json

const KeyboardAwareScrollView = props =>
  Platform.OS === "android" ? (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior={"height"}>
      <ScrollView {...props} />
    </KeyboardAvoidingView>
  ) : (
    <KeyboardAwareScrollViewComponent {...props} />
  );

export default KeyboardAwareScrollView;
