import React, { forwardRef } from "react";
import { array, arrayOf, bool, func, number, object, string } from "prop-types";

import { FieldValidator } from "../../Form";

import NumberInput from "./numberInput";

const NumberInputWithValidation = forwardRef(
  (
    {
      defaultValue,
      form,
      formatMethods,
      isRequired,
      label,
      name,
      onBlur,
      onFocus,
      prefix,
      suffix,
      validationMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;

    // stub the component's default onChange if it's not defined
    const onChange =
      typeof props.onChange === "function" ? props.onChange : () => {};

    const validationLabel = label.replace("*", "");

    const isNumber = ({ label = "Field", value }) => {
      if (value && isNaN(value)) {
        return `${label} must be a valid number`;
      }

      if (value && typeof value === "string") {
        // We don't want this error to printed to the user.
        // It should only cause the form to be invalid
        // and the form's submit button to be disabled.
        return "FIELD_IS_STILL_FOCUSED";
      }

      return false;
    };

    const formatNumber = value => {
      if (value === undefined) {
        return undefined;
      }
      return parseFloat(value);
    };

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={validationLabel}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        validationMethods={[isNumber, ...(validationMethods || [])]}
        formatMethods={[formatNumber, ...(formatMethods || [])]}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <NumberInput
              errorMessage={errorMessage}
              label={label}
              ref={ref}
              {...props}
              {...validatorProps}
              prefix={prefix}
              suffix={suffix}
              onChange={val => validatorProps.onChange(val, keyValue)}
            />
          );
        }}
      </FieldValidator>
    );
  }
);

NumberInputWithValidation.propTypes = {
  defaultValue: number,
  form: object.isRequired,
  formatMethods: arrayOf(func),
  hintLabel: string,
  isDisabled: bool,
  isRequired: bool,
  label: string.isRequired,
  name: string.isRequired,
  onBlur: func,
  /** A secondary onChange method which will run before the default one */
  onChange: func,
  onFocus: func,
  onHintPress: func,
  /** String that will be placed before the value for presentation */
  placeholder: string,
  prefix: string,
  /** String that will be placed after the value for presentation */
  suffix: string,
  testID: string,
  validationMethods: array
};

NumberInputWithValidation.defaultProps = {
  prefix: "",
  suffix: "",
  placeholder: "0.00"
};

NumberInputWithValidation.displayName = "NumberInputWithValidation";

export default NumberInputWithValidation;
