import React, { forwardRef } from "react";
import { array, bool, string, func, object, oneOf } from "prop-types";

import { FieldValidator } from "../../Form";

import DatePicker from "./DatePicker";

const DatePickerWithValidation = forwardRef(
  (
    {
      defaultValue,
      form,
      isDisabled,
      isRequired,
      label,
      maxDate,
      minDate,
      name,
      validationMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;

    // stub the component's default onChange if it's not defined
    const onChange =
      typeof props.onChange === "function" ? props.onChange : () => {};

    const validationLabel = label.replace("*", "");

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={validationLabel}
        name={name}
        onChange={onChange}
        validationMethods={validationMethods}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <DatePicker
              errorMessage={errorMessage}
              label={label}
              ref={ref}
              isDisabled={isDisabled}
              maxDate={maxDate}
              minDate={minDate}
              {...props}
              {...validatorProps}
              onChange={async value => {
                await validatorProps.onChange(value, keyValue);
                validatorProps.onBlur();
              }}
            />
          );
        }}
      </FieldValidator>
    );
  }
);

DatePickerWithValidation.propTypes = {
  /** Component can return either a dateString ("YYYY-MM-DD"), or an ISO string to zero zulu time ("YYYY-MM-DDT00:00:00.000Z")  */
  format: oneOf(["dateString", "ISO"]),
  defaultValue: string,
  form: object.isRequired,
  isRequired: bool,
  /** decides if the input is disabled to the user */
  isDisabled: bool,
  label: string.isRequired,
  /** Minimum date that can be selected, dates before minDate will be grayed out.*/
  minDate: string,
  /** Maximum date that can be selected, dates after maxDate will be grayed out.*/
  maxDate: string,
  name: string.isRequired,
  onChange: func,
  onKeyPress: func,
  validationMethods: array
};

DatePickerWithValidation.defaultProps = {
  minDate: undefined,
  maxDate: undefined
};

DatePickerWithValidation.displayName = "DatePickerWithValidation";

export default DatePickerWithValidation;
