import { Playground, Props } from "docz";
import SectionTitle from "../../../../../src/components/SectionTitle/index";
import DataRow from "../../../../../src/components/DataRow";
import * as React from 'react';
export default {
  Playground,
  Props,
  SectionTitle,
  DataRow,
  React
};