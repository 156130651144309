import React from "react";
import { array, func, object, oneOfType, string } from "prop-types";
import { Text, TouchableOpacity, StyleSheet } from "react-native";

import { Icon } from "../../icons";
import { colors, text } from "../../core";
import { InlineSpacer } from "../Spacer";

const AddItemButton = ({ onPress, style, label = "Add Item" }) => {
  return (
    <TouchableOpacity style={[ss.base, style]} onPress={onPress}>
      <Icon icon="plusEnclosed" size={24} color={colors.action.dark} />
      <InlineSpacer size="xsmall" />
      <Text style={ss.text}>{label}</Text>
    </TouchableOpacity>
  );
};

const ss = StyleSheet.create({
  text: {
    ...text["4x-500"],
    color: colors.action.dark
  },
  base: {
    flexDirection: "row",
    alignItems: "center"
  }
});

AddItemButton.propTypes = {
  label: string.isRequired,
  onPress: func,
  style: oneOfType([array, object])
};

export default AddItemButton;
