/* eslint-disable react/prop-types */
/** @jsx jsx */
import React, { useEffect, useRef } from "react";
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Link } from "gatsby";
import { useCurrentDoc } from "docz";

import { Search } from "../Icons";

export const NavSearch = props => {
  const { spacing, colors } = useTheme();

  const styles = {
    wrapper: {
      marginBottom: spacing.medium,
      display: "inline-flex",
      alignItems: "center"
    },
    input: {
      outline: "none",
      background: "none",
      border: "none",
      color: colors.foreground
    },
    icon: {
      color: colors.foregroundMuted,
      marginRight: spacing.small
    }
  };

  return (
    <div css={styles.wrapper} data-testid="nav-search">
      <Search size={20} css={styles.icon} />
      <input {...props} css={styles.input} />
    </div>
  );
};

export const NavLink = React.forwardRef(({ item, ...props }, ref) => {
  const current = useCurrentDoc();
  const { spacing, fontSize, fontWeight, colors, radii } = useTheme();
  const isCurrent = item.route === current.route;

  const styles = {
    link: {
      alignItems: "center",
      color: colors[isCurrent ? "foreground " : "foregroundMuted"],
      cursor: "pointer",
      display: "flex",
      fontWeight: fontWeight[isCurrent ? "medium" : "regular"],
      padding: `${spacing.xsmall}px ${spacing.large}px`,
      position: "relative",
      textDecoration: "none",
      fontSize: fontSize.medium,
      "&.active": {
        color: colors.foreground
      },
      "&:hover": {
        color: colors.foreground,
        textDecoration: "none"
      },
      "::after": {
        backgroundColor: colors.foregroundHighlight,
        borderRadius: radii.xsmall,
        bottom: 0,
        content: '" "',
        left: 0,
        position: "absolute",
        right: spacing.medium,
        top: 0,
        visibility: isCurrent ? "visible" : "hidden",
        width: 4
      }
    }
  };

  if (item.hidden) {
    return null;
  }

  return (
    <Link
      {...props}
      to={item.route}
      css={styles.link}
      activeClassName="active"
      ref={ref}
    />
  );
});

export const NavGroup = ({ item, sidebarRef }) => {
  const currentDoc = useCurrentDoc();
  const currentDocRef = useRef();
  const { colors, spacing } = useTheme();

  useEffect(() => {
    if (sidebarRef.current && currentDocRef.current) {
      sidebarRef.current.scrollTo(0, currentDocRef.current.offsetTop);
    }
  }, []);

  const styles = {
    wrapper: {
      marginBottom: spacing.medium,
      marginTop: spacing.large,

      ":first-of-type": { marginTop: 0 }
    },

    sublinkWrapper: {
      boxShadow: `inset 1px 0 0 ${colors.border}`,
      marginLeft: spacing.xsmall
    },

    title: {
      mb: 1,
      fontSize: 2,
      fontWeight: 500,
      color: "sidebar.navGroup",
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },

    chevron: ({ active }) => ({
      ml: 1,
      flexShrink: 0,
      alignSelf: "baseline",
      transform: `rotateX(${active ? 180 : 0}deg)`,
      transformOrigin: "center",
      transition: "transform .3s ease-in-out"
    })
  };
  return (
    <div css={styles.wrapper} data-testid="nav-group">
      <NavGroupTitle>{item.name}</NavGroupTitle>

      <div css={styles.sublinkWrapper} data-testid="nav-group-links">
        {item.menu &&
          item.menu.map(menu => {
            if (currentDoc.route === menu.route) {
              return (
                <NavLink key={menu.id} item={menu} ref={currentDocRef}>
                  {menu.name}
                </NavLink>
              );
            } else {
              return (
                <NavLink key={menu.id} item={menu}>
                  {menu.name}
                </NavLink>
              );
            }
          })}
      </div>
    </div>
  );
};

export const NavSectionTitle = props => {
  const { spacing, colors, fontSize } = useTheme();

  const styles = {
    color: colors.foregroundMuted,
    fontSize: fontSize.small,
    marginBottom: spacing.medium,
    marginTop: spacing.xxlarge,
    textTransform: "uppercase"
  };

  return <h3 css={styles} {...props} />;
};

const NavGroupTitle = props => {
  const { spacing, fontSize, fontWeight, colors } = useTheme();

  return (
    <h4
      css={{
        alignItems: "center",
        background: 0,
        border: 0,
        color: colors.foreground,
        display: "flex",
        fontSize: fontSize.small,
        fontWeight: fontWeight.medium,
        marginBottom: spacing.small,
        marginTop: 0,
        outline: 0,
        padding: 0,
        position: "relative",
        width: "100%"
      }}
      {...props}
    />
  );
};
