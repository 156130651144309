import React from "react";
import { View } from "react-native";
import { bool, string, func } from "prop-types";

import Footer from "../Footer";
import Button from "../Button";
import Sheet from "../Sheet";
import Text from "../Text";
import { Icon } from "../../icons";
import { colors, spacing } from "../../core";
import { StackSpacer } from "../Spacer";

const AndroidBiometricInputModal = ({ title, visible, onHide }) => (
  <Sheet visible={visible} onHide={onHide}>
    <View
      style={{
        paddingVertical: spacing.xlarge,
        paddingHorizontal: spacing.xxlarge,
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 0
      }}
    >
      <Text token="blockTitle-2">{title}</Text>
      <View
        style={{
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Icon icon="fingerprint" size={62} color={colors.action.dark} />
        <StackSpacer size="large" />
        <Text token="4x-500" align="center" color={colors.text.neutral}>
          Touch the fingerprint sensor
        </Text>
      </View>
      <Footer>
        <Button type="link" onPress={onHide} label="Cancel" />
      </Footer>
    </View>
  </Sheet>
);

AndroidBiometricInputModal.propTypes = {
  onHide: func,
  title: string,
  visible: bool
};

AndroidBiometricInputModal.defaultProps = {
  visible: false,
  title: "Confirm fingerprint"
};

export default AndroidBiometricInputModal;
