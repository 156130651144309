/* eslint-disable react/prop-types */
/** @jsx jsx */
import { useState } from "react";
import { jsx } from "@emotion/core";
import { useConfig } from "docz";
import Iframe from "react-frame-component";
import ReactResizeDetector from "react-resize-detector";
import { useTheme } from "emotion-theming";

import * as styles from "./styles";

const CLEAR_PADDING = `<style> body { padding: 0; margin: 0; }  </style>`;
const INITIAL_IFRAME_CONTENT = `<!DOCTYPE html><html><head> ${CLEAR_PADDING} </head><body><div></div></body></html>`;

const IframeWrapper = ({ children, style }) => {
  const { colors } = useTheme();
  const [containerHeight, setHeight] = useState();
  return (
    <Iframe
      initialContent={INITIAL_IFRAME_CONTENT}
      css={{
        ...styles.wrapper({ colors }),
        style,
        height: containerHeight
      }}
    >
      {children}
      <ReactResizeDetector
        handleHeight
        onResize={({ height }) => {
          setHeight(height);
        }}
      />
    </Iframe>
  );
};

const NormalWrapper = ({ children, style }) => {
  const { colors } = useTheme();

  return (
    <div
      css={{
        ...styles.wrapper({ colors }),
        ...style
      }}
    >
      {children}
    </div>
  );
};

export const Wrapper = ({ children, content, useScoping, showingCode }) => {
  const { colors } = useTheme();
  const {
    themeConfig: { useScopingInPlayground }
  } = useConfig();

  const Element =
    useScoping || useScopingInPlayground ? IframeWrapper : NormalWrapper;

  return (
    <Element style={styles.wrapperBorder(content, showingCode, colors)}>
      {children}
    </Element>
  );
};
