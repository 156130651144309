import React from "react";
import { View } from "react-native";
import { string } from "prop-types";

import { colors, spacing } from "../../core";
import Text from "../Text";

const SectionTitle = ({ label }) => (
  <View
    style={{ paddingVertical: spacing.small, paddingHorizontal: spacing.none }}
  >
    <Text
      token="blockTitle-1"
      testID="generalTabFirstHeading"
      style={{ color: colors.highlighted.dark }}
    >
      {label}
    </Text>
  </View>
);

SectionTitle.propTypes = {
  label: string
};

export default SectionTitle;
