import SegmentedControl from "../../../../../src/components/SegmentedControl/src/segmentedControl";
import SegmentedControlWithValidation from "../../../../../src/components/SegmentedControl/src/segmentedControlWithValidation";
import { Playground, Props } from "docz";
import * as React from 'react';
export default {
  SegmentedControl,
  SegmentedControlWithValidation,
  Playground,
  Props,
  React
};