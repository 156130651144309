/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";

export const Logo = () => {
  const { colors, spacing, fontSize, fontWeight } = useTheme();

  return (
    <div css={{ marginBottom: spacing.xlarge, verticalAlign: "middle" }}>
      <svg
        width="24"
        height="48"
        viewBox="0 0 48 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        alt="Balance Design System"
        css={{ verticalAlign: "middle" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 48V11.476C0 5.146 5.146 0 11.476 0h25.048C42.854 0 48 5.146 48 11.476v25.048C48 42.854 42.854 48 36.524 48H0z"
          fill="url(#paint1_linear)"
        />
        <circle cx="24" cy="72" r="24" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint1_linear"
            x1="37.609"
            y1="11.341"
            x2="5.113"
            y2="42.954"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EC4078" />
            <stop offset="1" stopColor="#F74239" />
          </linearGradient>
          <linearGradient
            id="paint0_linear"
            x1="41.4"
            y1="58.1"
            x2="7.5"
            y2="88.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#13CBD9" />
            <stop offset="1" stopColor="#009EFF" />
          </linearGradient>
        </defs>
      </svg>

      <div
        css={{
          display: "inline-block",
          marginLeft: spacing.medium,
          verticalAlign: "middle"
        }}
      >
        <div
          css={{
            fontSize: fontSize.xlarge,
            fontWeight: fontWeight.bold,
            marginBottom: spacing.xsmall
          }}
        >
          Balance
          <span
            css={{
              fontSize: fontSize.xlarge,
              fontWeight: fontWeight.regular,
              color: colors.foregroundHighlight
            }}
          >
            Mobile
          </span>
        </div>
        <div
          css={{ fontSize: fontSize.small, color: colors.foregroundMuted }}
          color="muted"
          marginBottom="xlarge"
        >
          Reckon Design System
        </div>
      </div>
    </div>
  );
};
