import { Playground, Props } from "docz";
import Footer from "../../../../../src/components/Footer/index";
import { Text, View } from "react-native";
import Button from "../../../../../src/components/Button/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Footer,
  Text,
  View,
  Button,
  React
};