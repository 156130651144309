import React from "react";
import PropTypes from "prop-types";
import { View, TextInput, Platform } from "react-native";

import { colors, text, radii, spacing } from "../../../core";
import { StackSpacer } from "../../Spacer";
import Text from "../../Text";

const PercentageInput = ({
  label,
  placeholder,
  isDisabled,
  errorMessage,
  testID,
  value: initialValue,
  onBlur: defaultOnBlur,
  onFocus: defaultOnFocus,
  onChange,
  ...props
}) => {
  const value =
    initialValue === 0 ? "0" : initialValue ? initialValue.toString() : "";
  const [isFocused, setFocused] = React.useState(false);

  // Define styles here to allow access to state
  const dynamicStyles = {
    input: {
      padding: spacing.medium,
      ...text["4x-500"],
      borderRadius: radii.small,
      backgroundColor: isFocused
        ? colors.inputField.focus.light
        : isDisabled
        ? colors.inputField.disabled.light
        : colors.inputField.idle.light,
      color: isFocused
        ? colors.inputField.focus.dark
        : isDisabled
        ? colors.inputField.disabled.dark
        : colors.inputField.idle.dark,
      flexGrow: 1,
      zIndex: 1
    }
  };

  const onFocus = () => {
    // Toggling the visual focus state
    setFocused(true);
    defaultOnFocus && defaultOnFocus();
  };

  const onBlur = () => {
    setFocused(false);
    defaultOnBlur && defaultOnBlur();
  };

  return (
    <View>
      {label && (
        <>
          <Text token="3.5x-500" style={{ color: colors.text.neutral }}>
            {label}
          </Text>
          <StackSpacer size="xsmall" />
        </>
      )}
      <TextInput
        style={dynamicStyles.input}
        onFocus={onFocus}
        onBlur={onBlur}
        editable={!isDisabled}
        placeholder={placeholder}
        placeholderTextColor={colors.text.muted}
        keyboardType={Platform.select({
          android: "numeric",
          ios: "numbers-and-punctuation"
        })}
        {...props}
        value={
          // format the string as currency if component isn't being interacted with
          value !== "" && !isFocused ? `${value}%` : value
        }
        testID={testID}
        /*
            Intercept the `onChangeText` event here so we can handle floating labels logic
            and then relay it to the `<Input />` component call (see relayOnchangeText method).
            We pass `{...props}` before that so that we can override `props.onChangeText`! ✨
          */
        onChangeText={value => onChange(value)}
      />
      {errorMessage && (
        <>
          <StackSpacer size="xsmall" />
          <Text token="3x-400" style={{ color: colors.alert.danger.dark }}>
            {errorMessage}
          </Text>
        </>
      )}
    </View>
  );
};

PercentageInput.propTypes = {
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  testID: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

PercentageInput.defaultProps = {
  value: ""
};

export default PercentageInput;
