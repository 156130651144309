import React from "react";
import {
  Keyboard,
  Platform,
  TextInput,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { bool, func, number, oneOf, string } from "prop-types";

import Digit from "./src/Digit";

export default class PinCodeInput extends React.Component {
  static propTypes = {
    autoFocus: bool,
    codeLength: number,
    keyboardAppearance: oneOf(["default", "light", "dark"]),
    onChange: func,
    value: string
  };
  static defaultProps = {
    autoFocus: true,
    codeLength: 4,
    keyboardAppearance: "default"
  };

  constructor(props, context) {
    super(props, context);

    this.input = React.createRef();
    this.digits = new Array(props.codeLength).fill(true);

    this.state = {
      isFocused: !!props.autoFocus
    };

    if (props.autoFocus) {
      this.handleFocus();
    }
  }

  render() {
    const {
      autoFocus,
      codeLength,
      keyboardAppearance,
      onChange,
      value
    } = this.props;

    const keyboardType = Platform.select({
      android: "numeric",
      ios: "number-pad"
    });

    return (
      <View height={70} justifyContent="center" alignItems="center">
        <TouchableWithoutFeedback onPress={this.handleFocus}>
          <View flexDirection="row" alignItems="center" justifyContent="center">
            {this.digits.map((d, index) => (
              <Digit
                index={index}
                isFocused={this.state.isFocused && index === value.length}
                key={index}
                value={value}
              />
            ))}
          </View>
        </TouchableWithoutFeedback>
        <TextInput
          ref={this.input}
          autoCapitalize="none"
          autoCorrect={false}
          autoFocus={autoFocus}
          caretHidden={true}
          clearButtonMode="never"
          contextMenuHidden={true}
          disableFullscreenUI={true}
          keyboardAppearance={keyboardAppearance}
          keyboardType={keyboardType}
          maxLength={codeLength}
          onBlur={this.handleBlur}
          onChangeText={onChange}
          style={{
            borderWidth: 1,
            borderColor: "transparent",
            height: 0
          }}
          value={value}
        />
      </View>
    );
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
    if (this.input.current !== null) {
      this.input.current.focus();
    }
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
    Keyboard.dismiss();
  };
}
