import React from "react";
import { number, string } from "prop-types";
import { Animated } from "react-native";

import { radii, spacing } from "../../core";

class Dot extends React.Component {
  state = {
    fadeAnim: new Animated.Value(0)
  };

  componentDidMount() {
    const { delay } = this.props;

    Animated.sequence([
      Animated.delay(delay),
      Animated.loop(
        Animated.sequence([
          Animated.timing(this.state.fadeAnim, {
            toValue: 1,
            duration: 600,
            useNativeDriver: false
          }),
          Animated.timing(this.state.fadeAnim, {
            toValue: 0,
            duration: 600,
            useNativeDriver: false
          }),
          Animated.delay(300)
        ]),
        {
          iterations: 800
        }
      )
    ]).start();
  }

  render() {
    let { fadeAnim } = this.state;
    const { color } = this.props;
    return (
      <Animated.View
        style={{
          borderRadius: radii.full,
          width: spacing.small,
          height: spacing.small,
          margin: spacing.xsmall,
          backgroundColor: color,
          opacity: fadeAnim
        }}
      />
    );
  }
}

Dot.propTypes = {
  color: string.isRequired,
  delay: number
};

Dot.defaultProps = {
  delay: 0
};

export default Dot;
