import React from "react";
import { array, bool, node, object, oneOfType, string } from "prop-types";
import { TouchableOpacity, View, StyleSheet } from "react-native";

import { spacing, colors, radii, boxShadow, sizing } from "../../core";
import { Icon } from "../../icons";
import { InlineSpacer } from "../Spacer";

const SelectableCard = ({ children, selected, style, iconSize, ...props }) => {
  const styledChildren = React.Children.map(children, child =>
    React.cloneElement(child, {
      style: { color: selected ? "white" : null, ...child.props.style }
    })
  );

  return (
    <TouchableOpacity {...props} delayPressIn={50}>
      <View style={[ss.base, selected && ss.selected, style]}>
        <View
          style={{
            flexDirection: "column",
            flex: 1
          }}
        >
          {styledChildren}
        </View>
        <InlineSpacer />
        <Icon
          icon="tickEnclosed"
          size={sizing[iconSize]}
          color={colors.action.light}
        />
      </View>
    </TouchableOpacity>
  );
};

SelectableCard.propTypes = {
  children: node.isRequired,
  selected: bool,
  style: oneOfType([object, array]),
  iconSize: string
};

SelectableCard.defaultProps = {
  iconSize: "4x"
};

export default SelectableCard;

// Styles
const ss = StyleSheet.create({
  base: {
    ...boxShadow,
    padding: spacing.large,
    borderRadius: radii.medium,
    alignItems: "center",
    backgroundColor: "#fff",

    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  selected: {
    backgroundColor: colors.selected.dark
  }
});
