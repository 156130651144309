const styles = {
  plain: {},
  styles: [
    {
      types: ["cdata", "comment", "doctype", "prolog"],
      style: {
        color: "#959daa"
      }
    },
    {
      types: ["punctuation"],
      style: {
        color: "#5a6270"
      }
    },
    {
      types: [
        "boolean",
        "constant",
        "deleted",
        "number",
        "property",
        "symbol",
        "tag"
      ],
      style: {
        color: "#f25cc1"
      }
    },
    {
      types: ["attr-name", "builtin", "char", "inserted", "selector", "string"],
      style: {
        color: "#26a29d"
      }
    },
    {
      types: ["atrule", "attr-value", "keyword"],
      style: {
        color: "#6554C0"
      }
    },
    {
      types: ["class-name", "function"],
      style: {
        color: "#f25cc1"
      }
    },
    {
      types: ["important", "regex", "variable"],
      style: {
        color: "#f4d03f"
      }
    },
    {
      types: ["bold", "important"],
      style: {
        fontWeight: "bold"
      }
    },
    {
      types: ["italic"],
      style: {
        fontStyle: "italic"
      }
    },
    {
      types: ["entity"],
      style: {
        cursor: "help"
      }
    },
    {
      types: ["namespace"],
      style: {
        opacity: 0.7
      }
    }
  ]
};

export default {
  dark: styles,
  light: styles
};
