import { format, eachDayOfInterval } from "date-fns";

import { colors } from "../../../core";

export const generateMarkedDays = (start, end) => {
  /**
   * returns marked days.
   * @param {string} start - The first date, in yyyy-MM-dd format.
   * @param {string} end - The last date, in yyyy-MM-dd format.
   * @return {object} An object with keys representing a selected date.
   */

  if (!start && !end) {
    return {};
  }

  if (start && !end) {
    return {
      [start]: {
        startingDay: true,
        color: colors.selected.dark,
        textColor: colors.selected.light
      }
    };
  }

  if (start > end) {
    return {};
  }

  const dates = eachDayOfInterval({
    start: new Date(start),
    end: new Date(end)
  });

  const obj = {};

  dates.forEach((date, index) => {
    const formatted = format(date, "yyyy-MM-dd");
    obj[formatted] = {
      selected: true,
      color: colors.selected.dark,
      textColor: colors.selected.light,
      ...(index === 0 ? { startingDay: true } : {}),
      ...(index === dates.length - 1 ? { endingDay: true } : {})
    };
  });
  return obj;
  /**
    value: Object {
      "2019-11-11": Object {
        "color": "#00DAAA",
        "startingDay": true,
        "textColor": "#ffffff",
      },
      "2019-11-12": Object {
        "color": "#00DAAA",
        "textColor": "#ffffff",
      },
      "2019-11-13": Object {
        "color": "#00DAAA",
        "endingDay": true,
        "textColor": "#ffffff",
      },
    }
    */
};
