import React from "react";
import { string, object, oneOf } from "prop-types";
import { View } from "react-native";

import Text from "../Text";
import { colors, spacing, radii, sizing } from "../../core";
import { Icon } from "../../icons";

const Badge = ({ label, type, style, ...props }) => {
  const baseStyles = {
    paddingHorizontal: spacing.medium,
    borderRadius: radii.full,
    alignSelf: "flex-start",
    alignItems: "center",
    backgroundColor: getColor(type).dark,
    justifyContent: "center",
    flexDirection: "row",
    minWidth: 60,
    height: sizing.xsmall,
    ...style
  };

  return (
    <View style={baseStyles} {...props}>
      {getContent(label, type)}
    </View>
  );
};

Badge.propTypes = {
  label: string.isRequired,
  style: object,
  type: oneOf(["idle", "pending", "success", "error", "disabled"]).isRequired
};

export default Badge;

const getContent = (label, type) => {
  const icons = ["pending", "dash"];
  const formattedLabel =
    label
      .toLowerCase()
      .charAt(0)
      .toUpperCase() + label.toLowerCase().slice(1);

  if (icons.includes(label)) {
    return (
      <Icon
        icon={getIcon(label)}
        color={getColor(type).light}
        size={sizing["2x"]}
      />
    );
  }

  return (
    <Text
      token="3x-700"
      style={{
        color: getColor(type).light
      }}
    >
      {formattedLabel}
    </Text>
  );
};

const getColor = type => {
  switch (type) {
    case "pending":
      return colors.notification.pending;
    case "success":
      return colors.notification.success;
    case "error":
      return colors.notification.not_success;
    case "disabled":
      return {
        dark: colors.disabled.light,
        light: "white"
      };
    default:
    case "idle":
      return colors.notification.idle;
  }
};

const getIcon = label => {
  switch (label) {
    case "pending":
      return "dots";
  }
};
