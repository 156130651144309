/* eslint-disable react/prop-types */
// TODO: prop-types

import React from "react";
import { Text as RNText } from "react-native";
import PropTypes from "prop-types";

import { text as tokens, colors } from "../../core";

const Text = ({ align = "auto", token, color, style, ...props }) => (
  <RNText
    style={{
      textAlign: align,
      color: color || colors.text.default,
      ...tokens[token],
      ...style
    }}
    {...props}
  />
);

Text.propTypes = {
  /** Alignment */
  align: PropTypes.oneOf(["left", "auto", "right", "center", "justify"]),
  /** Defaults to colors.text.default if not set. */
  color: PropTypes.string,
  /** Used to truncate the text with an ellipsis after computing the text layout, including line wrapping, such that the total number of lines does not exceed this number. */
  numberOfLines: PropTypes.number,
  /** The text style to apply. */
  token: PropTypes.oneOf(Object.keys(tokens))
};

Text.defaultProps = {
  token: "3x-400"
};

export default Text;
