import React from "react";

import text from "./index";

const TextDemo = () => {
  return (
    <table>
      <tr>
        <th>Token</th>
        <th>Preview</th>
        <th>Font size</th>
        <th>x4 multiplier</th>
      </tr>
      {Object.keys(text)
        .filter(i => i !== "__filemeta")
        .map(token => (
          <tr key={token}>
            <td>
              <code>{`${token}`}</code>
            </td>
            <td>
              <span style={{ ...text[token], lineHeight: "initial" }}>
                {token}
              </span>
            </td>
            <td>{text[token].fontSize}</td>
            <td>{`${text[token].fontSize / 4}x`}</td>
          </tr>
        ))}
    </table>
  );
};

export default TextDemo;
