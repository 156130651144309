import React from "react";
import {
  arrayOf,
  func,
  number,
  shape,
  string,
  oneOf,
  oneOfType
} from "prop-types";
import { View, ScrollView, TouchableOpacity } from "react-native";

import Divider from "../Divider";
import { colors } from "../../core";
import { InlineSpacer, StackSpacer } from "../Spacer";
import Text from "../Text";

import Underline from "./components/underline";
import StatusDot from "./components/statusdot";

const TabBar = ({ items: originalItems, activeItem, onPress, ...props }) => {
  // enables support of a simple array of strings as options
  // by converting the strings, into objects
  const items = originalItems.map(item => {
    if (typeof item === "string") {
      return {
        label: item
      };
    } else return item;
  });

  return (
    <View>
      <ScrollView horizontal={true}>
        {items.map((item, index) => {
          const isActive = index === activeItem;
          const isFirst = index === 0;

          return (
            <View
              key={`TabBarItem-${index}`}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              {isFirst && <InlineSpacer size="xxlarge" />}

              <TouchableOpacity {...props} onPress={() => onPress(index)}>
                <Text
                  token="4x-700"
                  style={{
                    textTransform: "capitalize",
                    color: isActive ? colors.text.default : colors.disabled.dark
                  }}
                >
                  {item.label}
                </Text>
                <StackSpacer size="small" />
                <Underline isActive={isActive} />
              </TouchableOpacity>
              {item.status && <StatusDot status={item.status} />}

              <InlineSpacer size="large" />
            </View>
          );
        })}
      </ScrollView>
      <Divider />
    </View>
  );
};

TabBar.propTypes = {
  /** The index of the active item, witin the 'items' array */
  activeItem: number.isRequired,
  /** An array of selectable items */
  items: oneOfType([
    arrayOf(string),
    arrayOf(
      shape({
        label: string.isRequired,
        status: oneOf(["success", "danger"])
      })
    )
  ]).isRequired,
  /** The function that runs as a result of an item being pressed. onpress = (item) => setActiveItem(item) */
  onPress: func.isRequired
};

TabBar.defaultProps = {
  active: 0
};

export default TabBar;
