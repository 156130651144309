import React, { forwardRef } from "react";
import { array, bool, string, func, object } from "prop-types";

import { FieldValidator } from "../../Form";

import DateSelect from "./DateSelect";

const DateSelectWithValidation = forwardRef(
  (
    {
      defaultValue,
      form,
      isRequired,
      label,
      name,
      validationMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;

    // stub the component's default onChange if it's not defined
    const onChange =
      typeof props.onChange === "function" ? props.onChange : () => {};

    const validationLabel = label.replace("*", "");

    // value only contains numbers
    const isDateStringFormat = field => {
      const label = field.label || "Field";

      if (
        !field.value ||
        new RegExp("([0-9]{4})-([0-9]{2})-([0-9]{2})").test(field.value)
      ) {
        return false;
      }
      return `${label} must be complete`;
    };

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={validationLabel}
        name={name}
        onChange={onChange}
        validationMethods={[isDateStringFormat, ...validationMethods]}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <DateSelect
              errorMessage={errorMessage}
              label={label}
              ref={ref}
              {...props}
              {...validatorProps}
              onChange={async text => {
                await validatorProps.onChange(text, keyValue);
                validatorProps.onBlur();
              }}
            />
          );
        }}
      </FieldValidator>
    );
  }
);

DateSelectWithValidation.propTypes = {
  defaultValue: string,
  form: object.isRequired,
  isDisabled: bool,
  isRequired: bool,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func,
  onKeyPress: func,
  validationMethods: array
};

DateSelectWithValidation.defaultProps = {
  validationMethods: []
};

DateSelectWithValidation.displayName = "DateSelectWithValidation";

export default DateSelectWithValidation;
