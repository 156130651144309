import React, { useMemo } from "react";
import {
  shape as objectShape,
  string,
  bool,
  oneOf,
  oneOfType,
  array,
  object
} from "prop-types";
import { View, Text, ImageBackground } from "react-native";

import { colors as colorPacks, sizing, radii } from "../../core";

import { getColor, hashCode, getInitials } from "./utils";

const fontSizes = {
  md: 18,
  sm: 12,
  xs: 10
};

const Avatar = ({
  name,
  colors: specifiedColors,
  src,
  style,
  size,
  isDisabled,
  ...props
}) => {
  const initials = useMemo(() => getInitials(name), [name]);
  const hashValue = useMemo(() => hashCode(name), [name]);
  const colors = specifiedColors ? specifiedColors : getColor(hashValue);

  const ss = {
    base: {
      borderRadius: radii.full,
      height: sizes[size],
      width: sizes[size]
    },
    colorAvatar: {
      alignItems: "center",
      backgroundColor: isDisabled ? "transparent" : colors.dark,
      borderColor: isDisabled
        ? colorPacks.disabled.dark
        : colorPacks.selected.light,
      borderWidth: 2,
      justifyContent: "center"
    },
    text: {
      fontSize: fontSizes[size],
      fontWeight: "700",
      color: isDisabled ? colorPacks.disabled.dark : colors.light
    },
    image: {
      borderRadius: radii.full,
      backgroundColor: isDisabled ? colorPacks.disabled.dark : colors.dark,
      flex: 1,
      resizeMode: "cover",
      justifyContent: "center"
    }
  };

  return (
    <View
      style={{
        ...ss.base,
        ...(src ? {} : ss.colorAvatar),
        ...style
      }}
      {...props}
    >
      {src ? (
        <ImageBackground
          source={{ uri: src }}
          imageStyle={{ borderRadius: radii.full }}
          style={ss.image}
        />
      ) : (
        <Text style={ss.text}>{initials}</Text>
      )}
    </View>
  );
};

Avatar.propTypes = {
  /** Determines if Avatar has disabled appearance */
  isDisabled: bool,
  /** An object of colors which will be applied to the avatar */
  colors: objectShape({
    dark: string,
    light: string
  }),
  /** Determines the size of the overall component */
  size: oneOf(["xl", "md", "sm", "xs"]),
  /** A URL of an avatar image file */
  src: string,
  /** Apply style to the outer element (e.g. padding, position) */
  style: oneOfType([array, object]),
  /** The name of the person. e.g. "Nathan Simpson" */
  name: string.isRequired
};

Avatar.defaultProps = {
  size: "md"
};

// TODO: remove mapper and uses core sizes (same as web).
const sizes = {
  xl: 80,
  md: sizing.large,
  sm: sizing.base,
  xs: sizing.small
};

export default Avatar;
