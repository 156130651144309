import { Playground, Props } from "docz";
import { View } from "react-native";
import Box from "../../../../../src/components/Box/index";
import { StackSpacer } from "../../../../../src/components/Spacer";
import { spacing } from "../../../../../src/core";
import Text from "../../../../../src/components/Text";
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  Box,
  StackSpacer,
  spacing,
  Text,
  React
};