import { colors as colorPacks } from "../../core";

const tintPack = colorPacks.instance;

/**
 * @param {string} color — automatic or actual color object
 * @param {number} hashValue
 * @return {Object} { color, backgroundColor }
 */
export function getColor(hashValue) {
  const key = hashValue % tintPack.length;
  return tintPack[key];
}

export function hashCode(s) {
  const str = String(s);
  let hash = 0;
  let char;

  if (str.trim().length === 0) return hash;

  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return Math.abs(hash);
}

export function getInitials(name, fallback = "?") {
  if (!name || typeof name !== "string") return fallback;

  return name
    .replace(/\s+/, " ")
    .split(" ") // Repeated spaces results in empty strings
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase()) // Watch out for empty strings
    .join("");
}
