import { Playground, Props } from "docz";
import { View } from "react-native";
import Badge from "../../../../../src/components/Badge/index";
import { InlineSpacer } from "../../../../../src/components/Spacer";
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  Badge,
  InlineSpacer,
  React
};