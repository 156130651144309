import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight
} from "react-native";
import { bool, func, object, string } from "prop-types";

import Button from "../../Button";
import { colors, spacing, sizing, radii } from "../../../core";
import { Icon } from "../../../icons";
import { StackSpacer } from "../../Spacer";
import Header from "../../Header";
import Footer from "../../Footer";
import Text from "../../Text";

const daysOfWeek = [
  { key: "monday", value: "Monday", display: "M" },
  { key: "tuesday", value: "Tuesday", display: "Tu" },
  { key: "wednesday", value: "Wednesday", display: "W" },
  { key: "thursday", value: "Thursday", display: "Th" },
  { key: "friday", value: "Friday", display: "F" },
  { key: "saturday", value: "Saturday", display: "Sa" },
  { key: "sunday", value: "Sunday", display: "Su" }
];

export const DaysRow = () => (
  <View style={ss.daysRow}>
    {daysOfWeek.map(day => (
      <View key={day.key} style={ss.dayCell}>
        <Text token="3.5x-700" align="center">
          {day.display}
        </Text>
      </View>
    ))}
  </View>
);

export const CalendarHeader = ({ title, handleClear, clearable }) => (
  <Header
    title={title}
    afterElement={
      clearable ? (
        <TouchableOpacity onPress={handleClear}>
          <Text
            token="4x-500"
            style={{
              color: colors.action.dark
            }}
          >
            Clear
          </Text>
        </TouchableOpacity>
      ) : null
    }
  />
);

CalendarHeader.propTypes = {
  clearable: bool,
  handleClear: func,
  title: string
};

CalendarHeader.defaultProps = {
  clearable: false
};

export const CalendarFooter = ({
  title,
  hasValue,
  selectedValue,
  onDone,
  testID
}) => (
  <Footer direction="row">
    <View style={{ flex: 1 }}>
      <Text token="3x-700" style={{ color: colors.text.neutral }}>
        {title.toUpperCase()}
      </Text>
      <StackSpacer size="xsmall" />
      <Text
        token="3.5x-700"
        style={{
          color: hasValue ? colors.inputField.idle.dark : colors.text.muted
        }}
      >
        {selectedValue === "Select pay period"
          ? "No dates selected"
          : selectedValue}
      </Text>
    </View>
    <Button
      type="primary"
      size="xsmall"
      onPress={onDone}
      testID={testID}
      label="Done"
      style={{ alignSelf: "flex-end" }}
    />
  </Footer>
);

CalendarFooter.propTypes = {
  hasValue: bool,
  onDone: func,
  selectedValue: string,
  testID: string,
  title: string
};

CalendarFooter.defaultProps = {
  selectedValue: null,
  hasValue: false
};

export const PickerInputField = ({
  hasValue,
  isDisabled,
  label,
  onPress,
  style,
  testID,
  value
}) => (
  <View style={style}>
    <Text token="3.5x-500" style={{ color: colors.text.neutral }}>
      {label}
    </Text>
    <StackSpacer size="xsmall" />
    <TouchableHighlight
      disabled={isDisabled}
      onPress={onPress}
      style={ss.input}
      testID={testID}
      underlayColor={colors.inputField.focus.light}
    >
      <>
        <Text
          token="4x-500"
          style={{
            color: isDisabled
              ? colors.text.muted
              : hasValue
              ? colors.text.default
              : colors.text.neutral
          }}
        >
          {value}
        </Text>
        <Icon
          icon="event"
          size={sizing["1.5x"]}
          color={colors.inputField.idle.support}
        />
      </>
    </TouchableHighlight>
  </View>
);

PickerInputField.propTypes = {
  hasValue: bool,
  isDisabled: bool,
  label: string,
  onPress: func,
  style: object,
  testID: string,
  value: string
};

PickerInputField.defaultProps = {
  hasValue: false
};

const ss = StyleSheet.create({
  daysRow: {
    paddingVertical: spacing.small,
    paddingHorizontal: spacing.large,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.separator
  },
  dayCell: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  input: {
    padding: spacing.medium,
    borderRadius: radii.small,
    backgroundColor: colors.inputField.idle.light,
    flexGrow: 1,
    zIndex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  inputIcon: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: sizing["2x"],
    right: sizing["2x"]
  }
});
