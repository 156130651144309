import React, { forwardRef } from "react";
import { array, bool, func, number, object, string } from "prop-types";

import {
  FieldValidator,
  FormValidationRules,
  formatValueToNumber
} from "../../Form";

import PercentageInput from "./percentageInput";

const PercentageInputWithValidation = forwardRef(
  (
    {
      defaultValue,
      form,
      formatMethods,
      isRequired,
      label,
      name,
      onBlur,
      onFocus,
      validationMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;

    // stub the component's default onChange if it's not defined
    const onChange =
      typeof props.onChange === "function" ? props.onChange : () => {};

    const validationLabel = label.replace("*", "");

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={validationLabel}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        formatMethods={[formatValueToNumber, ...(formatMethods || [])]}
        validationMethods={[
          FormValidationRules.isNumber,
          ...(validationMethods || [])
        ]}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <PercentageInput
              errorMessage={errorMessage}
              label={label}
              ref={ref}
              {...props}
              {...validatorProps}
              onChange={val => validatorProps.onChange(val, keyValue)}
            />
          );
        }}
      </FieldValidator>
    );
  }
);

PercentageInputWithValidation.propTypes = {
  defaultValue: number,
  form: object.isRequired,
  formatMethods: array,
  isDisabled: bool,
  isRequired: bool,
  label: string.isRequired,
  name: string.isRequired,
  onBlur: func,
  /** A secondary onChange method which will run before the default one */
  onChange: func,
  onFocus: func,
  placeholder: string,
  testID: string,
  validationMethods: array
};

PercentageInputWithValidation.displayName = "PercentageInputWithValidation";

export default PercentageInputWithValidation;
