/** @jsx jsx */
import { theme, useConfig, ComponentsProvider } from "docz";
import { jsx } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import { element } from "prop-types";

import { themeConfig } from "./theme";
import components from "./components";

const Theme = ({ children }) => {
  const config = useConfig();
  return (
    <ThemeProvider theme={config.themeConfig}>
      <ComponentsProvider components={components}>
        {children}
      </ComponentsProvider>
    </ThemeProvider>
  );
};

Theme.propTypes = {
  children: element
};

export default theme(themeConfig)(Theme);
