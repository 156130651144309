import { Playground, Props } from "docz";
import { useState } from "react";
import TabBar from "../../../../../src/components/TabBar/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  useState,
  TabBar,
  React
};