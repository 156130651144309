import { Playground, Props } from "docz";
import { Icon, ICONS } from "../../../../../src/icons/index";
import { colors } from "../../../../../src/core";
import { View, Text } from "react-native";
import IconsDemo from "../../../../../src/icons/iconsDemo";
import * as React from 'react';
export default {
  Playground,
  Props,
  Icon,
  ICONS,
  colors,
  View,
  Text,
  IconsDemo,
  React
};