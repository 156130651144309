// clean falsy values
const cleanString = str => {
  if (str == null) return "";
  return str;
};

const DELIMITER = "/";

/**
 * Date Mask - Out
 * @param {string} inputString - Date string in the format DD/MM/YYYY
 * @returns {string} Date string in the format YYYY-MM-DD
 *
 * @example
 * dateMaskTransformOut('23/04/1987') --> '1987-04-23'
 */

export const dateMaskTransformOut = inputString => {
  let str = cleanString(inputString);
  return str
    .split(DELIMITER)
    .reverse()
    .join("-");
};

/**
 * Date Mask - In
 * @param {string} inputString - Date string in the format YYYY-MM-DD
 * @returns {string} Date string in the format DD/MM/YYYY
 *
 * @example
 * dateMaskTransformIn('1987-04-23') --> '23/04/1987'
 */

export const dateMaskTransformIn = inputString => {
  let str = cleanString(inputString);
  return str
    .split("-")
    .reverse()
    .join(DELIMITER);
};

/**
 * Date Mask - Transform
 * @param {string} inputString - Date string
 * @returns {string} Date string in the format DD/MM/YYYY
 */

export const dateMaskFormatter = (inputString, maybeKey) => {
  let str = cleanString(inputString);
  let arr = str.split("").filter(char => char !== DELIMITER);

  // add slashes "/" between the day/month/year
  let result = arr
    .map((char, idx) => {
      if (idx === 1) return char + DELIMITER;
      if (idx === 3) return char + DELIMITER;
      return char;
    })
    .join("");

  // handle awkward backspace behaviour
  if (maybeKey && maybeKey === "Backspace" && [2, 4].includes(arr.length)) {
    result = result.slice(0, result.length - 1);
  }

  return result;
};

/**
 * Currency Mask - Out
 * @param {string} inputString - Currency string in the format $1234.56
 * @returns {float} - eg 1234.56
 *
 * @example
 * currencyMaskTransformOut('$1234.56') --> 1234.56
 */
export const currencyMaskTransformOut = value => {
  const isNegative = value.startsWith("-");
  const number = isNegative ? value.slice(1, value.length) : value;

  if (number.length && isNaN(parseFloat(number))) {
    return "";
  } else {
    return number;
  }
};

// validates currency input
export const handleTextChange = value => {
  const number = value.startsWith("-") ? value.slice(1, value.length) : value;
  if (number.length && isNaN(parseFloat(number))) {
    this.props.onChange("");
  } else {
    this.props.onChange(value);
  }
};

// Converts string to number. Rounds numbers that are longer that
// the length of the decimals parameter.
export const formatValueToNumber = (
  value,
  { maximumDecimalPlaces = false } = {}
) => {
  // formatValueToNumber("1") -> 1
  // formatValueToNumber("1.234") -> 1.234
  // formatValueToNumber("1.234", { maximumDecimalPlaces: 2 }) -> 1.23
  // formatValueToNumber("1.234", { maximumDecimalPlaces: 0 }) -> 1
  if (value === "" || value === null || value === undefined) {
    return undefined;
  }

  if (typeof maximumDecimalPlaces === "number") {
    return parseFloat(parseFloat(value).toFixed(maximumDecimalPlaces));
  } else {
    return parseFloat(value);
  }
};
