/**
 * INTERNAL USE ONLY
 */
import hexAlpha from "hex-alpha";

const tokenColors = {
  pink: "#F84184",
  blue: "#007AFF", //action
  purple: "#433D9D",
  cyan: "#00BFFF",
  turquoise: "#00DAAA",
  amber: "#FFAC4B",
  red: "#E30613",
  babyBlue: "#ECF5FF",
  grey00: "#ffffff", // white
  grey10: "#F8F9F9", // background
  grey20: "#F0F0F1", // ui_05
  grey30: "#E8E8E8", // ui_04
  grey40: "#DDDDDD", // border
  grey50: "#B6B8B8", // text_04
  grey60: "#AEAFAF", // ui_03
  grey70: "#828383", // text_03
  grey80: "#6B6C6C", // muted
  grey90: "#3F3F3F", // label
  grey100: "#121212" // neutral
};

/**
 * EXTRERNAL USE
 */
export default {
  hero: {
    dark: tokenColors.pink,
    light: tokenColors.grey00
  },

  action: {
    dark: tokenColors.blue,
    light: tokenColors.grey00
  },

  actionSecondary: {
    dark: tokenColors.blue,
    light: tokenColors.babyBlue
  },

  actionDestructive: {
    dark: tokenColors.red,
    light: tokenColors.grey00
  },

  idle: {
    dark: tokenColors.grey80,
    light: tokenColors.grey30
  },

  selected: {
    dark: tokenColors.turquoise,
    light: tokenColors.grey00
  },

  highlighted: {
    dark: tokenColors.purple,
    light: tokenColors.grey00
  },

  disabled: {
    dark: tokenColors.grey60,
    light: tokenColors.grey20
  },

  notification: {
    success: {
      dark: tokenColors.turquoise,
      light: tokenColors.grey00
    },

    not_success: {
      dark: tokenColors.red,
      light: tokenColors.grey00
    },

    pending: {
      dark: tokenColors.purple,
      light: tokenColors.grey00
    },

    idle: {
      dark: tokenColors.grey60,
      light: tokenColors.grey00
    }
  },

  alert: {
    danger: {
      dark: tokenColors.red,
      light: tokenColors.grey00
    },

    success: {
      dark: tokenColors.turquoise,
      light: tokenColors.grey00
    }
  },

  instance: [
    {
      dark: tokenColors.cyan,
      light: tokenColors.grey00
    },
    {
      dark: tokenColors.pink,
      light: tokenColors.grey00
    },
    {
      dark: tokenColors.turquoise,
      light: tokenColors.grey00
    },
    {
      dark: tokenColors.purple,
      light: tokenColors.grey00
    },
    {
      dark: tokenColors.amber,
      light: tokenColors.grey00
    }
  ],

  inputField: {
    idle: {
      dark: tokenColors.grey90,
      light: tokenColors.grey20,
      support: tokenColors.grey50
    },

    focus: {
      dark: tokenColors.grey90,
      light: tokenColors.grey30,
      support: tokenColors.grey50
    },

    disabled: {
      dark: tokenColors.grey50,
      light: tokenColors.grey20,
      support: tokenColors.grey50
    }
  },

  text: {
    default: tokenColors.grey100,
    neutral: tokenColors.grey70,
    muted: tokenColors.grey50
  },

  progress: {
    completed: {
      dark: tokenColors.turquoise,
      light: tokenColors.grey00
    },
    active: {
      dark: tokenColors.grey60,
      light: tokenColors.grey00
    },
    pending: {
      dark: tokenColors.grey30,
      light: tokenColors.grey00
    }
  },

  separator: tokenColors.grey40,

  background: {
    default: tokenColors.grey10,
    elevated: tokenColors.grey00,
    sunken: tokenColors.grey20,
    splash: tokenColors.babyBlue,
    overlay: hexAlpha(tokenColors.grey100, 0.5)
  }
};
