import React from "react";
import { View } from "react-native";
import { func, string, oneOf } from "prop-types";

import { colors, spacing, sizing } from "../../core";
import Text from "../Text";
import Button from "../Button";
import { Icon } from "../../icons";
import { StackSpacer } from "../Spacer";
import Screen from "../Screen";

export const ErrorMessage = ({
  body,
  buttonLabel,
  buttonType,
  heading,
  icon,
  onPress,
  onLogOut
}) => {
  return (
    <View
      style={{
        paddingVertical: spacing.large,
        paddingHorizontal: spacing.xxlarge
      }}
    >
      <Icon icon={icon} size={sizing.small} style={{ alignSelf: "center" }} />
      <StackSpacer />
      <Text token="3.5x-500" align="center">
        {heading}
      </Text>
      <StackSpacer size="small" />
      <Text token="3x-400" align="center" color={colors.text.neutral}>
        {body}
      </Text>
      <StackSpacer size="large" />
      {onPress && (
        <Button type={buttonType} onPress={onPress} label={buttonLabel} />
      )}
      {onLogOut && (
        <>
          <StackSpacer size="large" />
          <Text token="3x-400" align="center" color={colors.text.neutral}>
            If the problem persists,{" "}
            <Text
              token="3x-700"
              onPress={() => onLogOut()}
              style={{ color: colors.action.dark }}
            >
              try logging out
            </Text>
          </Text>
        </>
      )}
    </View>
  );
};

export const ErrorScreen = props => (
  <Screen>
    <View style={{ flex: 1, justifyContent: "center" }}>
      <ErrorMessage {...props} />
    </View>
  </Screen>
);

const propTypes = {
  /** Main headline of the error. */
  heading: string,
  /** A more detailed description of the error. */
  body: string,
  /** The label for the main button. */
  buttonLabel: string,
  /** See type prop in <Button/> for more information. */
  buttonType: oneOf(["primary", "secondary", "tertiary"]),
  /** Choose what icon will appear at the top. See <Icon /> for more info. */
  icon: string,
  /** Setting this function decides if the 'try logging out' line is visuble. */
  onLogOut: func,
  /** The function that will run when user presses main button. Setting this prop decides if the button is visible. */
  onPress: func
};

const defaultProps = {
  buttonLabel: "Try again",
  buttonType: "tertiary",
  heading: "An error occoured",
  body: "Something went wrong, please try again later",
  icon: "disconnected"
};

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;
ErrorScreen.propTypes = propTypes;
ErrorScreen.defaultProps = defaultProps;
