import { Playground, Props } from "docz";
import { Text } from "react-native";
import Skeleton from "../../../../../src/components/Skeleton/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Text,
  Skeleton,
  React
};