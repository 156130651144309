import React from "react";
import { array, object, oneOfType, string } from "prop-types";
import { View } from "react-native";

import { colors } from "../../core";

import Dot from "./dot";

const Ellipsis = ({ color, style }) => (
  <View style={[{ flexDirection: "row" }, style]}>
    <Dot color={color} />
    <Dot color={color} delay={300} />
    <Dot color={color} delay={600} />
  </View>
);

Ellipsis.propTypes = {
  color: string,
  style: oneOfType([array, object])
};

Ellipsis.defaultProps = {
  color: colors.notification.pending.dark
};

export default Ellipsis;
