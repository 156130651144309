import React from "react";
import { StyleSheet, View } from "react-native";
import { number } from "prop-types";

import { Icon } from "../../icons";
import { colors, radii, sizing } from "../../core";
import { InlineSpacer } from "../Spacer";

const ProgressBar = ({ steps, current }) => (
  <View
    style={{
      flexDirection: "row",
      alignItems: "stretch",
      alignSelf: "flex-end"
    }}
  >
    {// create the correct amount of notches in the ProgressBar
    new Array(steps).fill("").map((c, index) => (
      <Step index={index} current={current} key={index} />
    ))}
  </View>
);

const Step = ({ index, current }) => {
  const isCurrent = index === current;
  const isComplete = index < current;

  const ss = StyleSheet.create({
    tickBadge: {
      borderRadius: radii.full,
      height: sizing["2x"],
      width: sizing["2x"],
      backgroundColor:
        index === current - 1 ? colors.progress.completed.dark : "transparent",
      alignItems: "center",
      justifyContent: "center"
    },
    notch: {
      borderRadius: radii.small,
      width: "100%",
      height: 4,
      top: 10,
      backgroundColor: isComplete
        ? colors.progress.completed.dark
        : isCurrent
        ? colors.progress.active.dark
        : colors.progress.pending.dark
    }
  });

  return (
    <>
      {index !== 0 && <InlineSpacer size="xsmall" />}
      <View
        style={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}
      >
        <View style={ss.notch} />
        <View style={ss.tickBadge}>
          <Icon
            icon="tick"
            size={sizing["1x"]}
            color={
              index === current - 1
                ? colors.progress.completed.light
                : "transparent"
            }
          />
        </View>
      </View>
    </>
  );
};

ProgressBar.propTypes = {
  /** The current step */
  current: number.isRequired,
  /** The amount of steps or 'dots' in the progress bar */
  steps: number.isRequired
};

Step.propTypes = {
  /** The current step */
  current: number.isRequired,
  /** The amount of steps or 'dots' in the progress bar */
  index: number.isRequired
};

export default ProgressBar;
