import React, { Children, Fragment, isValidElement } from "react";
import { number, oneOf, oneOfType, string, node } from "prop-types";
import { View } from "react-native";

import Box from "../Box";
import { spacing, radii } from "../../core";
import Divider from "../Divider";
import { StylePropType } from "../../helpers";

export const Stack = ({
  align = "stretch",
  children,
  orientation = "vertical",
  dividers = "none",
  gap = "none",
  ...props
}) => {
  const { marginProperty } = orientationMap[orientation];

  return (
    <Box orientation={orientation} align={align} {...props}>
      {["around", "start"].includes(dividers) && (
        <Divider orientation={orientation} />
      )}
      {Children.map(children, (child, idx) => {
        if (!isValidElement(child)) {
          return null;
        }

        return (
          <Fragment>
            {dividers !== "none" && idx ? (
              <Divider
                orientation={orientation}
                style={{
                  [marginProperty]: spacing[gap]
                }}
              />
            ) : null}
            <Div
              style={{
                [marginProperty]:
                  (["around", "start"].includes(dividers) || idx) &&
                  spacing[gap]
              }}
            >
              {child}
            </Div>
          </Fragment>
        );
      })}
      {["around", "end"].includes(dividers) && (
        <Divider
          orientation={orientation}
          style={{
            [marginProperty]: spacing[gap]
          }}
        />
      )}
    </Box>
  );
};

const spacingType = oneOf(Object.keys(spacing));

Stack.propTypes = {
  /** The align prop maps to the align-items CSS property, and defines the alignment of items along the cross-axis of the flex container. */
  align: oneOf(["center", "end", "start", "stretch"]),
  /** Background color */
  background: oneOf(["none", "default", "sunken", "elevated"]),
  /** Define the initial main size of a flex item. */
  basis: oneOfType([number, string]),
  /** Each element in the stack. */
  children: node,
  /** The placement, if any, of the dividing elements. */
  dividers: oneOf(["none" | "around" | "between" | "start" | "end"]),
  /** You can pass shorthand values directly to the CSS flex property. e.g.<Box flex="0 1 auto" /> */
  flex: oneOfType([number, string]),
  /** The size of the gap between each element in the stack. */
  gap: spacingType,
  /** Define grow "factor" of a flex item. It accepts a unitless value, which dictates the amount of available space inside the flex container the item should take up. */
  grow: number,
  /** Height */
  height: number,
  /** The justify prop maps to the justify-content CSS property, and defines the distribution of space between items along the main-axis of the flex container. */
  justify: oneOf([
    "around",
    "between",
    "center",
    "end",
    "even",
    "start",
    "stretch"
  ]),
  /** Margin */
  margin: spacingType,
  /** Margin bottom */
  marginBottom: spacingType,
  /** Margin left */
  marginLeft: spacingType,
  /** Margin right */
  marginRight: spacingType,
  /** Margin top */
  marginTop: spacingType,
  /** Margin horizontal */
  marginX: spacingType,
  /** Margin vertical */
  marginY: spacingType,
  /** The orientation prop maps to the flex direction CSS property, allowing us to maintain a consistent API among the design system primitives. */
  orientation: oneOf(["horizontal", "vertical"]),
  /** Padding */
  padding: spacingType,
  /** Padding bottom */
  paddingBottom: spacingType,
  /** Padding left */
  paddingLeft: spacingType,
  /** Padding right */
  paddingRight: spacingType,
  /** Padding top */
  paddingTop: spacingType,
  /** Padding horizontal */
  paddingX: spacingType,
  /** Padding vertical */
  paddingY: spacingType,
  /** Radius */
  rounding: oneOf(Object.keys(radii)),
  /** Radius bottom */
  roundingBottom: oneOf(Object.keys(radii)),
  /** Radius left */
  roundingLeft: oneOf(Object.keys(radii)),
  /** Radius right */
  roundingRight: oneOf(Object.keys(radii)),
  /** Radius top */
  roundingTop: oneOf(Object.keys(radii)),
  /** Define shrink "factor" of a flex item. It accepts a unitless value, which dictates the amount of available space inside the flex container the item should take up. */
  shrink: number,
  /** The regular style prop */
  style: StylePropType,
  /** Width */
  width: number
};

// Styled Components
// ------------------------------

// min-width and min-height declarations prevent overflow issues
// https://css-tricks.com/preventing-a-grid-blowout/
const Div = props => <View style={{ minHeight: 0, minWidth: 0 }} {...props} />;

// Utils
// ------------------------------

const orientationMap = {
  horizontal: {
    flexDirection: "horizontal",
    marginProperty: "marginLeft"
  },
  vertical: {
    flexDirection: "column",
    marginProperty: "marginTop"
  }
};
