import React from "react";
import { View, ScrollView } from "react-native";
import { arrayOf, func, shape, string, oneOf, oneOfType } from "prop-types";

import { InlineSpacer } from "../Spacer";
import { spacing } from "../../core";

import Pill from "./components/pill";

const PillBar = ({ options: originalOptions, paddingLeft, value, onPress }) => {
  // enables support of a simple array of strings as options
  // by converting the strings, into objects
  const options = originalOptions.map(option => {
    if (typeof option === "string") {
      return {
        label: option,
        value: option
      };
    } else return option;
  });

  return (
    <View>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: spacing.small }}
      >
        <InlineSpacer size={paddingLeft} />
        {options.map(item => {
          const isActive = item.value === value;

          return (
            <Pill
              key={item.value}
              {...{
                ...item,
                onPress,
                isActive
              }}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

PillBar.propTypes = {
  /** The amount of space before the first item. This allows the first item in the list to line up with page content. See "Spacing" for available tokens. */
  paddingLeft: oneOf(Object.keys(spacing)),
  /** The name of the active item */
  value: string,
  /** An array of selectable options */
  options: arrayOf(
    oneOfType([
      string,
      shape({
        value: string.isRequired,
        label: string.isRequired
      })
    ])
  ).isRequired,
  /** The function that runs as a result of an item being pressed. onpress = (item) => setActiveItem(item) */
  onPress: func.isRequired
};

PillBar.defaultProps = {
  active: 0,
  paddingLeft: "xxlarge"
};

export default PillBar;
