import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";

import { spacing } from "../../core";

// inline
const InlineSpacer = ({ size }) => (
  <View style={{ paddingRight: spacing[size] }} />
);

InlineSpacer.propTypes = {
  size: PropTypes.string
};

InlineSpacer.defaultProps = {
  size: "small"
};

// stack
const StackSpacer = ({ size }) => (
  <View style={{ paddingBottom: spacing[size] }} />
);

StackSpacer.propTypes = {
  size: PropTypes.oneOf(Object.keys(spacing))
};

StackSpacer.defaultProps = {
  size: "small"
};

export { InlineSpacer, StackSpacer };
