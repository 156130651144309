import { Playground, Props } from "docz";
import CircleButton from "../../../../../src/components/CircleButton/index";
import { View } from "react-native";
import * as React from 'react';
export default {
  Playground,
  Props,
  CircleButton,
  View,
  React
};