import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "214-2020-11-29"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v2.1.3...v2.1.4"
      }}>{`2.1.4`}</a>{` (2020-11-29)`}</h2>
    <h3 {...{
      "id": "bug-fixes"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` Update react-native-safe-area-context (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/09d052b762348b22e342abf1714ef954f305499f"
        }}>{`09d052b`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "213-2020-11-26"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v2.1.2...v2.1.3"
      }}>{`2.1.3`}</a>{` (2020-11-26)`}</h2>
    <h3 {...{
      "id": "bug-fixes-1"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DateSelect:`}</strong>{` Opt out of iOS 14 DatePicker UIs (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/36d67629c8f484567d0734eb2b3322efcab78d25"
        }}>{`36d6762`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "212-2020-11-25"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v2.1.1...v2.1.2"
      }}>{`2.1.2`}</a>{` (2020-11-25)`}</h2>
    <h3 {...{
      "id": "bug-fixes-2"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`header:`}</strong>{` Ensure title does not wrap (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f8a69c20d0e3e8c5fcefd5c45b3107b23dcca0f9"
        }}>{`f8a69c2`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "211-2020-10-14"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v2.1.0...v2.1.1"
      }}>{`2.1.1`}</a>{` (2020-10-14)`}</h2>
    <h3 {...{
      "id": "bug-fixes-3"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`header:`}</strong>{` Ensure title does not wrap (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/a8ef50fe057f24a5027e5e3dd4bcb019e4b09147"
        }}>{`a8ef50f`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "210-2020-10-13"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v2.0.1...v2.1.0"
      }}>{`2.1.0`}</a>{` (2020-10-13)`}</h1>
    <h3 {...{
      "id": "bug-fixes-4"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Correct proptypes for size (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7f5ba17832c2337ba2553e2c6c8cce743d36f166"
        }}>{`7f5ba17`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PayItem:`}</strong>{` Use correct text token (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/333f5c2a93565875d08d332ef59a99fde2b7a9de"
        }}>{`333f5c2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` Add checkCircle icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/8b53c1b84a79910140942af857e4b748fc18e096"
        }}>{`8b53c1b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` Add clock icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/08c508f07bf0ee27bc398077a2d72b26d30e3e8b"
        }}>{`08c508f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "201-2020-09-24"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v2.0.0...v2.0.1"
      }}>{`2.0.1`}</a>{` (2020-09-24)`}</h2>
    <h3 {...{
      "id": "bug-fixes-5"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`stack:`}</strong>{` Remove usage of unsupported CSS property (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/17d9aa38d49d49469f19fb66f9de10f55dda5a8e"
        }}>{`17d9aa3`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "200-2020-09-23"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.53.0...v2.0.0"
      }}>{`2.0.0`}</a>{` (2020-09-23)`}</h1>
    <h3 {...{
      "id": "features-1"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`core:`}</strong>{` New sizing, spacing and radii tokens from Reckon Design System (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/492b40dc46e391f3526ef25d0a62afa6f8d4f429"
        }}>{`492b40d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Icons:`}</strong>{` Add sizing tokens to Icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/808312339ae7df85e8f2f850bba146b29a9b71a6"
        }}>{`8083123`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "breaking-changes"
    }}>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`core:`}</strong>{` This releases introduces all-new tokens for sizing, spacing and radii. Please read the upgrade guide to learn about upgrading your apps.`}</li>
    </ul>
    <p>{`Approved-by: Joss Mackison`}</p>
    <h1 {...{
      "id": "1530-2020-09-17"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.52.2...v1.53.0"
      }}>{`1.53.0`}</a>{` (2020-09-17)`}</h1>
    <h3 {...{
      "id": "bug-fixes-6"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DataRow:`}</strong>{` allow labels to contract for smaller screens (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9b2540036699d044582c97d70b8ac4685cc652e9"
        }}>{`9b25400`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-2"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` Create Stack component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ab3fe9f0d06e10e20a9c01cb841023c6ff3f0817"
        }}>{`ab3fe9f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1522-2020-09-09"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.52.1...v1.52.2"
      }}>{`1.52.2`}</a>{` (2020-09-09)`}</h2>
    <h3 {...{
      "id": "bug-fixes-7"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`skeleton:`}</strong>{` Correct proptypes for height and width (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7a5958307bb51561e2131526f8d2170acfaba6de"
        }}>{`7a59583`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "performance-improvements"
    }}>{`Performance Improvements`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`fonts:`}</strong>{` Remove font files (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/70cb7ae2094bd86e4e2e999861341974be532e16"
        }}>{`70cb7ae`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1521-2020-09-03"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.52.0...v1.52.1"
      }}>{`1.52.1`}</a>{` (2020-09-03)`}</h2>
    <h3 {...{
      "id": "bug-fixes-8"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` Resolve unintended prop-spreading on RN component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/688254214665bca5b1dbe86c2b945fd1cb3f82ff"
        }}>{`6882542`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1520-2020-09-02"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.51.1...v1.52.0"
      }}>{`1.52.0`}</a>{` (2020-09-02)`}</h1>
    <h3 {...{
      "id": "bug-fixes-9"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`animations:`}</strong>{` set useNativeDriver to false on all animations (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/72bb165734273d4e999d7b42815dffae1070cf2e"
        }}>{`72bb165`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-3"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` Add flex props. Replace flexDirection with orientation. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/363c7de5bd26744cb063086220a3f6bc387e4b28"
        }}>{`363c7de`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`carousel:`}</strong>{` Use spacing tokens from core (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/2c3347d40981c81022a654878af955210787b1e2"
        }}>{`2c3347d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Carousel:`}</strong>{` Enable overflow on iOS (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/212795614182a18174bdf1acdd3a7357a51c607b"
        }}>{`2127956`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`core:`}</strong>{` Add Android elevation to boxShadow (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/74441b8c2973e6b053488b6435d0e0293be8b440"
        }}>{`74441b8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add Building and Person icons (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b7a9330151925908b7500d5eb94127f1aa2fb08f"
        }}>{`b7a9330`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`text:`}</strong>{` Add align prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/739d5b21aaabb1a52460411b4266429e6839614b"
        }}>{`739d5b2`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1511-2020-08-19"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.51.0...v1.51.1"
      }}>{`1.51.1`}</a>{` (2020-08-19)`}</h2>
    <h3 {...{
      "id": "bug-fixes-10"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`carousel:`}</strong>{`  set useNativeDriver to false in animated.event (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ca21d4f9a34bdf62ea25ff36e067a245fcc4e9b7"
        }}>{`ca21d4f`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1510-2020-08-17"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.50.1...v1.51.0"
      }}>{`1.51.0`}</a>{` (2020-08-17)`}</h1>
    <h3 {...{
      "id": "bug-fixes-11"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`carousel:`}</strong>{` Enable Native Driver for Animation (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/cc8ee86b2567dc7e793f7f08ab8cc6b60a0149a2"
        }}>{`cc8ee86`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-4"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`carousel:`}</strong>{` expose onScroll event and activeItem index (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/24842a5d1d45fb439fc09f7789c2b63219148c82"
        }}>{`24842a5`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1501-2020-07-27"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.50.0...v1.50.1"
      }}>{`1.50.1`}</a>{` (2020-07-27)`}</h2>
    <h3 {...{
      "id": "bug-fixes-12"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`KeyboardAwareScrollView:`}</strong>{` Remove dependency on RN4BottomTabBar (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/52147730e1be3d4d3fb2a4ced2755521c2308880"
        }}>{`5214773`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1500-2020-07-27"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.49.1...v1.50.0"
      }}>{`1.50.0`}</a>{` (2020-07-27)`}</h1>
    <h3 {...{
      "id": "bug-fixes-13"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`RN4BottomTabBar:`}</strong>{` Rename containing folder (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9a6a7a46dd680714546119c65ba99b802dcb8f16"
        }}>{`9a6a7a4`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-5"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`RN4BottomTabBar:`}</strong>{` Fix crash due to missing react-navigation-v4 dependency (see docs) (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/84effb78322720605974dbccee47621a456f14db"
        }}>{`84effb7`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1491-2020-07-27"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.49.0...v1.49.1"
      }}>{`1.49.1`}</a>{` (2020-07-27)`}</h2>
    <h3 {...{
      "id": "bug-fixes-14"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`slideUpModal:`}</strong>{` Add onModalHide callback (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/57751f11d3226c6c3eb9407ff62b70f17d13ea63"
        }}>{`57751f1`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1490-2020-07-27"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.48.1...v1.49.0"
      }}>{`1.49.0`}</a>{` (2020-07-27)`}</h1>
    <h3 {...{
      "id": "features-6"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Screen:`}</strong>{` Add Screen and AppWrapper. Export SafeAreaView (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/82bc2f11e95d053cd08e1d304320987b33f9af1d"
        }}>{`82bc2f1`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1481-2020-07-24"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.48.0...v1.48.1"
      }}>{`1.48.1`}</a>{` (2020-07-24)`}</h2>
    <h3 {...{
      "id": "bug-fixes-15"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`date-picker:`}</strong>{` Move off Nathan's fork of react-native-calendars (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/cb85aaba4ce698749bea6f2b134e96873726ffb5"
        }}>{`cb85aab`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`svg:`}</strong>{` Use React Native SVG v12. Upgrade Expo. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3e9e3685069459922b7b8eb4e54b275d23a97a48"
        }}>{`3e9e368`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1480-2020-07-13"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.47.0...v1.48.0"
      }}>{`1.48.0`}</a>{` (2020-07-13)`}</h1>
    <h3 {...{
      "id": "features-7"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SwitchRow:`}</strong>{` switch row can be disabled (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/8b0299758639072da1602d1c86ed2ff0b8ffd5f5"
        }}>{`8b02997`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1470-2020-06-30"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.46.0...v1.47.0"
      }}>{`1.47.0`}</a>{` (2020-06-30)`}</h1>
    <h3 {...{
      "id": "features-8"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` allow setting of the currently visible month (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d74b5f668221679e7d6f4d676b4794935c1cfcd6"
        }}>{`d74b5f6`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1460-2020-06-22"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.45.0...v1.46.0"
      }}>{`1.46.0`}</a>{` (2020-06-22)`}</h1>
    <h3 {...{
      "id": "bug-fixes-16"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DataRow:`}</strong>{` Update help icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/42855614f7876e31e1492b3652018aad299d0287"
        }}>{`4285561`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-9"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Rename help to support, add helpFilled (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3bbf0422c7a5cd191ba7ee1785a82ef63565054f"
        }}>{`3bbf042`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputWithValidation:`}</strong>{` Add support for onBlur and onFocus (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/8f56b686fc8afce0751db27a3922a2c63f1e14f7"
        }}>{`8f56b68`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1450-2020-06-15"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.44.4...v1.45.0"
      }}>{`1.45.0`}</a>{` (2020-06-15)`}</h1>
    <h3 {...{
      "id": "features-10"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`svg:`}</strong>{` Downgrade rn-svg to v11 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f868b3f22474e6ac458f44d1cb68ed38d8e27f75"
        }}>{`f868b3f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1444-2020-06-01"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.44.3...v1.44.4"
      }}>{`1.44.4`}</a>{` (2020-06-01)`}</h2>
    <h3 {...{
      "id": "bug-fixes-17"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DateRangePicker:`}</strong>{` fix crash when clearing start date (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e61f2f45a10b8c57859cc8327315665a36d44968"
        }}>{`e61f2f4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1443-2020-05-25"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.44.2...v1.44.3"
      }}>{`1.44.3`}</a>{` (2020-05-25)`}</h2>
    <h3 {...{
      "id": "bug-fixes-18"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DateSelect:`}</strong>{` fix invalid time error when date select is dismissed on android (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/6a074dedadc257fed840f079e8ed052738fbc1d5"
        }}>{`6a074de`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1442-2020-05-07"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.44.1...v1.44.2"
      }}>{`1.44.2`}</a>{` (2020-05-07)`}</h2>
    <h3 {...{
      "id": "bug-fixes-19"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` Force label to only use one line (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5282427f8cb964657f66ef38ec5b843ea41a8606"
        }}>{`5282427`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1441-2020-05-01"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.44.0...v1.44.1"
      }}>{`1.44.1`}</a>{` (2020-05-01)`}</h2>
    <h3 {...{
      "id": "bug-fixes-20"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DateRangePicker:`}</strong>{` Can't set an end date before start date (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/2bdc9ad1a277b066037ae119a355cce1cdab5601"
        }}>{`2bdc9ad`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`helpers:`}</strong>{` Export helper functions (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ba7760738b4e61160a88ea374eeee0e028107cad"
        }}>{`ba77607`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PinCodeInput:`}</strong>{` Allows user to refocus input (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/33b9cd89d6483da4ceb0e5a61eaf5b3ff271a37d"
        }}>{`33b9cd8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` Support Android back button to close (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/6d2ed1cee21611c5290d9131ca3698d82525f3dd"
        }}>{`6d2ed1c`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1440-2020-04-30"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.43.0...v1.44.0"
      }}>{`1.44.0`}</a>{` (2020-04-30)`}</h1>
    <h3 {...{
      "id": "bug-fixes-21"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` Corrected proptypes (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/bfa51bc7ddcb202dabe93cbe70092abc9b06e201"
        }}>{`bfa51bc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` Value is shown with correct decimal places (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d2b31eebfbc9daf6f673d6a8eb74c5fbace57fe4"
        }}>{`d2b31ee`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Start week at Monday (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9fce7f289fdbe702a0df9acb9f6c460e3e7f6c50"
        }}>{`9fce7f2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-11"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` maximumDecimalPlaces API (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/429dce400c73df50ed06f831da68a477f3845cfd"
        }}>{`429dce4`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1430-2020-04-29"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.42.0...v1.43.0"
      }}>{`1.43.0`}</a>{` (2020-04-29)`}</h1>
    <h3 {...{
      "id": "features-12"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` Support more than 2 decimal places (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/416967f9886b8e887dfe6f0986fd3c75fd369514"
        }}>{`416967f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DateSelect:`}</strong>{` Use native date picker (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/87d4c9caaf29187ae4d3fab8a5bfc07db775f987"
        }}>{`87d4c9c`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1420-2020-04-28"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.41.2...v1.42.0"
      }}>{`1.42.0`}</a>{` (2020-04-28)`}</h1>
    <h3 {...{
      "id": "bug-fixes-22"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` Correct background default prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e0c3bf9760efb1bb5558a3db75d7adce9146b82e"
        }}>{`e0c3bf9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PayItem:`}</strong>{` Resolve colliding text labels `}<a parentName="li" {...{
          "href": "%5B3b31f1d%5D(http://bitbucket.org/reckonhq/design-system/commits/3b31f1dd59fa9e4272e70d39a0fb881aa021325b)"
        }}>{`MOBILE-1108`}</a></li>
      <li parentName="ul"><strong parentName="li">{`PayRunListItem:`}</strong>{` Ensure tertiary text line is only shown if defined. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/4ff533e5d84204d815dfe4a2d08e4645b18eb6b9"
        }}>{`4ff533e`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-13"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` Support 'hint' (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e6ec6096489b633193b0e1d55c14513b29da7beb"
        }}>{`e6ec609`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` Add 2.5x text tokens (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ac0586e0aa5d57ebef613b3d6e82003bc0ddf61c"
        }}>{`ac0586e`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1412-2020-04-22"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.41.1...v1.41.2"
      }}>{`1.41.2`}</a>{` (2020-04-22)`}</h2>
    <h3 {...{
      "id": "bug-fixes-23"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` Solve crash by renaming direction prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/de3a3aa16dca983e0b148474d867e77f79ff8be6"
        }}>{`de3a3aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Icons:`}</strong>{` Add more arrow icons (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/2c2a22ccc8ec02fe85e34e7d4e0dad1437248df5"
        }}>{`2c2a22c`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1411-2020-04-19"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.41.0...v1.41.1"
      }}>{`1.41.1`}</a>{` (2020-04-19)`}</h2>
    <h3 {...{
      "id": "bug-fixes-24"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Style prop affects outer container (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/98a243b5973654e71b87923a6a39ad4535191885"
        }}>{`98a243b`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1410-2020-04-17"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.40.0...v1.41.0"
      }}>{`1.41.0`}</a>{` (2020-04-17)`}</h1>
    <h3 {...{
      "id": "features-14"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Avatar:`}</strong>{` Support image avatars (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/faf6a05b032b1857525caaa91eb29fc7bffc786e"
        }}>{`faf6a05`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` Create Box component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/729a3d386090350fcac6519f62978e22dc503f30"
        }}>{`729a3d3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` Add utility props. Dropped 'noInset' prop. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/dbe5dbae3594e7c7df09b5bfc9ac1b1e7080de6b"
        }}>{`dbe5dba`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` Add square and circle icon types. Remove IconBadge. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/dc1f4e45b256deb4547d8f8bda4b87da61aa927e"
        }}>{`dc1f4e4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PillBar:`}</strong>{` Create PillBar component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/bbd093a7429fbe0b6ad0894333d479e159654314"
        }}>{`bbd093a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SafeAreaWrapper:`}</strong>{` Choose background color (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/cb16c3c08f5999d53e73eb24276897255eaee800"
        }}>{`cb16c3c`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1400-2020-04-14"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.39.0...v1.40.0"
      }}>{`1.40.0`}</a>{` (2020-04-14)`}</h1>
    <h3 {...{
      "id": "bug-fixes-25"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Header:`}</strong>{` Support wide title when no buttons are present (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/05cdb1e515e880ae819daf35d00bc84e6c625a58"
        }}>{`05cdb1e`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-15"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`core:`}</strong>{` remove deprecated tokens (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0eed2202054420e9457af2bd37d4691cfaced53f"
        }}>{`0eed220`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` Add color prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b14a82ee97ba34e68d00b3b76661c7e58ed6e42e"
        }}>{`b14a82e`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1390-2020-04-07"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.38.2...v1.39.0"
      }}>{`1.39.0`}</a>{` (2020-04-07)`}</h1>
    <h3 {...{
      "id": "bug-fixes-26"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix Docz deployment, add docz test in pipeline, upgrade dependencies. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/140c6b3399b15dcbdacc19b96b2f25148a1323a9"
        }}>{`140c6b3`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-16"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Remove default scroll-range limit (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/a5b1f4c506fc078561cfedc7802007bf45862100"
        }}>{`a5b1f4c`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1382-2020-03-30"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.38.1...v1.38.2"
      }}>{`1.38.2`}</a>{` (2020-03-30)`}</h2>
    <h3 {...{
      "id": "bug-fixes-27"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DateSelect:`}</strong>{` Fix bug with setting day on Android (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/05d2dd8"
        }}>{`05d2dd8`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1381-2020-03-26"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.38.0...v1.38.1"
      }}>{`1.38.1`}</a>{` (2020-03-26)`}</h2>
    <h3 {...{
      "id": "bug-fixes-28"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` isRequired rule supports 0 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f05f6ed"
        }}>{`f05f6ed`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1380-2020-03-25"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.37.2...v1.38.0"
      }}>{`1.38.0`}</a>{` (2020-03-25)`}</h1>
    <h3 {...{
      "id": "bug-fixes-29"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add chat icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/12cd4ee"
        }}>{`12cd4ee`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-17"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SwitchRow:`}</strong>{` Support React elements in body prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/75907c5"
        }}>{`75907c5`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1372-2020-03-18"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.37.1...v1.37.2"
      }}>{`1.37.2`}</a>{` (2020-03-18)`}</h2>
    <h3 {...{
      "id": "bug-fixes-30"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Change 'pay date' in footer to 'selected date' (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5e5f92b"
        }}>{`5e5f92b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DateRangePicker:`}</strong>{` Ensure component mounts with start value (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7fd7fdc"
        }}>{`7fd7fdc`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1371-2020-03-16"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.37.0...v1.37.1"
      }}>{`1.37.1`}</a>{` (2020-03-16)`}</h2>
    <h3 {...{
      "id": "bug-fixes-31"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` 'Clear' sets value to undefined (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b94a4b6"
        }}>{`b94a4b6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` Support 0 and undefined values`}</li>
      <li parentName="ul"><strong parentName="li">{`PercentageInput:`}</strong>{` Support 0 and undefined values`}</li>
    </ul>
    <h1 {...{
      "id": "1370-2020-03-10"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.36.3...v1.37.0"
      }}>{`1.37.0`}</a>{` (2020-03-10)`}</h1>
    <h3 {...{
      "id": "features-18"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Modify touched onChange (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ce93d97"
        }}>{`ce93d97`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1363-2020-03-04"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.36.2...v1.36.3"
      }}>{`1.36.3`}</a>{` (2020-03-04)`}</h2>
    <h3 {...{
      "id": "bug-fixes-32"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` Ensure field is blurred before allowing to submit. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d7ef3bd"
        }}>{`d7ef3bd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Add optional title prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/76f8f6a"
        }}>{`76f8f6a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DateSelect:`}</strong>{` Correct spelling of december (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/1f04f16"
        }}>{`1f04f16`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1362-2020-03-03"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.36.1...v1.36.2"
      }}>{`1.36.2`}</a>{` (2020-03-03)`}</h2>
    <h3 {...{
      "id": "bug-fixes-33"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Add support for 'touched' (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/47a845f"
        }}>{`47a845f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DateSelect:`}</strong>{` Add support for 'touched' (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d099b02"
        }}>{`d099b02`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedControl:`}</strong>{` Add support for 'touched' (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7820e3d"
        }}>{`7820e3d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` Add support for 'touched' (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9416ce1"
        }}>{`9416ce1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SwitchRow:`}</strong>{` Add support for 'touched' (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5f5f765"
        }}>{`5f5f765`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1361-2020-02-26"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.36.0...v1.36.1"
      }}>{`1.36.1`}</a>{` (2020-02-26)`}</h2>
    <h3 {...{
      "id": "bug-fixes-34"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Reveal 'isValid' constant (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/dba7b8f"
        }}>{`dba7b8f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NumberInput:`}</strong>{` Add default placeholder (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ab9ed62"
        }}>{`ab9ed62`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NumberInput:`}</strong>{` Ensure 0" is supported (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/28f159e"
        }}>{`28f159e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NumberInput:`}</strong>{` Ensure field is blurred before allowing to submit. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f219f42"
        }}>{`f219f42`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1360-2020-02-24"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.35.0...v1.36.0"
      }}>{`1.36.0`}</a>{` (2020-02-24)`}</h1>
    <h3 {...{
      "id": "bug-fixes-35"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SlideUpSheet:`}</strong>{` Fixed issue with addPadding where bottom was being cut off (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ed36ef1"
        }}>{`ed36ef1`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-19"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ErrorMessage:`}</strong>{` Make 'try again' button generic. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/350e9cf"
        }}>{`350e9cf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SlideUpModal:`}</strong>{` Add 'addPadding' prop to add default spacing around content (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/6483314"
        }}>{`6483314`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1350-2020-02-18"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.34.1...v1.35.0"
      }}>{`1.35.0`}</a>{` (2020-02-18)`}</h1>
    <h3 {...{
      "id": "bug-fixes-36"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`EmployeeListItem:`}</strong>{` Truncate long strings (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7d78360"
        }}>{`7d78360`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SlideUpModal:`}</strong>{` Prevent unexpected scrolling behaviour with iOS keyboard (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5cfe429"
        }}>{`5cfe429`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-20"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Deprecate BackArrow (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5c65ad5"
        }}>{`5c65ad5`}</a>{`)`}</li>
      <li parentName="ul">{`Deprecate CloseButton (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/bed58d3"
        }}>{`bed58d3`}</a>{`)`}</li>
      <li parentName="ul">{`Deprecate Modal and ModalContent (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d304d4c"
        }}>{`d304d4c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`currencyInput:`}</strong>{` Add default placeholder prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c4184c0"
        }}>{`c4184c0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NumberInput:`}</strong>{` Add hint button (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/47fe103"
        }}>{`47fe103`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1341-2020-02-14"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.34.0...v1.34.1"
      }}>{`1.34.1`}</a>{` (2020-02-14)`}</h2>
    <h3 {...{
      "id": "bug-fixes-37"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`sheet:`}</strong>{` Remove fixed height from Sheet (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0726741"
        }}>{`0726741`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SlideUpModal:`}</strong>{` Build-in platform specific ScrollViews (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/aa8e551"
        }}>{`aa8e551`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SlideUpModal:`}</strong>{` Ensure AutoHeight modal doesn't collapse (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/661701c"
        }}>{`661701c`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1340-2020-02-12"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.33.1...v1.34.0"
      }}>{`1.34.0`}</a>{` (2020-02-12)`}</h1>
    <h3 {...{
      "id": "features-21"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DateSelect:`}</strong>{` Create `}<inlineCode parentName="li">{`DateSelect`}</inlineCode>{` component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5fe5b94"
        }}>{`5fe5b94`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`errorMessage:`}</strong>{` Add `}<inlineCode parentName="li">{`ErrorMessage`}</inlineCode>{` and `}<inlineCode parentName="li">{`ErrorScreen`}</inlineCode>{` components (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0d0bed0"
        }}>{`0d0bed0`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1331-2020-02-06"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.33.0...v1.33.1"
      }}>{`1.33.1`}</a>{` (2020-02-06)`}</h2>
    <h3 {...{
      "id": "bug-fixes-38"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`No longer resolves RN dependency to expo fork. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/787ded9"
        }}>{`787ded9`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1330-2020-02-06"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.32.0...v1.33.0"
      }}>{`1.33.0`}</a>{` (2020-02-06)`}</h1>
    <h3 {...{
      "id": "bug-fixes-39"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Ensure onBlur waits for any formatting before returning payload (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9893dc7"
        }}>{`9893dc7`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-22"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`carousel:`}</strong>{` add pagination color prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c3f700f"
        }}>{`c3f700f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Add onBlur prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/240540d"
        }}>{`240540d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add arrowLeftFilled icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3f80e74"
        }}>{`3f80e74`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add arrowRightFilled icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d6caa2e"
        }}>{`d6caa2e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add energyFilled icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/418cac6"
        }}>{`418cac6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add energyOutline icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/98f3690"
        }}>{`98f3690`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add newBadge icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3d9ea8e"
        }}>{`3d9ea8e`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1320-2020-02-03"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.31.0...v1.32.0"
      }}>{`1.32.0`}</a>{` (2020-02-03)`}</h1>
    <h3 {...{
      "id": "bug-fixes-40"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Carousel:`}</strong>{` Fix horizontal spacing on Android (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/06a28a3"
        }}>{`06a28a3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Removed flash of black when tapped (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/34911a4"
        }}>{`34911a4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`KeyboardAwareScrollView:`}</strong>{` Attempt to fix unexpected spacing (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/011a349"
        }}>{`011a349`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-23"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Header:`}</strong>{` Only render children containers if children exist (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e4e5aae"
        }}>{`e4e5aae`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1310-2020-01-14"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.30.0...v1.31.0"
      }}>{`1.31.0`}</a>{` (2020-01-14)`}</h1>
    <h3 {...{
      "id": "bug-fixes-41"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` Resolve eslint (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/a2adef2"
        }}>{`a2adef2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` Updated appearance of Header (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/edd4d0e"
        }}>{`edd4d0e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Carousel:`}</strong>{` Ensure scrollview is full-width (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/6aaff40"
        }}>{`6aaff40`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`core:`}</strong>{` Updated boxShadow to match figma (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d2c674e"
        }}>{`d2c674e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Footer:`}</strong>{` Resolve content jittering (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7fae399"
        }}>{`7fae399`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Header:`}</strong>{` Ensures custom titles align to center (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/afbaf10"
        }}>{`afbaf10`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PayRunListItem:`}</strong>{` Fix issue with pending variant (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/68b6e09"
        }}>{`68b6e09`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PinCodeInput:`}</strong>{` Centered text (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9b926a0"
        }}>{`9b926a0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ProgressBar:`}</strong>{` Actually export it :/ (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/1cd2980"
        }}>{`1cd2980`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-24"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`badge:`}</strong>{` Support icons (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/1c9d62e"
        }}>{`1c9d62e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PayRunListItem:`}</strong>{` Add isArrowHidden prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e66c027"
        }}>{`e66c027`}</a>{`)`}</li>
      <li parentName="ul">{`Add EmployeeListItem component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/00eb52c"
        }}>{`00eb52c`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1300-2020-01-09"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.29.1...v1.30.0"
      }}>{`1.30.0`}</a>{` (2020-01-09)`}</h1>
    <h3 {...{
      "id": "features-25"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`useActionSheet:`}</strong>{` Add useActionSheetWithArguments (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c2b66bb"
        }}>{`c2b66bb`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1291-2020-01-08"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.29.0...v1.29.1"
      }}>{`1.29.1`}</a>{` (2020-01-08)`}</h2>
    <h3 {...{
      "id": "bug-fixes-42"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` Adjust minWidth (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/69d3a70"
        }}>{`69d3a70`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Carousel:`}</strong>{` Removed unwanted flex property (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/62c6e88"
        }}>{`62c6e88`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PayRunListItem:`}</strong>{` Add case for 'done' status (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/a9ef5d5"
        }}>{`a9ef5d5`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1290-2020-01-07"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.28.0...v1.29.0"
      }}>{`1.29.0`}</a>{` (2020-01-07)`}</h1>
    <h3 {...{
      "id": "bug-fixes-43"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Adjust scroll range (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/80b498d"
        }}>{`80b498d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` Removed flex rule (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d1b7564"
        }}>{`d1b7564`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SwitchRow:`}</strong>{` corrected Flex rules (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e744d94"
        }}>{`e744d94`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-26"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DataRow:`}</strong>{` Add onHintPress prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ab49dfe"
        }}>{`ab49dfe`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PayRunListItem:`}</strong>{` Create PayRunListItem component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3f0c484"
        }}>{`3f0c484`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PopupMenu:`}</strong>{` ActionSheet hook refactor (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/78a9c79"
        }}>{`78a9c79`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1280-2020-01-02"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.27.0...v1.28.0"
      }}>{`1.28.0`}</a>{` (2020-01-02)`}</h1>
    <h3 {...{
      "id": "features-27"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Moved data formatting logic to form component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/bb6b18d"
        }}>{`bb6b18d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Input:`}</strong>{` Add hint to input (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0726a20"
        }}>{`0726a20`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PageHint:`}</strong>{` Add support for formatted text (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0b3853d"
        }}>{`0b3853d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` Add isDisabled support (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/03c6483"
        }}>{`03c6483`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1270-2019-12-10"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.26.0...v1.27.0"
      }}>{`1.27.0`}</a>{` (2019-12-10)`}</h1>
    <h3 {...{
      "id": "bug-fixes-44"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` Replaced text button with icon button (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e73dd13"
        }}>{`e73dd13`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Fix bug where isDisabled appearance wasn't showing (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/409cd13"
        }}>{`409cd13`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` onSubmit is no longer required (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9474291"
        }}>{`9474291`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-28"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Input can return 00:00Z ISO string (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/790c1ca"
        }}>{`790c1ca`}</a>{`)`}</li>
      <li parentName="ul">{`Redesigned isElevated bars (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3285b51"
        }}>{`3285b51`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1260-2019-12-05"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.25.2...v1.26.0"
      }}>{`1.26.0`}</a>{` (2019-12-05)`}</h1>
    <h3 {...{
      "id": "bug-fixes-45"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`RadioButton:`}</strong>{` Label responds predicatably to long strings (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e4554cd"
        }}>{`e4554cd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`RadioButton:`}</strong>{` Label responds predictably to long strings (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/74082a4"
        }}>{`74082a4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` Corrects input size when empty (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7c4d46a"
        }}>{`7c4d46a`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-29"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`NumberInput:`}</strong>{` Add prefix and to NumberInput (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f050ce2"
        }}>{`f050ce2`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1252-2019-12-03"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.25.1...v1.25.2"
      }}>{`1.25.2`}</a>{` (2019-12-03)`}</h2>
    <h3 {...{
      "id": "bug-fixes-46"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Attempt to resolve unpredictable top-spacing (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/dfad211"
        }}>{`dfad211`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Set default value to undefined (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/473b708"
        }}>{`473b708`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1251-2019-11-27"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.25.0...v1.25.1"
      }}>{`1.25.1`}</a>{` (2019-11-27)`}</h2>
    <h3 {...{
      "id": "bug-fixes-47"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CircleButton:`}</strong>{` increase size of small button (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0cd6b82"
        }}>{`0cd6b82`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1250-2019-11-25"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.24.1...v1.25.0"
      }}>{`1.25.0`}</a>{` (2019-11-25)`}</h1>
    <h3 {...{
      "id": "features-30"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DatePicker:`}</strong>{` Props are consistent with other forms (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/05a4f5f"
        }}>{`05a4f5f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1241-2019-11-21"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.24.0...v1.24.1"
      }}>{`1.24.1`}</a>{` (2019-11-21)`}</h2>
    <h3 {...{
      "id": "bug-fixes-48"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`carousel:`}</strong>{` Sizes to fill available space (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b8abc9b"
        }}>{`b8abc9b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`footer:`}</strong>{` Adds empty space for iPhone X (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7ac00f3"
        }}>{`7ac00f3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` isEmail validation rule allows empty (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/fab34d7"
        }}>{`fab34d7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`select:`}</strong>{` Ensure value text does not collapse (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/a1dd12a"
        }}>{`a1dd12a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SwitchRow:`}</strong>{` body prop is no longer requred (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/729eaba"
        }}>{`729eaba`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1240-2019-11-19"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.23.0...v1.24.0"
      }}>{`1.24.0`}</a>{` (2019-11-19)`}</h1>
    <h3 {...{
      "id": "bug-fixes-49"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`text:`}</strong>{` Adjust tokens for title-1 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/be92691"
        }}>{`be92691`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-31"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`HiddenInput (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/83ad413"
        }}>{`83ad413`}</a>{`)`}</li>
      <li parentName="ul">{`NumberInput and NumberInputWithValidation (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/58ebfbb"
        }}>{`58ebfbb`}</a>{`)`}</li>
      <li parentName="ul">{`PercentageInput and PercentageInputWithValidation (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/25c5af9"
        }}>{`25c5af9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` CurrencyInputWithValidation (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/bde6328"
        }}>{`bde6328`}</a>{`)`}</li>
      <li parentName="ul">{`Rename FormInput to InputWithValidation (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ddbec0a"
        }}>{`ddbec0a`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1230-2019-11-18"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.22.0...v1.23.0"
      }}>{`1.23.0`}</a>{` (2019-11-18)`}</h1>
    <h3 {...{
      "id": "bug-fixes-50"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Removes asterisks from validation error messages (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3fbae13"
        }}>{`3fbae13`}</a>{`)`}</li>
      <li parentName="ul">{`Resolve eslint (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b2a519e"
        }}>{`b2a519e`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-32"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`carousel:`}</strong>{` Refactor and docs (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b70b388"
        }}>{`b70b388`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`currencyInput:`}</strong>{` Truncate vals to 2 dec places. return number type (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f5d98eb"
        }}>{`f5d98eb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Add help and community icons (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0c95434"
        }}>{`0c95434`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SwitchRow:`}</strong>{` withValidation, isElevated prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/fb8dd91"
        }}>{`fb8dd91`}</a>{`)`}</li>
      <li parentName="ul">{`SelectWithValidation, DatePickerWithValidation, SegmentedControlWithValidation (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/24fe9dc"
        }}>{`24fe9dc`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1220-2019-11-12"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.21.0...v1.22.0"
      }}>{`1.22.0`}</a>{` (2019-11-12)`}</h1>
    <h3 {...{
      "id": "features-33"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DataRow:`}</strong>{` Add onValuePress Prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0c10efb"
        }}>{`0c10efb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Form:`}</strong>{` Add form validation when new fields are registered (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5a44fbe"
        }}>{`5a44fbe`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1210-2019-11-07"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.20.1...v1.21.0"
      }}>{`1.21.0`}</a>{` (2019-11-07)`}</h1>
    <h3 {...{
      "id": "bug-fixes-51"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` Remove horizontal padding from link (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/36d06ea"
        }}>{`36d06ea`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` Remove horizontal padding from link button (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e265fa0"
        }}>{`e265fa0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` Replace children with label prop. Changed props. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/dba5330"
        }}>{`dba5330`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`header:`}</strong>{` Add 'hasPrimaryTitle' prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ed619d4"
        }}>{`ed619d4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`header:`}</strong>{` Adjust padding (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/adc0c2b"
        }}>{`adc0c2b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkRow:`}</strong>{` isDanger prop for destructive action (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f1de667"
        }}>{`f1de667`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`stack:`}</strong>{` Reset stack spacing tokens to base (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/a526847"
        }}>{`a526847`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`stack:`}</strong>{` Resolving 'stack' space to equal base (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ddeb3c9"
        }}>{`ddeb3c9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`text:`}</strong>{` Adjust line-height for titles (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/33abd0f"
        }}>{`33abd0f`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-34"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CircleButton:`}</strong>{` Size, type, icon props (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c73beb2"
        }}>{`c73beb2`}</a>{`)`}</li>
      <li parentName="ul">{`Create FlexGroup (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3b4017c"
        }}>{`3b4017c`}</a>{`)`}</li>
      <li parentName="ul">{`LinkRow and SwitchRow (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7c61b6e"
        }}>{`7c61b6e`}</a>{`)`}</li>
      <li parentName="ul">{`New SectionTitle component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/2a3e35e"
        }}>{`2a3e35e`}</a>{`)`}</li>
      <li parentName="ul">{`New SlideUpModal, Header and Footer (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/79e0f72"
        }}>{`79e0f72`}</a>{`)`}</li>
      <li parentName="ul">{`New SlideUpModal, Header and Footer (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/42c5f7a"
        }}>{`42c5f7a`}</a>{`)`}</li>
      <li parentName="ul">{`New title tokens (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/48d4a07"
        }}>{`48d4a07`}</a>{`)`}</li>
      <li parentName="ul">{`SlideUpModal (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ce7d4a2"
        }}>{`ce7d4a2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`avatar:`}</strong>{` Pick colour based on name (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c6753ab"
        }}>{`c6753ab`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` Changed props (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9203ac9"
        }}>{`9203ac9`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1201-2019-10-23"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.20.0...v1.20.1"
      }}>{`1.20.1`}</a>{` (2019-10-23)`}</h2>
    <h3 {...{
      "id": "bug-fixes-52"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`datePicker:`}</strong>{` update dateFormat to v2.5.1 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c31ff85"
        }}>{`c31ff85`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1200-2019-10-16"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.19.0...v1.20.0"
      }}>{`1.20.0`}</a>{` (2019-10-16)`}</h1>
    <h3 {...{
      "id": "bug-fixes-53"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`keyboard:`}</strong>{` android keyboard not dragging up (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7f37b79"
        }}>{`7f37b79`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-35"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Avatar:`}</strong>{` Add `}<inlineCode parentName="li">{`isSelected`}</inlineCode>{` prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/04cd647"
        }}>{`04cd647`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1190-2019-10-03"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.18.0...v1.19.0"
      }}>{`1.19.0`}</a>{` (2019-10-03)`}</h1>
    <h3 {...{
      "id": "bug-fixes-54"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` Adjust font weight for link button (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/59871aa"
        }}>{`59871aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tabbar:`}</strong>{` adjust font size and fix docz crashing (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c6be571"
        }}>{`c6be571`}</a>{`)`}</li>
      <li parentName="ul">{`Adjust default shadow (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d103082"
        }}>{`d103082`}</a>{`)`}</li>
      <li parentName="ul">{`Updates to PageHint (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b833d65"
        }}>{`b833d65`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-36"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add `}<inlineCode parentName="li">{`isElevated`}</inlineCode>{` to ActionRow and DataRow (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/4088947"
        }}>{`4088947`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1180-2019-09-26"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.17.0...v1.18.0"
      }}>{`1.18.0`}</a>{` (2019-09-26)`}</h1>
    <h3 {...{
      "id": "bug-fixes-55"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`PageHint:`}</strong>{` Line height to paragraph (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/eb019fd"
        }}>{`eb019fd`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-37"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`🎸 design update for pin input (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0b27f53"
        }}>{`0b27f53`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SelectableCard:`}</strong>{` Make icon size a prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3b7b4fe"
        }}>{`3b7b4fe`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1170-2019-09-20"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.16.0...v1.17.0"
      }}>{`1.17.0`}</a>{` (2019-09-20)`}</h1>
    <h3 {...{
      "id": "bug-fixes-56"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Upgrading AndroidBiometricAuth with new tokens (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c7275ac"
        }}>{`c7275ac`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`datePicker:`}</strong>{` resolve misalignment drawerHeader (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/aa0b6ba"
        }}>{`aa0b6ba`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-38"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add `}<inlineCode parentName="li">{`background.splash`}</inlineCode>{` colour (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/a50b337"
        }}>{`a50b337`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` Added minAmount form validation rule (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/83fc7ec"
        }}>{`83fc7ec`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1160-2019-09-17"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.15.1...v1.16.0"
      }}>{`1.16.0`}</a>{` (2019-09-17)`}</h1>
    <h3 {...{
      "id": "bug-fixes-57"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`datePicker:`}</strong>{` resolve misalignment drawerHeader (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5e5d4aa"
        }}>{`5e5d4aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`radioButton:`}</strong>{` misalingment text (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/932ccbd"
        }}>{`932ccbd`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-39"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`form:`}</strong>{` new validation rules (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ebaf286"
        }}>{`ebaf286`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1151-2019-09-17"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.15.0...v1.15.1"
      }}>{`1.15.1`}</a>{` (2019-09-17)`}</h2>
    <h3 {...{
      "id": "bug-fixes-58"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`added more icons (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f9e0d9a"
        }}>{`f9e0d9a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`select:`}</strong>{` resolve misalignment with inputForm (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/148464a"
        }}>{`148464a`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1150-2019-09-13"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.14.0...v1.15.0"
      }}>{`1.15.0`}</a>{` (2019-09-13)`}</h1>
    <h3 {...{
      "id": "bug-fixes-59"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`action row:`}</strong>{` font weight and colour for label. (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0877059"
        }}>{`0877059`}</a>{`)`}</li>
      <li parentName="ul">{`new color for `}<inlineCode parentName="li">{`selected`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/42cbb0c"
        }}>{`42cbb0c`}</a>{`)`}</li>
      <li parentName="ul">{`remove 'sceneTitle-3' text token (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/ea19421"
        }}>{`ea19421`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tabbar:`}</strong>{` changed statusdot handling (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3854a7b"
        }}>{`3854a7b`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-40"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add 'disconnnected' icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f93c88b"
        }}>{`f93c88b`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1140-2019-09-11"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.13.0...v1.14.0"
      }}>{`1.14.0`}</a>{` (2019-09-11)`}</h1>
    <h3 {...{
      "id": "bug-fixes-60"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Add insetVertical + horizontal exports to core (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d02e304"
        }}>{`d02e304`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`DataRow:`}</strong>{` Resolve rendering issues MOBILE-354 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/198abe8"
        }}>{`198abe8`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-41"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add 8x and 10x spacing (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e178c77"
        }}>{`e178c77`}</a>{`)`}</li>
      <li parentName="ul">{`Remove PayrunCard component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/66bd9f7"
        }}>{`66bd9f7`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1130-2019-09-09"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.12.0...v1.13.0"
      }}>{`1.13.0`}</a>{` (2019-09-09)`}</h1>
    <h3 {...{
      "id": "features-42"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`New email validation regex (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e76cfd5"
        }}>{`e76cfd5`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1120-2019-09-04"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.11.0...v1.12.0"
      }}>{`1.12.0`}</a>{` (2019-09-04)`}</h1>
    <h3 {...{
      "id": "bug-fixes-61"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`base`}{`[0]`}{` size (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/0d4e476"
        }}>{`0d4e476`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-43"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add `}<inlineCode parentName="li">{`insetHorizontal`}</inlineCode>{` & `}<inlineCode parentName="li">{`insetVertical`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/474abb8"
        }}>{`474abb8`}</a>{`)`}</li>
      <li parentName="ul">{`Add icons for bottomNavigation (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/d66fcf8"
        }}>{`d66fcf8`}</a>{`)`}</li>
      <li parentName="ul">{`Upgrade to TabBar - visual, implement statusDots (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/4582e1f"
        }}>{`4582e1f`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1110-2019-08-28"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.10.0...v1.11.0"
      }}>{`1.11.0`}</a>{` (2019-08-28)`}</h1>
    <h3 {...{
      "id": "bug-fixes-62"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`incorrect proptype func causing crash (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/1a425fc"
        }}>{`1a425fc`}</a>{`)`}</li>
      <li parentName="ul">{`removed default color from blockTitle-1 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/50ebce7"
        }}>{`50ebce7`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-44"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><Icon mdxType="Icon" /> expects a name of icon as prop ([612aa98](http://bitbucket.org/reckonhq/design-system/commits/612aa98))</li>
      <li parentName="ul">{`add stack size 1.5x (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/1fe6e7e"
        }}>{`1fe6e7e`}</a>{`)`}</li>
      <li parentName="ul">{`Archived icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f4f69f1"
        }}>{`f4f69f1`}</a>{`)`}</li>
      <li parentName="ul">{`new success state for button (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e6bc187"
        }}>{`e6bc187`}</a>{`)`}</li>
      <li parentName="ul">{`visual changes to Avatar MOBILE-333 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/8a2eca2"
        }}>{`8a2eca2`}</a>{`)`}</li>
      <li parentName="ul">{`visual changes to badge MOBILE-334 (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/6acc996"
        }}>{`6acc996`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "1100-2019-08-14"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.9.2...v1.10.0"
      }}>{`1.10.0`}</a>{` (2019-08-14)`}</h1>
    <h3 {...{
      "id": "bug-fixes-63"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CurrencyInput:`}</strong>{` 'onChange' prop (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/425e4e5"
        }}>{`425e4e5`}</a>{`)`}</li>
      <li parentName="ul">{`Improvements to PayItem (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/9f4f1b6"
        }}>{`9f4f1b6`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-45"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`destructive button (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/4a47c01"
        }}>{`4a47c01`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "192-2019-08-12"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.9.1...v1.9.2"
      }}>{`1.9.2`}</a>{` (2019-08-12)`}</h2>
    <h3 {...{
      "id": "bug-fixes-64"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`add gesture handler package (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/8a619b3"
        }}>{`8a619b3`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "191-2019-08-09"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.9.0...v1.9.1"
      }}>{`1.9.1`}</a>{` (2019-08-09)`}</h2>
    <h3 {...{
      "id": "bug-fixes-65"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Removing RN Gesture Handler (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/fdaf6d4"
        }}>{`fdaf6d4`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "190-2019-08-08"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.8.3...v1.9.0"
      }}>{`1.9.0`}</a>{` (2019-08-08)`}</h1>
    <h3 {...{
      "id": "bug-fixes-66"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Adding boolean support in proptypes (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/790cbb6"
        }}>{`790cbb6`}</a>{`)`}</li>
      <li parentName="ul">{`Check if key in error obj is defined (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/c8d9236"
        }}>{`c8d9236`}</a>{`)`}</li>
      <li parentName="ul">{`Corrected headline type in Select (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/7aad19b"
        }}>{`7aad19b`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-46"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add Label to Segmented Control (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/8a754b4"
        }}>{`8a754b4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "183-2019-08-08"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.8.2...v1.8.3"
      }}>{`1.8.3`}</a>{` (2019-08-08)`}</h2>
    <h3 {...{
      "id": "chores"
    }}>{`Chores`}</h3>
    <ul>
      <li parentName="ul">{`Update Epxo SDK from v33 to v34 (`}<a parentName="li" {...{
          "href": "https://bitbucket.org/reckonhq/design-system/commits/8d7ba65"
        }}>{`8d7ba65`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "182-2019-08-07"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.8.1...v1.8.2"
      }}>{`1.8.2`}</a>{` (2019-08-07)`}</h2>
    <h3 {...{
      "id": "bug-fixes-67"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Check if key in error obj is defined (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/3f20cd2"
        }}>{`3f20cd2`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "181-2019-08-06"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.8.0...v1.8.1"
      }}>{`1.8.1`}</a>{` (2019-08-06)`}</h2>
    <h3 {...{
      "id": "bug-fixes-68"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Adjusted dollar icon (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/91de497"
        }}>{`91de497`}</a>{`)`}</li>
      <li parentName="ul">{`Corrected proptypes for Select (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/6f54037"
        }}>{`6f54037`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "180-2019-08-05"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.7.0...v1.8.0"
      }}>{`1.8.0`}</a>{` (2019-08-05)`}</h1>
    <h3 {...{
      "id": "features-47"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add dollar and delete icons (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/59df065"
        }}>{`59df065`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "170-2019-08-02"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.6.2...v1.7.0"
      }}>{`1.7.0`}</a>{` (2019-08-02)`}</h1>
    <h3 {...{
      "id": "bug-fixes-69"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Applying IconBadge to PayItem component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/12d9b55"
        }}>{`12d9b55`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-48"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Creating IconBadge component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/13bf137"
        }}>{`13bf137`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "162-2019-08-01"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.6.1...v1.6.2"
      }}>{`1.6.2`}</a>{` (2019-08-01)`}</h2>
    <h3 {...{
      "id": "bug-fixes-70"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`corrected borderColor in BottomButton (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/1f47200"
        }}>{`1f47200`}</a>{`)`}</li>
      <li parentName="ul">{`Corrected styles for DataRow (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/370cb56"
        }}>{`370cb56`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "161-2019-08-01"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.6.0...v1.6.1"
      }}>{`1.6.1`}</a>{` (2019-08-01)`}</h2>
    <h3 {...{
      "id": "bug-fixes-71"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Removed incorrect import (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b07929e"
        }}>{`b07929e`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "160-2019-07-31"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.5.0...v1.6.0"
      }}>{`1.6.0`}</a>{` (2019-07-31)`}</h1>
    <h3 {...{
      "id": "features-49"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Created SegmentedControl component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/2b04c6e"
        }}>{`2b04c6e`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "150-2019-07-31"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.4.0...v1.5.0"
      }}>{`1.5.0`}</a>{` (2019-07-31)`}</h1>
    <h3 {...{
      "id": "features-50"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`PayItem Component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/05688c9"
        }}>{`05688c9`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "140-2019-07-31"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.3.2...v1.4.0"
      }}>{`1.4.0`}</a>{` (2019-07-31)`}</h1>
    <h3 {...{
      "id": "features-51"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ProgressBar component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/6ab2852"
        }}>{`6ab2852`}</a>{`)`}</li>
      <li parentName="ul">{`ProgressBar component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b6ad8f4"
        }}>{`b6ad8f4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "132-2019-07-30"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.3.1...v1.3.2"
      }}>{`1.3.2`}</a>{` (2019-07-30)`}</h2>
    <h3 {...{
      "id": "bug-fixes-72"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Exporting FieldValidator (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/f1bb15a"
        }}>{`f1bb15a`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "131-2019-07-30"
    }}><a parentName="h2" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.3.0...v1.3.1"
      }}>{`1.3.1`}</a>{` (2019-07-30)`}</h2>
    <h3 {...{
      "id": "bug-fixes-73"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Resolved demo app crashes on load (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/5a2ed3f"
        }}>{`5a2ed3f`}</a>{`)`}</li>
      <li parentName="ul">{`Resolved demo app crashes on load (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/b116fe9"
        }}>{`b116fe9`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "130-2019-07-29"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.2.0...v1.3.0"
      }}>{`1.3.0`}</a>{` (2019-07-29)`}</h1>
    <h3 {...{
      "id": "features-52"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Add optional Header to Card (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/e249e9c"
        }}>{`e249e9c`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "120-2019-07-26"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.1.0...v1.2.0"
      }}>{`1.2.0`}</a>{` (2019-07-26)`}</h1>
    <h3 {...{
      "id": "features-53"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Create PageHint component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/08102e9"
        }}>{`08102e9`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "110-2019-07-25"
    }}><a parentName="h1" {...{
        "href": "http://bitbucket.org/reckonhq/design-system/compare/v1.0.0...v1.1.0"
      }}>{`1.1.0`}</a>{` (2019-07-25)`}</h1>
    <h3 {...{
      "id": "features-54"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`New Select Component (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/dafd0c0"
        }}>{`dafd0c0`}</a>{`)`}</li>
    </ul>
    <h1 {...{
      "id": "100-2019-07-24"
    }}>{`1.0.0 (2019-07-24)`}</h1>
    <h3 {...{
      "id": "features-55"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ci:`}</strong>{` document versioning scheme (`}<a parentName="li" {...{
          "href": "http://bitbucket.org/reckonhq/design-system/commits/1355ae5"
        }}>{`1355ae5`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      