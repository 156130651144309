import { Playground, Props } from "docz";
import Box from "../../../../../src/components/Box";
import Text from "../../../../../src/components/Text";
import Avatar from "../../../../../src/components/Avatar";
import { Stack } from "../../../../../src/components/Stack/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Box,
  Text,
  Avatar,
  Stack,
  React
};