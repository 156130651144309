import React, { Component } from "react";
import { bool, func, string, object, number, oneOf } from "prop-types";
import { View } from "react-native";
import { CalendarList } from "react-native-calendars";
import { format as formatDate } from "date-fns";

import Sheet from "../../Sheet";

import {
  PickerInputField,
  DaysRow,
  CalendarHeader,
  CalendarFooter
} from "./common";
import calendarTheme from "./calendarTheme";

export default class DatePicker extends Component {
  state = {
    isVisible: false
  };

  render() {
    const {
      isDisabled,
      onChange,
      placeholder,
      style,
      format,
      label,
      maxDate,
      minDate,
      title,
      value,
      futureScrollRange,
      pastScrollRange,
      disableFutureDates,
      current,
      testID
    } = this.props;

    const today = formatDate(new Date(), "yyyy-MM-dd");

    // Because timezones are hard
    let exactDate;
    let valueAsDateString = value;

    if (value) {
      // if value is an ISO string, we only provide the date portion.
      valueAsDateString = value.split("T")[0];
      const [year, month, day] = valueAsDateString.split(new RegExp("[-T]"));
      exactDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

    return (
      <>
        <PickerInputField
          isDisabled={isDisabled}
          label={label}
          onPress={() => this.setState({ isVisible: true })}
          hasValue={valueAsDateString ? true : false}
          value={
            valueAsDateString
              ? formatDate(exactDate, "d MMM yyyy")
              : placeholder
          }
          style={style}
          testID={testID}
        />

        <Sheet
          visible={this.state.isVisible}
          onHide={() => this.setState({ isVisible: false })}
          testID="datePickerSheet"
        >
          <CalendarHeader
            title={title || label}
            clearable={valueAsDateString ? true : false}
            handleClear={this.handleClear}
          />

          <DaysRow />

          <View
            style={{ flex: 1, overflow: "hidden" }}
            testID="datePickerCalenderList"
          >
            <CalendarList
              minDate={minDate}
              maxDate={disableFutureDates ? today : maxDate}
              futureScrollRange={futureScrollRange}
              pastScrollRange={pastScrollRange}
              hideDayNames={true}
              current={value ? valueAsDateString : current}
              theme={calendarTheme}
              markedDates={this.handleMarkedDate()}
              firstDay={1} // sets to Monday
              onDayPress={day => {
                if (format === "ISO") {
                  onChange(`${day.dateString}T00:00:00.000Z`);
                } else {
                  onChange(day.dateString);
                }
              }}
            />
          </View>

          <CalendarFooter
            title="Selected date"
            hasValue={valueAsDateString ? true : false}
            selectedValue={
              valueAsDateString
                ? formatDate(exactDate, "do MMM yyyy")
                : "No date selected"
            }
            onDone={() => this.setState({ isVisible: false })}
            testID="datePickerDone"
          />
        </Sheet>
      </>
    );
  }

  handleMarkedDate = () => {
    if (this.props.value) {
      // if value is an ISO string, we only provide the date portion.
      let valueAsDateString = this.props.value.split("T")[0];
      return {
        [valueAsDateString]: { selected: true }
      };
    }
    return null;
  };

  handleClear = () => {
    this.props.onChange(undefined);
    // this.setState({ isVisible: false });
  };
}

DatePicker.propTypes = {
  /** Component can return either a dateString ("YYYY-MM-DD"), or an ISO string to zero zulu time ("YYYY-MM-DDT00:00:00.000Z")  */
  format: oneOf(["dateString", "ISO"]),
  disableFutureDates: bool,
  futureScrollRange: number,
  /** decides if the input is disabled to the user */
  isDisabled: bool,
  label: string,
  /** Minimum date that can be selected, dates before minDate will be grayed out.*/
  minDate: string,
  /** Maximum date that can be selected, dates after maxDate will be grayed out.*/
  maxDate: string,
  /** A function that expects a value with "YYYY-MM-DD" format as an arguement */
  onChange: func,
  /** Number of months the user should be able to scroll forward or backwards */
  pastScrollRange: number,
  placeholder: string,
  style: object,
  /** The text printed at the top of the opened modal. Defaults to the 'label' prop if not set. */
  title: string,
  testID: string,
  /** Must be a string in format "YYYY-MM-DD", (ISO strings will be trimmed) */
  value: string,
  current: string
};

DatePicker.defaultProps = {
  placeholder: "Choose a date...",
  disableFutureDates: false,
  format: "dateString",
  futureScrollRange: undefined,
  minDate: undefined,
  maxDate: undefined,
  pastScrollRange: undefined
};
