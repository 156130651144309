import { Playground, Props } from "docz";
import DateSelect from "../../../../../src/components/DateSelect/src/DateSelect";
import DateSelectWithValidation from "../../../../../src/components/DateSelect/src/DateSelectWithValidation";
import * as React from 'react';
export default {
  Playground,
  Props,
  DateSelect,
  DateSelectWithValidation,
  React
};