import { Playground, Props } from "docz";
import { View } from "react-native";
import Avatar from "../../../../../src/components/Avatar/index.js";
import { colors } from "../../../../../src/core";
import { InlineSpacer } from "../../../../../src/components/Spacer";
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  Avatar,
  colors,
  InlineSpacer,
  React
};