export const sizing = {
  none: 0,
  xsmall: 24,
  small: 32,
  base: 40,
  medium: 48,
  large: 56,
  xlarge: 72,

  // The following tokens are deprecated
  "0.5x": 4,
  "1x": 8,
  "1.5x": 12,
  "2x": 16,
  "8x": 64,
  "10x": 80
};
