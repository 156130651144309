import React from "react";
import { View } from "react-native";
import { string, bool, func, node, oneOfType } from "prop-types";

import Text from "../Text";
import { boxShadow, colors, radii, spacing } from "../../core";
import { InlineSpacer, StackSpacer } from "../Spacer";
import Button from "../Button";

const PageHint = ({
  text,
  heading,
  isEnlarged,
  hasCTA,
  onPress,
  label,
  emoji
}) => (
  <View
    style={{
      padding: spacing.large,
      borderRadius: radii.medium,
      ...boxShadow,
      ...(isEnlarged
        ? {
            marginHorizontal: -spacing.large
          }
        : {}),
      backgroundColor: colors.background.elevated
    }}
  >
    <View style={{ flexDirection: "row" }}>
      <Text style={{ fontSize: 28 }}>{emoji}</Text>
      <InlineSpacer />
      <View style={{ flex: 1 }}>
        {heading && <Text token="3.5x-700-p">{heading}</Text>}
        <Text token="3.5x-400-p">
          {text || "Hey! This pageHint needs content!"}
        </Text>
        {onPress && !hasCTA && (
          <Text
            onPress={onPress}
            token="3.5x-500-p"
            style={{ color: colors.action.dark }}
          >
            {label}
          </Text>
        )}
      </View>
    </View>
    {onPress && hasCTA && (
      <>
        <StackSpacer size="large" />
        <Button onPress={onPress} type="tertiary" size="small" label={label} />
      </>
    )}
  </View>
);

PageHint.propTypes = {
  /** Decides if the onPress should be rendered as a button or a onPress */
  hasCTA: bool,
  /** Bold heading text that will be printed at the top */
  heading: string,
  /** If true, negative margin is applied, so the pageHint sits outside of the container */
  isEnlarged: bool,
  /** The text that will be printed within the PageHint */
  text: oneOfType([string.isRequired, node]),
  onPress: func,
  label: string,
  emoji: string
};

PageHint.defaultProps = {
  isEnlarged: true,
  label: "Learn more...",
  emoji: "👋"
};

export default PageHint;
