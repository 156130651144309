import React from "react";
import { Platform, TextInput, View } from "react-native";
import { bool, func, number, oneOfType, string } from "prop-types";

import { colors, spacing, radii, text } from "../../../core";
import Text from "../../Text";
import { StackSpacer } from "../../Spacer";

const NumberInput = ({
  errorMessage,
  hintLabel,
  isDisabled,
  label,
  onBlur: defaultOnBlur,
  onChange: defaultOnChange,
  onFocus: defaultOnFocus,
  onHintPress,
  placeholder,
  prefix,
  suffix,
  testID,
  value: initialValue
}) => {
  const value =
    initialValue === 0 ? "0" : initialValue ? initialValue.toString() : "";
  const [isFocused, setFocused] = React.useState(false);

  // Define styles here to allow access to state
  const dynamicStyles = {
    input: {
      padding: spacing.medium,
      borderRadius: radii.small,
      ...text["4x-500"],
      backgroundColor: isFocused
        ? colors.inputField.focus.light
        : isDisabled
        ? colors.inputField.disabled.light
        : colors.inputField.idle.light,
      color: isFocused
        ? colors.inputField.focus.dark
        : isDisabled
        ? colors.inputField.disabled.dark
        : colors.inputField.idle.dark,
      flexGrow: 1,
      zIndex: 1
    }
  };

  const onFocus = () => {
    // Toggling the visual focus state
    setFocused(true);
    defaultOnFocus && defaultOnFocus();
  };

  const onBlur = () => {
    setFocused(false);
    defaultOnBlur && defaultOnBlur();
  };

  const onChange = value => {
    if (value === "") {
      return defaultOnChange(undefined);
    }
    return defaultOnChange(value);
  };

  return (
    <View>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text token="3.5x-500" style={{ color: colors.text.neutral }}>
          {label}
        </Text>
        {hintLabel && (
          <Text
            token="3.5x-500"
            style={{ color: colors.action.dark }}
            onPress={() => onHintPress()}
          >
            {hintLabel}
          </Text>
        )}
      </View>
      <StackSpacer size="xsmall" />
      <TextInput
        style={dynamicStyles.input}
        onFocus={onFocus}
        onBlur={onBlur}
        editable={!isDisabled}
        placeholder={placeholder}
        placeholderTextColor={colors.text.muted}
        keyboardType={Platform.select({
          android: "numeric",
          ios: "numbers-and-punctuation"
        })}
        returnKeyType="done"
        value={
          isFocused ? value : `${value && prefix}${value}${value && suffix}`
        }
        testID={testID}
        /*
            Intercept the `onChangeText` event here so we can handle floating labels logic
            and then relay it to the `<Input />` component call (see relayOnchangeText method).
            We pass `{...props}` before that so that we can override `props.onChangeText`! ✨
          */
        onChangeText={value => onChange(value)}
      />

      {errorMessage && errorMessage !== "FIELD_IS_STILL_FOCUSED" && (
        <>
          <StackSpacer size="xsmall" />
          <Text token="3x-400" style={{ color: colors.alert.danger.dark }}>
            {errorMessage}
          </Text>
        </>
      )}
    </View>
  );
};

// for documentation purposes, add propsTypes to NumberInputWithValidation
NumberInput.propTypes = {
  errorMessage: string,
  hintLabel: string,
  isDisabled: bool,
  label: string,
  onBlur: func,
  onChange: func,
  onFocus: func,
  onHintPress: func,
  placeholder: string,
  /** String that will be placed before the value for presentation */
  prefix: string,
  /** String that will be placed after the value for presentation */
  suffix: string,
  testID: string,
  value: oneOfType([number, string])
};

// for documentation purposes, add defaultProps to NumberInputWithValidation
NumberInput.defaultProps = {
  prefix: "",
  suffix: "",
  onHintPress: () => {}
};

export default NumberInput;
