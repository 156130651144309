import React, { forwardRef } from "react";
import { array, bool, func, number, object, oneOf, string } from "prop-types";

import {
  FieldValidator,
  FormValidationRules,
  formatValueToNumber
} from "../../Form";

import CurrencyInput from "./currencyInput";

const CurrencyInputWithValidation = forwardRef(
  (
    {
      maximumDecimalPlaces,
      defaultValue,
      form,
      onBlur,
      onFocus,
      isRequired,
      label,
      name,
      validationMethods,
      formatMethods,
      ...props
    },
    ref
  ) => {
    let keyValue = null;

    // stub the component's default onChange if it's not defined
    const onChange =
      typeof props.onChange === "function" ? props.onChange : () => {};

    const validationLabel = label.replace("*", "");

    const isNotFocused = ({ value }) => {
      if (value && typeof value === "string") {
        // We don't want this error to printed to the user.
        // It should only cause the form to be invalid
        // and the form's submit button to be disabled.
        return "FIELD_IS_STILL_FOCUSED";
      }
      return false;
    };

    return (
      <FieldValidator
        defaultValue={defaultValue}
        form={form}
        isRequired={isRequired}
        label={validationLabel}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        validationMethods={[
          FormValidationRules.isNumber,
          isNotFocused,
          ...(validationMethods || [])
        ]}
        formatMethods={[
          value =>
            formatValueToNumber(value, {
              maximumDecimalPlaces
            }),
          ...(formatMethods || [])
        ]}
      >
        {({ errors, ...validatorProps }) => {
          // just get the first error from the stack
          const errorMessage = errors && errors.length ? errors[0] : null;

          return (
            <CurrencyInput
              errorMessage={errorMessage}
              label={label}
              ref={ref}
              maximumDecimalPlaces={maximumDecimalPlaces}
              {...props}
              {...validatorProps}
              onChange={val => validatorProps.onChange(val, keyValue)}
            />
          );
        }}
      </FieldValidator>
    );
  }
);

CurrencyInputWithValidation.propTypes = {
  /** Allow more than two decimal places to be shown in UI and sent to the API. */
  maximumDecimalPlaces: oneOf([2, 4]),
  defaultValue: number,
  form: object.isRequired,
  formatMethods: array,
  isDisabled: bool,
  isRequired: bool,
  label: string.isRequired,
  name: string.isRequired,
  onBlur: func,
  /** A secondary onChange method which will run before the default one */
  onChange: func,
  onFocus: func,
  placeholder: string,
  testID: string,
  validationMethods: array
};

CurrencyInputWithValidation.defaultProps = {
  maximumDecimalPlaces: 2
};

CurrencyInputWithValidation.displayName = "CurrencyInputWithValidation";

export default CurrencyInputWithValidation;
