const BASE_TOKEN = 4;

const fontSizes = {
  "2.5x": BASE_TOKEN * 2.5, // 10
  "3x": BASE_TOKEN * 3, // 12
  "3.5x": BASE_TOKEN * 3.5, // 14
  "4x": BASE_TOKEN * 4, // 16
  "4.5x": BASE_TOKEN * 4.5, //18
  "5x": BASE_TOKEN * 5, // 20
  "6x": BASE_TOKEN * 6, // 24,
  "7x": BASE_TOKEN * 7, // 28,
  "8x": BASE_TOKEN * 8, // 32,
  "9x": BASE_TOKEN * 9, // 36,
  "10x": BASE_TOKEN * 20 // 40
};

// text styles are organised smallest to largest (1-N)

export default {
  "title-1": {
    fontWeight: "700",
    fontSize: fontSizes["4x"],
    lineHeight: fontSizes["4x"],
    letterSpacing: fontSizes["4x"] * 0.01
  },

  "title-2": {
    fontWeight: "700",
    fontSize: fontSizes["6x"],
    lineHeight: fontSizes["7x"],
    letterSpacing: fontSizes["6x"] * 0.02
  },

  "blockTitle-1": {
    fontWeight: "700",
    fontSize: fontSizes["3x"],
    lineHeight: fontSizes["3x"],
    letterSpacing: fontSizes["3x"] * 0.05,
    textTransform: "uppercase"
  },

  "2.5x-400": {
    fontSize: fontSizes["2.5x"],
    fontWeight: "400"
  },
  "2.5x-500": {
    fontSize: fontSizes["2.5x"],
    fontWeight: "500"
  },
  "2.5x-700": {
    fontSize: fontSizes["2.5x"],
    fontWeight: "700"
  },

  "3x-400": {
    fontSize: fontSizes["3x"],
    fontWeight: "400"
  },
  "3x-500": {
    fontSize: fontSizes["3x"],
    fontWeight: "500"
  },
  "3x-700": {
    fontSize: fontSizes["3x"],
    fontWeight: "700"
  },

  "3.5x-400": {
    fontSize: fontSizes["3.5x"],
    fontWeight: "400"
  },
  "3.5x-500": {
    fontSize: fontSizes["3.5x"],
    fontWeight: "500"
  },
  "3.5x-700": {
    fontSize: fontSizes["3.5x"],
    fontWeight: "700"
  },

  "4x-400": {
    fontSize: fontSizes["4x"],
    fontWeight: "400"
  },
  "4x-500": {
    fontSize: fontSizes["4x"],
    fontWeight: "500"
  },
  "4x-700": {
    fontSize: fontSizes["4x"],
    fontWeight: "700"
  },

  "4.5x-400": {
    fontSize: fontSizes["4.5x"],
    fontWeight: "400"
  },
  "4.5x-500": {
    fontSize: fontSizes["4.5x"],
    fontWeight: "500"
  },
  "4.5x-700": {
    fontSize: fontSizes["4.5x"],
    fontWeight: "700"
  },

  "2.5x-400-p": {
    fontSize: fontSizes["2.5x"],
    lineHeight: fontSizes["2.5x"] * 1.428,
    fontWeight: "400"
  },
  "2.5x-500-p": {
    fontSize: fontSizes["2.5x"],
    lineHeight: fontSizes["2.5x"] * 1.428,
    fontWeight: "500"
  },
  "2.5x-700-p": {
    fontSize: fontSizes["2.5x"],
    lineHeight: fontSizes["2.5x"] * 1.428,
    fontWeight: "700"
  },

  "3x-400-p": {
    fontSize: fontSizes["3x"],
    lineHeight: fontSizes["3x"] * 1.428,
    fontWeight: "400"
  },
  "3x-500-p": {
    fontSize: fontSizes["3x"],
    lineHeight: fontSizes["3x"] * 1.428,
    fontWeight: "500"
  },
  "3x-700-p": {
    fontSize: fontSizes["3x"],
    lineHeight: fontSizes["3x"] * 1.428,
    fontWeight: "700"
  },

  "3.5x-400-p": {
    fontSize: fontSizes["3.5x"],
    lineHeight: fontSizes["3.5x"] * 1.428,
    fontWeight: "400"
  },
  "3.5x-500-p": {
    fontSize: fontSizes["3.5x"],
    lineHeight: fontSizes["3.5x"] * 1.428,
    fontWeight: "500"
  },
  "3.5x-700-p": {
    fontSize: fontSizes["3.5x"],
    lineHeight: fontSizes["3.5x"] * 1.428,
    fontWeight: "700"
  },

  "4x-400-p": {
    fontSize: fontSizes["4x"],
    lineHeight: fontSizes["4x"] * 1.428,
    fontWeight: "400"
  },
  "4x-500-p": {
    fontSize: fontSizes["4x"],
    lineHeight: fontSizes["4x"] * 1.428,
    fontWeight: "500"
  },
  "4x-700-p": {
    fontSize: fontSizes["4x"],
    lineHeight: fontSizes["4x"] * 1.428,
    fontWeight: "700"
  },

  "4.5x-400-p": {
    fontSize: fontSizes["4.5x"],
    lineHeight: fontSizes["4.5x"] * 1.428,
    fontWeight: "400"
  },
  "4.5x-500-p": {
    fontSize: fontSizes["4.5x"],
    lineHeight: fontSizes["4.5x"] * 1.428,
    fontWeight: "500"
  },
  "4.5x-700-p": {
    fontSize: fontSizes["4.5x"],
    lineHeight: fontSizes["4.5x"] * 1.428,
    fontWeight: "700"
  },
  "7x-700-p": {
    fontSize: fontSizes["7x"],
    lineHeight: fontSizes["7x"] * 1.428,
    fontWeight: "700"
  }
};
